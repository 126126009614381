import * as React from "react";
import { Menu, MenuItemLink, MenuProps, useTranslate } from "react-admin";
import { useLocation } from "react-router-dom";

import { ArrowBack, Ballot, EventNote } from "@mui/icons-material";
import { CircularProgress, Divider } from "@mui/material";
import Box from "@mui/material/Box";

import config from "../config";
import exercises from "../exercises";
import inviteCodes from "../inviteCodes";
import sessions from "../sessions";
import users from "../users";
import programPages from "../programPages";
import SubMenu from "./SubMenu";
import { useFetchData } from "../utils/useAdminApi";
import { ProgramModel } from "../program/model";
import useRouteExists from "../utils/useRouteExists";

type MenuName = "menuPrograms" | "menuExercises" | "menuSessions" | "menuCodes";

const ConditionalMenuItemLink = ({ to, ...rest }) => {
  // Vérifiez la condition sur le chemin
  // if (condition && !condition(to)) {
  //     return null; // Ne rien rendre si la condition n'est pas remplie
  // }
  const routeExists = useRouteExists();

  // Check if the user has the permission to view the destination page
  return routeExists(to) ? <MenuItemLink to={to} {...rest} /> : null;
};

const CustomMenu = ({ dense = false }: MenuProps) => {
  const location = useLocation();
  const programId = location.pathname.split("/")[1];

  const [state, setState] = React.useState({
    menuPrograms: true,
    menuExercises: false,
    menuSessions: false,
    menuCodes: false,
  });

  const translate = useTranslate();

  const { data: programData, loading, error } = useFetchData(
    `/adminApi/program/${programId}`
  ) as { data: ProgramModel; loading: boolean; error: any };

  if (!programId || programId == "sessions") {
    // no program or /sessions/generate
    return null;
  }

  if (loading || !programData) {
    return <CircularProgress size={25} thickness={2} />;
  }

  if (error) {
    return <div>ERROR</div>;
  }

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  if (programId) {
    return (
      <Menu>
        <ConditionalMenuItemLink
          to={"/"}
          key={"home"}
          state={{ _scrollToTop: true }}
          primaryText={translate("pos.menu.home")}
          leftIcon={<ArrowBack />}
          dense={dense}
        />
        {/*<DashboardMenuItem />*/}
        {programId ? (
          <ConditionalMenuItemLink
            to={`/${programId.toLowerCase()}/home`}
            key={"program"}
            state={{ _scrollToTop: true }}
            primaryText={translate("pos.menu.program")}
            leftIcon={<Ballot />}
            dense={dense}
          />
        ) : null}

        {programData ? (
          <Box key={"list"}>
            <ConditionalMenuItemLink
              to={`/${programData.id}/invite-codes`}
              key={"codes"}
              state={{ _scrollToTop: true }}
              primaryText={translate(`pos.menu.codes`)}
              leftIcon={<inviteCodes.icon />}
              dense={dense}
            />
            <ConditionalMenuItemLink
              to={`/${programData.id}/users`}
              key={"users"}
              state={{ _scrollToTop: true }}
              primaryText={translate(`pos.menu.users`)}
              leftIcon={<users.icon />}
              dense={dense}
            />
            <ConditionalMenuItemLink
              to={`/${programData.id}/pages`}
              key={"pages"}
              state={{ _scrollToTop: true }}
              primaryText={translate(`pos.menu.programPages`)}
              leftIcon={<programPages.icon />}
              dense={dense}
            />
          </Box>
        ) : null}
        {programData ? (
          <ConditionalMenuItemLink
            to={`/${programData.id}/sessions/generate`}
            state={{ _scrollToTop: true }}
            primaryText={translate(`pos.menu.generateSessions`, {
              smart_count: 2,
            })}
            leftIcon={<sessions.icon />}
            dense={dense}
            key={"sessions"}
          />
        ) : null}

        {programData ? (
          <ConditionalMenuItemLink
            to={`/${programData.id}/sessions/exercises`}
            state={{ _scrollToTop: true }}
            primaryText={translate(`resources.exercises.name`, {
              smart_count: 2,
            })}
            leftIcon={<exercises.icon />}
            dense={dense}
            key={"exercises"}
          />
        ) : null}
      </Menu>
    );
  } else {
    return null;
  }
};

export default CustomMenu;
