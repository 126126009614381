import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useSafeSetState, useTranslate } from "ra-core";
//import { getUser } from "../graphql/queries";
import {
  Button,
  Card,
  CardActions,
  Chip,
  CircularProgress,
} from "@mui/material";
import { Form, required, TextInput, useLogin, useNotify } from "react-admin";
import Box from "@mui/material/Box";
import awsExports from "../aws-exports.js";

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
  }),
  { name: "RaLoginForm" }
);

const Login = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const amplifyEnvMatches = awsExports.aws_content_delivery_bucket?.match(
    /.*-(\w+)/
  );
  const amplifyEnv = amplifyEnvMatches ? amplifyEnvMatches[1] : null;

  const submit = (values) => {
    setLoading(true);
    login(values, typeof redirectTo === "undefined" ? null : redirectTo)
      .then(() => {
        setLoading(false);
        window.location.reload(); // Force to reload completely the page to refresh dynamic routes
      })
      .catch((error) => {
        console.log("error", error);
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          { type: "warning" }
        );
      });
  };

  return (
    <Form onSubmit={submit} noValidate>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
          background: `url(/img/${process.env.REACT_APP_ID}-background.png)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Card sx={{ minWidth: 300, marginTop: "6em" }}>
          <Box
            sx={{
              margin: "1em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src="/img/lock-icon.png" />
          </Box>
          <Box
            sx={{
              marginTop: "1em",
              display: "flex",
              justifyContent: "center",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            Alix
            {amplifyEnv != "prod" && (
              <Chip
                label={amplifyEnv}
                color="error"
                size="small"
                sx={{ ml: 1 }}
              />
            )}
          </Box>
          <Box sx={{ padding: "0 1em 1em 1em" }}>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                autoFocus
                source="username"
                label={translate("ra.auth.username")}
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
            <Box sx={{ marginTop: "1em" }}>
              <TextInput
                source="password"
                label={translate("ra.auth.password")}
                type="password"
                disabled={loading}
                validate={required()}
                fullWidth
              />
            </Box>
          </Box>
          <CardActions sx={{ padding: "0 1em 1em 1em" }}>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={loading}
              fullWidth
            >
              {loading && <CircularProgress size={25} thickness={2} />}
              {translate("ra.auth.sign_in")}
            </Button>
          </CardActions>
        </Card>
      </Box>
    </Form>
  );
};

Login.propTypes = {
  redirectTo: PropTypes.string,
};

export default Login;
