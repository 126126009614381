import React, { useEffect, useState } from "react";
import { Title } from "react-admin";
import {
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Stack,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useParams, useNavigate } from "react-router-dom";
import {
  useFetchData,
  getAsyncFetchData,
  postAsyncData,
} from "../utils/useAdminApi";
import FilteredList from "../components/FilteredList";
import { useTranslate } from "ra-core";

const UsersList = (props) => {
  let navigate = useNavigate();
  const translate = useTranslate();
  const { programId } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState("");
  const [phoneSearchError, setPhoneSearchError] = useState("");
  useEffect(() => {
    fetchItems();
  }, []);
  const {
    error: errorProgram,
    loading: loadingProgram,
    data: dataProgram,
  } = useFetchData("/adminApi/program/" + programId);

  if (loading || loadingProgram) {
    return <CircularProgress size={25} thickness={2} />;
  }
  if (error || errorProgram) {
    return <p>ERROR</p>;
  }

  const fetchItems = async (token = null) => {
    setLoading(true);
    try {
      const response = await getAsyncFetchData(
        `/adminApi/user?program=${programId}`
      );
      if (response.error) {
        console.error("Failed to fetch users");
        setError(true);
      }
      const data = response.data;
      setData(data);
    } catch (error) {
      console.error("Erreur lors de la récupération des éléments :", error);
    } finally {
      setLoading(false);
    }
  };
  const submitPhoneSearch = async () => {
    const { error, data } = await postAsyncData(
      `/adminApi/user/search?program=${programId}`,
      {
        phoneNumber: phoneSearch,
      }
    );
    if (error) {
      setPhoneSearchError(translate("errors.user"));
    } else if (data) {
      setPhoneSearchError("");
      navigate(`${data.id}/show`);
    }
  };

  let usersData: any = data;
  const attributesToDisplay = [
    {
      key: "patientId",
      type: "String",
      filter: "contains",
      style: { fontWeight: "bold" },
      name: translate("resources.users.patientId"),
    },
    // {
    //   key: "firstName",
    //   type: "String",
    //   filter: "stringStart",
    //   name: translate("resources.users.firstname")
    // },
    // {
    //   key: "name",
    //   type: "String",
    //   filter: "stringStart",
    //   name: translate("resources.users.lastname")
    // },
    {
      key: "sessionsCompleted",
      type: "Number",
      name: translate("resources.users.sessions.indoorSessionsCompleted"),
    },
    {
      key: "walkSessionsCompleted",
      type: "Number",
      name: translate("resources.users.sessions.walkSessionsCompleted"),
    },
    // {
    //   key: "createdAt",
    //   type: "moment",
    //   name: translate("resources.users.createdAt")
    // },
    {
      key: "updatedAt",
      type: "moment",
      name: translate("resources.users.updatedAt"),
    },
    {
      key: "membershipStartedAt",
      type: "moment",
      name: translate("resources.users.membershipStartedAt"),
    },
  ];
  return data ? (
    <>
      <Title
        title={translate(`pos.usersListTitle`, {
          programName: dataProgram?.displayName,
        })}
      />
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>Recherche d'utilisateurs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack display="flex" flexWrap="wrap" alignContent="flex-start">
            <TextField
              label={translate(`resources.users.phoneNumber`)}
              value={phoneSearch}
              onChange={(e) => setPhoneSearch(e.target.value)}
              margin="normal"
            />
            <Typography variant="caption">
              {translate("warning.phone_prefix")}
            </Typography>
            <Button variant="contained" onClick={submitPhoneSearch}>
              {translate(`pos.search`)}
            </Button>
            <Typography color="red">{phoneSearchError}</Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
      <FilteredList
        data={usersData}
        displayAttributes={attributesToDisplay}
        path={`/${programId}/users`}
      />
    </>
  ) : null;
};
export default UsersList;
