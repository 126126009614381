import React, { useState, useEffect, ReactNode } from "react";
import {
  EditorContent,
  useEditor,
} from "@tiptap/react";
import awsExports from "../aws-exports";
import { Storage } from '@aws-amplify/storage';
import { alpha, useTheme } from "@mui/material/styles";
import StarterKit from "@tiptap/starter-kit";
import Gapcursor from "@tiptap/extension-gapcursor";
import HardBreak from "@tiptap/extension-hard-break";
import DropCursor from "@tiptap/extension-dropcursor";
import CodeBlock from "@tiptap/extension-code-block";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import TextAlign from "@tiptap/extension-text-align";
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Link from "@tiptap/extension-link";
import Dropcursor from "@tiptap/extension-dropcursor";
import {
  Button,
  Box,
  Paper,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
} from "@mui/material";
import {
  Code,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  Link as LinkIcon,
  LinkOff,
  InsertPhoto,
  FormatAlignLeft,
  FormatAlignRight,
  FormatAlignCenter,
  FormatAlignJustify,
  FormatListBulleted,
  FormatListNumbered,
  AddCard,
  PostAdd,
  YouTube,
} from "@mui/icons-material";
import { useTranslate } from "react-admin";
import { CardNode } from "./tiptap_extensions/CardNode";
import { ImageNode } from "./tiptap_extensions/ImageNode";
import { getEmbedVideoUrl, Iframe } from "./tiptap_extensions/Iframe";
import { ProgramModel } from "../program/model";

interface WysiwygEditorProps {
  value: string;
  program?: ProgramModel;
  onChange: (value: string) => void;
}

type EditorButtonProps = {
  isActive?: boolean;
  onClick: () => void;
  disabled?: boolean;
  title?: string;
  children: ReactNode;
};

const EditorButton = ({
  isActive,
  onClick,
  children,
  disabled,
  title,
}: EditorButtonProps) => {
  const theme = useTheme();

  const backgroundColor = alpha(
    theme.palette.primary.main,
    isActive ? 1 : 0.05
  );

  return (
    <Tooltip title={title} arrow>
      <Button
        variant="contained"
        onClick={onClick}
        sx={{
          backgroundColor: backgroundColor,
          color: isActive ? "#FFF" : theme.palette.primary.main,
          minWidth: 0,
          padding: 1,
          marginRight: 1,
        }}
        disabled={disabled}
      >
        {children}
      </Button>
    </Tooltip>
  );
};

const WysiwygEditor: React.FC<WysiwygEditorProps> = ({ program, value, onChange }) => {
  const translate = useTranslate();
  const [isCodeView, setIsCodeView] = useState(false);
  const [htmlContent, setHtmlContent] = useState(value);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        bulletList: {
          keepMarks: true,
          keepAttributes: false,
        },
        orderedList: {
          keepMarks: true,
          keepAttributes: false,
        },
      }),
      DropCursor.configure({
        width: 2,
        class: "notitap-dropcursor",
        color: "skyblue",
      }),
      CardNode,
      Iframe,
      HardBreak,
      CodeBlock,
      TextStyle,
      Underline,
      TextAlign.configure({
        types: ["heading", "paragraph", "image"],
      }),
      HorizontalRule,
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: "https",
        protocols: ["http", "https", "alix", "alixmed", "mailto"],
      }),
      ImageNode.extend({
        addAttributes() {
          return {
            src: {},
            width: { default: "auto" },
            height: { default: "auto" },
          };
        },
      }),
      Gapcursor,
      Dropcursor,
    ],
    content: value || "",
    onUpdate: ({ editor }) => {
      if (!isCodeView) {
        // Update html code (source box) when editing in wysiwyg editor
        const html = editor.getHTML();
        setHtmlContent(html);
        onChange(html);
      }
    },
    editorProps: {
      attributes: {
        spellcheck: "false",
      },
      handleDOMEvents: {
        drop: (view, event) => {
          event.preventDefault();
          const file = event.dataTransfer?.files[0];
          if (file) {
            setSelectedFile(file);
            setOpenImageDialog(true);
          }
        },
      },
    },
  });

  useEffect(() => {
    if (editor && isCodeView) {
      // Update wysiwyg editor when editing in source box editor
      editor?.commands.setContent(htmlContent);
    }
  }, [editor, htmlContent, isCodeView]);

  const addOrEditLink = () => {
    const previousUrl = editor?.getAttributes("link").href;
    const url = prompt(translate(`wysiwyg.pasteLinkUrl`), previousUrl || "");
    if (url === null) return;
    if (url === "") {
      editor?.chain().focus().unsetLink().run();
    } else {
      editor
        ?.chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    }
  };

  const addVideo = () => {
    const url = prompt(translate(`wysiwyg.pasteVideoUrl`), "");
    const embedUrl = getEmbedVideoUrl(url);
    if (embedUrl === null) return;
    if (embedUrl === "") {
      return
    } else {
      editor
        ?.chain()
        .focus()
        .insertContent({
          type: "iframe",
          attrs: {
            src: embedUrl,
          },
        })
        .run();
    }
  }

  const uploadImage = async () => {
    if (!selectedFile || !editor) return;

    try {
      const filename = `${Date.now()}-${selectedFile.name}`;
      const key = `programData/${program?.id}/pages/images/${filename}`;

      const result: any = await Storage.put(key, selectedFile, {
        contentType: selectedFile.type,
        level: 'public',
      });

      // Build manually public url
      const bucket = awsExports.aws_user_files_s3_bucket;
      const region = awsExports.aws_user_files_s3_bucket_region;
      const publicUrl = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`;

      editor.chain().focus().setImage({ src: publicUrl }).run();
      setHtmlContent(editor.getHTML());
      setOpenImageDialog(false);
      setSelectedFile(null);
    } catch (error) {
      console.error("Error on image upload", error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setOpenImageDialog(true);
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Box>
      <Paper sx={{ p: 2, minHeight: 200, overflow: "auto" }}>
        <Box sx={{ mb: 2 }}>
          <EditorButton
            isActive={editor.isActive("bold")}
            onClick={() => editor?.chain().focus().toggleBold().run()}
            title={translate("wysiwyg.bold")}
            disabled={isCodeView}
          >
            <FormatBold />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("italic")}
            onClick={() => editor?.chain().focus().toggleItalic().run()}
            title={translate("wysiwyg.italic")}
            disabled={isCodeView}
          >
            <FormatItalic />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("underline")}
            onClick={() => editor?.chain().focus().toggleUnderline().run()}
            title={translate("wysiwyg.underline")}
            disabled={isCodeView}
          >
            <FormatUnderlined />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("bulletList")}
            onClick={() => editor?.chain().focus().toggleBulletList().run()}
            title={translate("wysiwyg.bulletList")}
            disabled={isCodeView}
          >
            <FormatListBulleted />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("orderedList")}
            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
            title={translate("wysiwyg.orderedList")}
            disabled={isCodeView}
          >
            <FormatListNumbered />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("heading", { level: 1 })}
            onClick={() =>
              editor?.chain().focus().toggleHeading({ level: 1 }).run()
            }
            title={translate("wysiwyg.h1")}
            disabled={isCodeView}
          >
            H1
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("heading", { level: 2 })}
            onClick={() =>
              editor?.chain().focus().toggleHeading({ level: 2 }).run()
            }
            title={translate("wysiwyg.h2")}
            disabled={isCodeView}
          >
            H2
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("heading", { level: 3 })}
            onClick={() =>
              editor?.chain().focus().toggleHeading({ level: 3 }).run()
            }
            title={translate("wysiwyg.h3")}
            disabled={isCodeView}
          >
            H3
          </EditorButton>
          <EditorButton
            isActive={editor.isActive("link")}
            onClick={addOrEditLink}
            title={
              editor.isActive("link")
                ? translate("wysiwyg.editLink")
                : translate("wysiwyg.addLink")
            }
            disabled={isCodeView}
          >
            <LinkIcon />
          </EditorButton>
          <EditorButton
            isActive={false}
            onClick={() => editor.chain().focus().unsetLink().run()}
            title={translate("wysiwyg.unsetLink")}
            disabled={isCodeView || !editor.isActive("link")}
          >
            <LinkOff />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive({ textAlign: "left" })}
            onClick={() => editor?.chain().focus().setTextAlign("left").run()}
            title={translate("wysiwyg.alignLeft")}
            disabled={isCodeView}
          >
            <FormatAlignLeft />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive({ textAlign: "center" })}
            onClick={() => editor?.chain().focus().setTextAlign("center").run()}
            title={translate("wysiwyg.alignCenter")}
            disabled={isCodeView}
          >
            <FormatAlignCenter />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive({ textAlign: "right" })}
            onClick={() => editor?.chain().focus().setTextAlign("right").run()}
            title={translate("wysiwyg.alignRight")}
            disabled={isCodeView}
          >
            <FormatAlignRight />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive({ textAlign: "justify" })}
            onClick={() =>
              editor?.chain().focus().setTextAlign("justify").run()
            }
            title={translate("wysiwyg.alignJustify")}
            disabled={isCodeView}
          >
            <FormatAlignJustify />
          </EditorButton>
          <EditorButton
            isActive={editor.isActive({ textAlign: "justify" })}
            onClick={() => setOpenImageDialog(true)}
            title={translate("wysiwyg.uploadImage")}
            disabled={isCodeView}
          >
            <InsertPhoto />
          </EditorButton>
          <EditorButton
            isActive={false}
            onClick={() =>
              editor
                ?.chain()
                .focus()
                .insertContent({
                  type: 'card',
                  attrs: { title: '' },
                  content: [
                    {
                      type: 'paragraph',
                      content: [],
                    },
                  ],
                })
                .run()
            }
            title={translate("wysiwyg.addCard")}
            disabled={isCodeView}
          >
            <AddCard />
          </EditorButton>
          <EditorButton
            isActive={false}
            onClick={addVideo}
            title={translate("wysiwyg.addVideo")}
            disabled={isCodeView}
          >
            <YouTube />
          </EditorButton>
          <EditorButton
            isActive={isCodeView}
            onClick={() => setIsCodeView(!isCodeView)}
            title={translate("wysiwyg.sourcecode")}
          >
            <Code />
          </EditorButton>
          <EditorButton
            onClick={() => editor.chain().focus().setHorizontalRule().run()}
          >
            <PostAdd /> {translate(`wysiwyg.addSection`)}
          </EditorButton>
        </Box>

        {isCodeView ? (
          <TextField
            fullWidth
            multiline
            variant="outlined"
            value={htmlContent}
            onChange={(e) => setHtmlContent(e.target.value)}
          />
        ) : (
          <EditorContent editor={editor} style={{ height: "65vh", overflowY: "scroll" }} />
        )}
      </Paper>

      <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
        <DialogTitle>
          {translate(`wysiwyg.uploadImage`)}
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{ border: "2px dashed gray", p: 3, textAlign: "center", mb: 2 }}
            onDragOver={(event) => event.preventDefault()}
            onDrop={(event) => {
              event.preventDefault();
              const file = event.dataTransfer?.files[0];
              if (file) {
                setSelectedFile(file);
              }
            }}
          >
            <Typography>
              {translate(`wysiwyg.dragDropImage`)}
            </Typography>
          </Box>
          <Button component="label" variant="outlined" color="primary">
            {translate(`wysiwyg.selectFile`)}
            <input type="file" hidden onChange={handleFileUpload} />
          </Button>
          {selectedFile && (
            <>
              <Typography sx={{ mt: 2 }}>{selectedFile.name}</Typography>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt=""
                style={{ maxWidth: "100%", maxHeight: 100 }}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenImageDialog(false)}>
            {translate(`resources.misc.cancel`)}
          </Button>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Button onClick={uploadImage} variant="contained" color="primary">
              {translate(`resources.misc.save`)}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default WysiwygEditor;
