const customMessages = {
  program: {
    params: {
      surgeryDate: "À quelle date est prévue votre opération ?",
      knee: "Quel genou doit être opéré ?",
      knee__LEFT: "Gauche",
      knee__RIGHT: "Droit",
      flexion: "Flexion (°)",
      extension: "Extension (°)",
      effusion: "Effusion (°)",
      effusion__I_DO_NOT_KNOW: "Je ne sais pas",
      effusion__NO_EFFUSION: "Pas d’épanchement",
      effusion__EFFUSION_LEVEL_1: "Épanchement de niveau 1",
      effusion__EFFUSION_LEVEL_2: "Épanchement de niveau 2",
      effusion__EFFUSION_LEVEL_3: "Épanchement de niveau 3",
      flessum: "Niveau de flessum",
      kneePainDescription: "Niveau de douleur",
      kneePainDescription__NO_PAIN: "Aucune douleur",
      kneePainDescription__MILD_PAIN: "Douleur légère",
      kneePainDescription__MODERATE_PAIN: "Douleur modérée",
      kneePainDescription__INTENSE_PAIN: "Douleur intense",
      kneePainDescription__EXTREME_PAIN: "Douleur extrême",
      useOfCanes: "Utilisation de supports (cannes)",
      useOfCanes__NO_CANES: "Je n'utilise pas de cannes",
      useOfCanes__ONE_CANE: "J'utilise une canne",
      useOfCanes__TWO_CANES: "J'utilise deux cannes",
      postOpWeightBearing: "Autorisation d'appui post-opératoire",
      postOpWeightBearing__FULL_WEIGHT_BEARING:
        "Oui, appui complet autorisé immédiatement",
      postOpWeightBearing__PARTIAL_WEIGHT_BEARING:
        "Non, appui partiel autorisé seulement",
      postOpWeightBearing__NO_WEIGHT_BEARING:
        "Appui interdit jusqu'à nouvel ordre",
      walkingTestDuration: "Durée de marche (s)",
      phase: "Phase",
      phase__KPRP_0: "Phase pré-op",
      phase__KPRP_1: "Phase post-op immédiat",
      phase__KPRP_2: "Phase post-op",
    },
    questionnaires: {
      walkingTest: "Test de marche",
      kprpQuestionnaire: "Questionnaire spécifique",
      phaseQuestionnaire: "Sélection de la phase",
    },
  },
};

export default customMessages;
