const customMessages = {
  program: {
    params: {
      surgeryDate: "À quelle date est prévue votre opération ?",
      knee: "À quel genou allez-vous être opéré ?",
      knee__LEFT: "Gauche",
      knee__RIGHT: "Droit",
      extension: "Extension de votre genou concerné par l’opération",
      flexion: "Flexion de votre genou concerné par l’opération",
      flessum: "Flessum de votre genou concerné par l’opération",
      effusion: "Niveau d’épanchement de votre genou concerné par l’opération",
      effusion__I_DO_NOT_KNOW: "Je ne sais pas",
      effusion__NO_EFFUSION: "Pas d’épanchement",
      effusion__EFFUSION_LEVEL_1: "Épanchement de niveau 1",
      effusion__EFFUSION_LEVEL_2: "Épanchement de niveau 2",
      effusion__EFFUSION_LEVEL_3: "Épanchement de niveau 3",
      kneePainDescription:
        "Comment décririez-vous la douleur quotidienne liée à votre genou ?",
      kneePainDescription__NO_PAIN: "Aucune douleur",
      kneePainDescription__MILD_PAIN: "Douleur légère",
      kneePainDescription__MODERATE_PAIN: "Douleur modérée",
      kneePainDescription__INTENSE_PAIN: "Douleur intense",
      kneePainDescription__EXTREME_PAIN: "Douleur extrême",
      walkingDistance:
        "Quelle est la distance que vous pouvez parcourir sans douleur significative ou fatigue ?",
      walkingDistance__200_METERS: "200 mètres",
      walkingDistance__500_METERS: "500 mètres",
      walkingDistance__1_KM: "1 km",
      walkingDistance__3_KM: "3 km",
      walkingDistance__UNLIMITED: "Pas de limites",
      stairDifficulty: "Difficultés dans les escaliers",
      stairDifficulty__NO_DIFFICULTY: "Sans difficulté",
      stairDifficulty__DIFFICULTY_ASCENDING:
        "Avec difficulté seulement en montée",
      stairDifficulty__DIFFICULTY_DESCENDING:
        "Avec difficulté seulement en descente",
      stairDifficulty__DIFFICULTY_BOTH:
        "Avec difficulté en montée et en descente",
      stairDifficulty__IMPOSSIBLE_WITHOUT_ASSISTANCE:
        "Impossible sans assistance",
      carEntryExitDifficulty:
        "Difficultés pour rentrer et sortir de la voiture",
      carEntryExitDifficulty__NO_DIFFICULTY: "Sans aucune difficulté",
      carEntryExitDifficulty__SOME_DIFFICULTY:
        "Avec un peu de difficulté, mais je peux le faire sans aide",
      carEntryExitDifficulty__MUCH_DIFFICULTY:
        "Avec beaucoup de difficulté, j'ai besoin d'une aide légère",
      carEntryExitDifficulty__CANNOT_DO_WITHOUT_SIGNIFICANT_ASSISTANCE:
        "Je ne peux pas le faire sans assistance significative",
      useOfCanes: "Utilisation de cannes",
      useOfCanes__NO_CANES: "Je n'utilise pas de cannes",
      useOfCanes__ONE_CANE: "J'utilise une canne",
      useOfCanes__TWO_CANES: "J'utilise deux cannes",
      postOpWeightBearing: "Autorisation d'appui post-opératoire",
      postOpWeightBearing__FULL_WEIGHT_BEARING:
        "Oui, appui complet autorisé immédiatement",
      postOpWeightBearing__PARTIAL_WEIGHT_BEARING:
        "Non, appui partiel autorisé seulement",
      postOpWeightBearing__NO_WEIGHT_BEARING:
        "Appui interdit jusqu'à nouvel ordre",
      useOfStairRamps: "Utilisation d’une rampe pour les escaliers ?",
      useOfStairRamps__YES: "Oui",
      useOfStairRamps__NO: "Non",
      phase: "Phase",
      phase__rraac_0: "Pré-opératoire",
      phase__rraac_1: "Pré-intervention",
      phase__rraac_2: "Post-opératoire immédiat",
      phase__rraac_3: "Post-opératoire moyen terme",
      phase__rraac_4: "Post-opératoire long terme",
    },
    questionnaires: {
      rraacQuestionnaire: "Questionnaire RRAAC",
      phaseQuestionnaire: "Phase",
    },
  },
};

export default customMessages;
