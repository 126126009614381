import React, { useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  styled,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useTranslate } from "ra-core";
import { useI18nProvider } from "react-admin";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import useRouteExists from "../utils/useRouteExists";

const MAX_ITEMS_BEFORE_SHOW_ALL = 50;
export default function FilteredList(props) {
  let navigate = useNavigate();
  const translate = useTranslate();
  const routeExists = useRouteExists();
  const [showAll, setShowAll] = useState(false);
  const [filtersValue, setFiltersValue] = useState({});
  const filtersToDisplay = props.displayAttributes.filter((a) => a.filter);
  const i18nProvider = useI18nProvider();

  const StyledTableCell = styled(TableCell)(({ theme }) => {
    return {
      [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
      },
    };
  });

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const filterFunctions = filtersToDisplay.map((f) => {
    switch (f.filter) {
      case "exact":
        return (a: Record<string, any>) =>
          !filtersValue[f.key] ||
          (a[f.key] && a[f.key] === filtersValue[f.key]);

      case "stringStart":
        return (a: Record<string, any>) =>
          !filtersValue[f.key] ||
          (typeof a[f.key] === "string" &&
            a[f.key]
              .toLowerCase()
              .startsWith(filtersValue[f.key]!.toLowerCase()));

      case "contains":
        return (a: Record<string, any>) =>
          !filtersValue[f.key] ||
          (typeof a[f.key] === "string" &&
            a[f.key]
              .toLowerCase()
              .includes(filtersValue[f.key]!.toLowerCase()));

      default:
        // If an unsupported filter type is encountered, log a warning or throw an error
        console.warn(`Unsupported filter type: ${f.filter}`);
        return () => true; // No-op filter function (doesn't filter anything)
    }
  });
  let filteredData = filterFunctions.reduce((current, filter) => {
    return current.filter(filter);
  }, props.data);

  let dataToDisplay = filteredData;
  if (!showAll) {
    dataToDisplay = dataToDisplay.slice(0, MAX_ITEMS_BEFORE_SHOW_ALL);
  }

  const handleDetails = (id) => {
    navigate(`${props.path}/${id}/show`);
  };
  const handleEdit = (id) => {
    navigate(`${props.path}/${id}/edit`);
  };

  const renderAttribute = (att, item) => {
    switch (att.type) {
      case "String":
        return item[att.key];
      case "Number":
        return Number.parseInt(!item[att.key] ? 0 : item[att.key]);
      case "moment": {
        return item[att.key]
          ? moment(item[att.key]).locale(i18nProvider.getLocale()).fromNow()
          : "";
      }
    }
  };

  const handleChange = (e, key) => {
    setFiltersValue({ ...filtersValue, [key]: e.target.value });
  };

  const renderFilter = (filter) => {
    return (
      <TextField
        key={filter.key}
        label={filter.name}
        variant="outlined"
        value={filtersValue[filter.key]}
        onChange={(e) => handleChange(e, filter.key)}
        margin="normal"
      />
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        {filtersToDisplay.map((filter) => renderFilter(filter))}
      </Box>
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              {props.displayAttributes.map((att, index) => {
                return (
                  <StyledTableCell key={index} style={att.style}>
                    {att.name}
                  </StyledTableCell>
                );
              })}
              <StyledTableCell></StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataToDisplay.map((item, index) => {
              return (
                <StyledTableRow key={index}>
                  {props.displayAttributes.map((att) => {
                    return (
                      <StyledTableCell key={att.key} style={att.style}>
                        {renderAttribute(att, item)}
                      </StyledTableCell>
                    );
                  })}
                  <StyledTableCell>
                    {routeExists(`${props.path}/${item.id}/show`) ? (
                      <Button
                        variant="contained"
                        onClick={() => handleDetails(item.id)}
                      >
                        {translate("resources.misc.details")}
                      </Button>
                    ) : null}
                  </StyledTableCell>
                  <StyledTableCell>
                    {routeExists(`${props.path}/${item.id}/edit`) ? (
                      <Button
                        variant="contained"
                        onClick={() => handleEdit(item.id)}
                      >
                        {translate("resources.misc.edit")}
                      </Button>
                    ) : null}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      {filteredData.length > MAX_ITEMS_BEFORE_SHOW_ALL && (
        <Button variant="outlined" onClick={() => setShowAll(!showAll)}>
          {showAll ? "Afficher moins" : "Afficher tout"}
        </Button>
      )}
    </>
  );
}
