import {
  Node,
  ReactNodeViewRenderer,
  NodeViewWrapper,
  NodeViewContent,
} from "@tiptap/react";
import {
  Card,
  CardContent,
  Typography,
  TextField,
} from "@mui/material";
import { useTranslate } from "react-admin";

const CardComponent = ({ node, updateAttributes }) => {
  const translate = useTranslate();
  return (
    <NodeViewWrapper>
      <Card sx={{ p: 2, m: 1, backgroundColor: "#f5f5f5" }}>
        <CardContent>
          <TextField
            fullWidth
            variant="standard"
            value={node.attrs.title}
            onChange={(e) => updateAttributes({ title: e.target.value })}
            placeholder={translate(`wysiwyg.cardTitle`)}
            sx={{ fontWeight: "bold", mb: 1 }}
          />
          <Typography
            variant="body2"
            sx={{ minHeight: 50, border: "1px solid #ccc", padding: 1 }}
          >
            <NodeViewContent className="card-content" />
          </Typography>
        </CardContent>
      </Card>
    </NodeViewWrapper>
  );
};

export const CardNode = Node.create({
  name: "card",
  group: "block",
  atom: false,
  content: "block+",

  addAttributes() {
    return {
      title: {
        default: '',
      },
      
    };
  },

  parseHTML() {
    return [{ tag: "mui-card" }];
  },

  renderHTML({ node }) {
    return [
      "mui-card",
      { title: node.attrs.title },
      0,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CardComponent);
  },
});