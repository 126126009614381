import dayjs from "dayjs";
import { omit, snakeCase, uniqueId } from "lodash";
import { useEffect, useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { SwitchElement, TextFieldElement } from "react-hook-form-mui";
import { Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { useParams } from "react-router-dom";

import { ArrowBack, Check, ErrorOutline, Remove } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";

import { postAsyncData } from "../utils/useAdminApi";

import { InviteCodeModel } from "./model";

let defaultValues = {
  withCounts: false,
  withExpiration: false,
  withUserValidation: false,
  userValidationMethod: "manual",
  refKey: null,
  expiresAt: dayjs().add(1, "day").endOf("day").unix() * 1000, // To ms
} as InviteCodeModel;

export default function InviteCodeCreate() {
  // Load translation & redirection
  const translate = useTranslate();
  const redirect = useRedirect();

  // Get programId
  const { programId } = useParams();

  // Init form status state
  const [status, setStatus] = useState("idle");
  const [error, setError] = useState("");

  // Form control
  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: defaultValues,
  });
  const watchedFields = watch();

  // Get category length for warnings
  const generationCategoriesLength =
    watchedFields.generationCategories?.length || 0;
  const generationNumber =
    watchedFields.generationCategories?.reduce(
      (total, category) =>
        total === 0 ? total + category.number : total * category.number,
      0
    ) || 0;

  // Init categories field array
  const {
    fields: categories,
    append: appendCategory,
    remove: removeCategory,
  } = useFieldArray({ control, name: "generationCategories" });

  // Function to add an category to the form
  function addCategory(categoryName?: string) {
    const name = categoryName || uniqueId("field_");
    appendCategory({ name: name, number: 10 });
  }

  // Reset default form values when programId from params changes
  useEffect(() => {
    reset({ ...watchedFields, program: programId });
  }, [programId]);

  // Form submit handler
  async function submitHandler(formData: InviteCodeModel) {
    // Set status to 'loading' for client feedback
    setStatus("loading");

    let lotGenerationErrors = 0;
    const parsedFormData = prepareInviteCodeValues({ formData });

    // Recursive function to iterate n nested loops (if )
    async function recursiveCategoryIterator(
      depth: number,
      iterationIndexes: Array<number>
    ) {
      if (depth <= 0) {
        for (
          let index = 0;
          index < formData.generationCategories![depth].number;
          index++
        ) {
          iterationIndexes[0] = index;

          // Generate an ID from the category names and iteration indexes
          const generatedName =
            formData.name +
            " " +
            iterationIndexes
              .map(
                (categoryId, index) =>
                  formData.generationCategories![index].name +
                  " " +
                  String(categoryId)
              )
              .join(" - ");
          parsedFormData.name = generatedName;

          // Try to create the new invite code
          try {
            await createInviteCode({ ...parsedFormData });
          } catch (error) {
            lotGenerationErrors += 1;
          }
        }
        return;
      }

      for (
        let index = 0;
        index < formData.generationCategories![depth].number;
        index++
      ) {
        iterationIndexes[depth] = index;
        await recursiveCategoryIterator(depth - 1, iterationIndexes);
      }
    }

    if (formData.generateLots) {
      // Generates invite code lots
      if (!formData.generationCategories) {
        setStatus("error");
        setError(translate("resources.codes.create.missingCategories"));
        return;
      }
      // Generate invite code lots by iterating over each category
      await recursiveCategoryIterator(
        formData.generationCategories.length - 1,
        new Array(formData.generationCategories.length)
      );

      // Set result status
      console.log("lotGenerationErrors", lotGenerationErrors);
      if (lotGenerationErrors === 0) {
        setStatus("success");
        setTimeout(() => {
          redirect("/" + programId + "/invite-codes");
        }, 1000);
      } else {
        setStatus("error");
        setError(
          translate("resources.codes.create.lotsErrors").replace(
            "%s",
            String(lotGenerationErrors)
          )
        );
      }
    } else {
      // Generate one invite code
      try {
        await createInviteCode(parsedFormData);
        setStatus("success");
        setTimeout(() => {
          redirect("/" + programId + "/invite-codes");
        }, 1000);
      } catch (error) {
        setStatus("error");
        setError(String(error));
      }
    }
  }

  // Generate invite code values for the database
  function prepareInviteCodeValues({
    formData,
  }: {
    formData: InviteCodeModel;
  }) {
    // Define a new invite code Object
    let parsedFormData = {
      ...omit(formData, ["generateLots", "generationCategories"]),
      id: snakeCase(formData.id),
      program: String(programId),
      maxUses:
        formData.withCounts && formData.maxUses !== undefined
          ? formData.maxUses
          : undefined,
      uses: formData.withCounts ? 0 : undefined,
      expiresAt: formData.withExpiration ? formData.expiresAt : undefined,
      userValidationMethod:
        formData.withUserValidation &&
        formData.userValidationMethod !== undefined
          ? formData.userValidationMethod
            ? "auto"
            : "manual"
          : undefined,
    } as InviteCodeModel;

    if (formData.withCounts === false)
      parsedFormData = omit(parsedFormData, ["maxUses", "uses"]);
    if (formData.withExpiration === false)
      parsedFormData = omit(parsedFormData, ["expiresAt"]);
    if (formData.withUserValidation === false)
      parsedFormData = omit(parsedFormData, ["userValidationMethod"]);

    return parsedFormData;
  }

  async function createInviteCode(codeData: InviteCodeModel) {
    try {
      codeData.id = codeData.id + "_" + crypto.randomUUID();
      const { data, error: postError } = (await postAsyncData(
        "/adminApi/program/" + programId + "/invitecodes",
        codeData
      )) as { data: any; error: any };

      if (postError) {
        setStatus("error");
        if (postError.status == 403) {
          throw new Error("Access denied");
        } else {
          throw new Error(String(postError));
        }
      }
    } catch (error) {
      setStatus("error");
      setError(String(error));
      throw new Error(String(error));
    }
  }

  if (!programId) return <CircularProgress />;

  return (
    <Paper style={{ padding: 16, margin: "16px 8px" }}>
      {/* Go back button */}
      <div style={{ display: "flow-root" }}>
        <Button
          sx={{ float: "left" }}
          startIcon={<ArrowBack />}
          onClick={() => redirect("/" + programId + "/invite-codes")}
        >
          {translate("resources.misc.goBack")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          rowGap: 16,
        }}
      >
        <Typography variant="h5">
          {translate("resources.codes.create.title")}
        </Typography>
        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit(async (data) => await submitHandler(data))}
        >
          <Grid container spacing={4}>
            {/* Basic properties */}
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
            >
              <TextFieldElement
                control={control}
                name="id"
                required
                size="small"
                fullWidth
                label={translate("resources.codes.create.prefixId")}
              />
              <TextFieldElement
                control={control}
                name="name"
                required
                size="small"
                fullWidth
                label={translate("resources.codes.name")}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SwitchElement
                  control={control}
                  name="withUserValidation"
                  label={translate("resources.codes.create.withUserValidation")}
                />
                {watchedFields.withUserValidation && (
                  <div>
                    <SwitchElement
                      control={control}
                      label={translate(
                        `resources.codes.${
                          watchedFields.userValidationMethod ? "auto" : "manual"
                        }`
                      )}
                      name="userValidationMethod"
                    />
                    <Typography></Typography>
                  </div>
                )}
              </div>
            </Grid>

            {/* Limit fields */}
            <Grid
              item
              container
              xs={12}
              md={6}
              style={{ display: "flex", flexDirection: "column", rowGap: 16 }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SwitchElement
                  control={control}
                  name="withCounts"
                  label={translate("resources.codes.create.withCounts")}
                />
                {watchedFields.withCounts && (
                  <Controller
                    control={control}
                    name="maxUses"
                    render={({ field }) => (
                      <TextFieldElement
                        control={control}
                        name="maxUses"
                        required
                        type="number"
                        size="small"
                        fullWidth
                        label={translate("resources.codes.create.maxUses")}
                        onChange={(e) => {
                          const newValue = parseInt(e.target.value);
                          field.onChange(newValue < 0 ? 0 : newValue);
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SwitchElement
                  control={control}
                  name="withExpiration"
                  label={translate("resources.codes.create.withExpiration")}
                />
                {watchedFields.withExpiration && (
                  <Controller
                    control={control}
                    name="expiresAt"
                    render={({ field }) => (
                      <Calendar
                        value={field.value}
                        weekStartDayIndex={1}
                        minDate={new Date().toISOString()}
                        plugins={[<TimePicker position="bottom" />]}
                        onChange={(date) => {
                          field.onChange((date?.toUnix() as number) * 1000);
                        }}
                      />
                    )}
                  />
                )}
              </div>
            </Grid>

            {/* Lots generation */}
            <Grid
              item
              xs={12}
              xl={7}
              display="flex"
              flexDirection="column"
              alignContent="flex-start"
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <SwitchElement
                  control={control}
                  name="generateLots"
                  label={translate("resources.codes.create.generateLots")}
                />
                {watchedFields.generateLots && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 16,
                      justifyContent: "center",
                      padding: "16px 8px",
                    }}
                  >
                    <Typography variant="body2">
                      {translate(
                        "resources.codes.create.generationNumber"
                      ).replace("%s", String(generationNumber))}
                    </Typography>
                    {categories.map((category, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 4,
                          }}
                        >
                          <TextFieldElement
                            size="small"
                            control={control}
                            name={`generationCategories.${index}.name`}
                            label={translate(
                              "resources.codes.create.categoryName"
                            )}
                            required
                          />
                          <TextFieldElement
                            size="small"
                            control={control}
                            type="number"
                            name={`generationCategories.${index}.number`}
                            label={translate(
                              "resources.codes.create.categoryNumber"
                            )}
                            required
                          />
                          <Tooltip
                            title={translate(
                              "resources.codes.create.removeCategory"
                            )}
                            arrow
                            placement="top"
                          >
                            <Button
                              onClick={() => removeCategory(index)}
                              sx={{ minWidth: 0 }}
                            >
                              <Remove color="error" />
                            </Button>
                          </Tooltip>
                        </div>
                      );
                    })}
                    <Tooltip
                      arrow
                      placement="top"
                      title={
                        generationCategoriesLength > 3
                          ? translate(
                              "resources.codes.create.tooManyCategories"
                            )
                          : generationCategoriesLength > 1
                          ? translate(
                              "resources.codes.create.warningManyCategories"
                            )
                          : ""
                      }
                    >
                      <Button
                        onClick={() => addCategory()}
                        color={
                          generationCategoriesLength > 3
                            ? "error"
                            : generationCategoriesLength > 1
                            ? "warning"
                            : undefined
                        }
                        variant={
                          generationCategoriesLength > 1
                            ? "contained"
                            : "outlined"
                        }
                      >
                        {translate("resources.codes.create.addCategory")}
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>

          {/* Submit response handling */}
          {(status === "success" || status === "error") && (
            <div style={{ padding: "16px 0" }}>
              {status === "success" && (
                <Alert severity="success">
                  {translate("resources.codes.create.createSuccess")}
                </Alert>
              )}
              {status === "error" && (
                <Alert severity="error">
                  <div>{translate("resources.codes.create.createError")}:</div>
                  <div>{error}</div>
                </Alert>
              )}
            </div>
          )}

          {/* Submit button */}
          <div
            style={{
              flex: "1 0 0",
              display: "flex",
              alignItems: "center",
              gap: 8,
            }}
          >
            <Button
              variant="contained"
              type="submit"
              autoFocus
              disabled={status === "loading"}
            >
              {translate("resources.misc.create")}
            </Button>
            {status === "loading" && (
              <CircularProgress size={25} thickness={2} />
            )}
            {status === "success" && <Check color="success" />}
            {status === "error" && <ErrorOutline color="error" />}
          </div>
        </form>
      </div>
    </Paper>
  );
}
