// IframeNode.tsx
import {
  Node,
  ReactNodeViewRenderer,
  NodeViewWrapper,
} from '@tiptap/react'
import { Typography } from '@mui/material'
import { useTranslate } from 'react-admin'

export const getEmbedVideoUrl = (url: string | null): string | null => {
  if(!url) return null

  const youtube = url.match(/(?:youtube\.com\/watch\?v=|youtu\.be\/)([\w-]+)/)
  if (youtube) return `https://www.youtube.com/embed/${youtube[1]}`
  if (url.startsWith('https://www.youtube.com/embed/')) return url

  const vimeo = url.match(/vimeo\.com\/(\d+)/)
  if (vimeo) return `https://player.vimeo.com/video/${vimeo[1]}`
  if (url.startsWith('https://player.vimeo.com/video/')) return url

  const dailymotion = url.match(/dailymotion\.com\/video\/([^_]+)/)
  if (dailymotion) return `https://www.dailymotion.com/embed/video/${dailymotion[1]}`
  if (url.startsWith('https://www.dailymotion.com/embed/video/')) return url

  return null
}

const IframeComponent = ({ node, updateAttributes }) => {
  const translate = useTranslate();
  const { src } = node.attrs
  const embedUrl = src

  return (
    <NodeViewWrapper as="div">
      {embedUrl ? (
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
          <iframe
            src={embedUrl}
            allowFullScreen
            frameBorder="0"
            className="video-iframe"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      ) : (
        <Typography variant="body2" color="text.secondary">
          {translate(`wysiwyg.unknownVideoUrl`)}
        </Typography>
      )}
    </NodeViewWrapper>
  )
}

export const Iframe = Node.create({
  name: 'iframe',
  group: 'block',
  atom: true,
  selectable: true,

  addAttributes() {
    return {
      src: { default: '' },
      width: { default: '100%' },
      height: { default: '100%' },
      frameborder: { default: '0' },
      allowfullscreen: { default: true },
      class: { default: "video-iframe" },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'iframe[src]',
        getAttrs: (node) => {
          if (!(node instanceof HTMLElement)) return false
          return {
            src: node.getAttribute('src'),
            width: node.getAttribute('width') || '560',
            height: node.getAttribute('height') || '315',
            frameborder: node.getAttribute('frameborder') || '0',
            allowfullscreen: node.hasAttribute('allowfullscreen'),
            class: node.getAttribute('class') || 'video-iframe',
          }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['iframe', {
      ...HTMLAttributes,
      frameborder: HTMLAttributes.frameborder ?? '0',
      allowfullscreen: HTMLAttributes.allowfullscreen ? 'true' : null,
    }]
  },

  addNodeView() {
    return ReactNodeViewRenderer(IframeComponent)
  },
})
