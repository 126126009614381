import { useTranslate } from "ra-core";
import { useRedirect } from "react-admin";

import {
  Chip,
  CircularProgress,
  Container,
  MenuItem,
  MenuList,
  Paper,
  Stack,
} from "@mui/material";
import { useFetchData } from "../utils/useAdminApi";
import { ProgramModel, ProgramVisibility } from "./model";

export default function ProgramList(props) {
  const translate = useTranslate();
  const redirect = useRedirect();

  const { data, loading, error } = useFetchData("/adminApi/program") as {
    data: ProgramModel[];
    loading: boolean;
    error: any;
  };

  const goToProgram = (programId) => {
    redirect(`/${programId}/home`);
    window.location.reload();
  };

  if (loading || !data) {
    return <CircularProgress size={25} thickness={2} />;
  }

  if (data.length == 1) {
    // If the user has access to only one program, redirect to it.
    redirect(`/${data[0].id}/home`);
    window.location.reload();
  }

  return (
    <Container
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <Paper sx={{ width: 400, padding: 2 }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {process.env.REACT_APP_ID == "alixmed" ? (
            <img src="/img/alixmed-hello.png" style={{ height: 100 }} />
          ) : (
            <img src="/img/alix-hello.png" style={{ height: 100 }} />
          )}
          <h2>{translate(`resources.programs.selectProgram`)}</h2>
        </Stack>
        <MenuList>
          {data.map((program) => {
            return (
              <MenuItem
                key={program.id}
                onClick={() => goToProgram(program.id)}
              >
                {program.displayName}
                {program.visibility == ProgramVisibility.Public && (
                  <Chip
                    label={translate(`resources.programs.public`)}
                    color="primary"
                    size="small"
                    sx={{ marginLeft: 1 }}
                  />
                )}
              </MenuItem>
            );
          })}
        </MenuList>
      </Paper>
    </Container>
  );
}
