import { useTranslate } from "ra-core";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import { useFetchData } from "../utils/useAdminApi";
import ProgramPageForm from "./ProgramPageForm";
import { ProgramModel } from "../program/model";

export const ProgramPageCreate = (props) => {
  const translate = useTranslate();
  const { programId } = useParams();

  const { data: program, loading, error } = useFetchData(
    `/adminApi/program/${programId}`
  ) as { data: ProgramModel; loading: boolean; error: any };

  if (loading || !program) {
    return <CircularProgress size={25} thickness={2} />;
  }

  return (
    <div style={{ padding: "16px 0" }}>
      {/* Program data */}
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        {program && (
          <>
            <Title
              title={translate(`pos.programShowTitle`, {
                programName: program.displayName,
              })}
            />
            <h1>{translate("resources.pages.create.title")}</h1>

            <ProgramPageForm program={program} />
          </>
        )}
      </Stack>
    </div>
  );
};

export default ProgramPageCreate;
