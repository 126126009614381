/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const alixQuestionnairesSelection = /* GraphQL */ `
  mutation AlixQuestionnairesSelection($input: questionnairesSelectionInput!) {
    alixQuestionnairesSelection(input: $input) {
      questionnairePAS {
        options {
          value
          key
        }
        answer
        version
      }
      healthgoalQuestionnaire {
        id
        questions {
          id
          type
          image_url
          key
          options {
            id
            value
          }
          answers
        }
        version
      }
    }
  }
`;
export const alixQuestionnairesResults = /* GraphQL */ `
  mutation AlixQuestionnairesResults($input: questionnairesResultsInput!) {
    alixQuestionnairesResults(input: $input) {
      healthgoal
      program
      medicalIssues
      info
    }
  }
`;
export const generateSession = /* GraphQL */ `
  mutation GenerateSession($input: generateSessionInput!) {
    generateSession(input: $input) {
      name
      author
      duration
      components {
        section
        exerciseVariantId
        exerciseId
        name
        pause
        duration
        speedFactor
        intensity
        video {
          filepath
          filesize
        }
        equipments
        painAreas
        cardiacImpact
        easyMode {
          exerciseId
          exerciseVariantId
          name
          video {
            filepath
            filesize
          }
          equipments
          cardiacImpact
          advice
        }
        advice
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
      }
      equipments
      painAreas
      module
      moduleLevel
      iterations
      sessionsCompleted
      lastDataValue
      program
      type
      description
      advice
      additionalComponents {
        exerciseVariantId
        video {
          filepath
          filesize
        }
      }
      previewInfoWebviewPath
      programParams
    }
  }
`;
export const uploadAlixAppSession = /* GraphQL */ `
  mutation UploadAlixAppSession($input: CreateSessionInput!) {
    uploadAlixAppSession(input: $input) {
      id
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
            }
            equipments
            cardiacImpact
            advice
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
        type
        description
        advice
        additionalComponents {
          exerciseVariantId
          video {
            filepath
            filesize
          }
        }
        previewInfoWebviewPath
        programParams
      }
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      timestamp
      type
    }
  }
`;
export const levelTestCompute = /* GraphQL */ `
  mutation LevelTestCompute($input: levelTestInput!) {
    levelTestCompute(input: $input) {
      pasScore
      balance
      balanceLevel
      endurance
      enduranceLevel
      resistance
      resistanceLevel
      power
      powerLevel
      timestamp
      id
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
    }
  }
`;
export const syncActivityData = /* GraphQL */ `
  mutation SyncActivityData($input: syncActivityDataInput!) {
    syncActivityData(input: $input) {
      syncStatus
    }
  }
`;
export const pointsTransaction = /* GraphQL */ `
  mutation PointsTransaction($input: pointsTransactionsInput!) {
    pointsTransaction(input: $input) {
      statusCode
      ExecutedVersion
      Payload
      body
    }
  }
`;
export const generateThumbnail = /* GraphQL */ `
  mutation GenerateThumbnail($input: generateThumbnailInput!) {
    generateThumbnail(input: $input) {
      statusCode
      body
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      dateOfBirth
      email
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      equipments
      painAreas
      firstName
      locale
      gender
      healthgoal
      healthgoalInfo
      hrMax
      hrMin
      measuresValues {
        date
        fatRatio
        muscleRatio
        lastWeightValue
      }
      name
      nickname
      pasScore
      points {
        total
        updatedAt
        categories {
          alixActivities {
            total
          }
          otherActivities {
            total
          }
          misc {
            total
          }
        }
      }
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      profileImage
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sensorsPossessed
      sessionsCompleted
      sleepValues {
        sleepProfileValue
        sleepAverageValue
        sleepSDValue
        sleepFeedbackValue
        sleepLastValue
      }
      shopifyId
      subscribed
      subscriptionSource
      subscriptionEndTime
      weight
      height
      withings {
        userId
        accessToken
        refreshToken
        validUntil
      }
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
      }
      avatar
      walkSessionsCompleted
      patientId
      program
      programInfo
      programData {
        id
        data {
          effusion
          extension
          flessum
          flexion
          walkingTestDuration
          knee
        }
        jsonData
      }
      memberships {
        id
        status
        startedAt
        endAt
        source
        refKey
        inviteToken
      }
      lastTestDate
      fitbit {
        userId
        accessToken
        refreshToken
        validUntil
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      dateOfBirth
      email
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      equipments
      painAreas
      firstName
      locale
      gender
      healthgoal
      healthgoalInfo
      hrMax
      hrMin
      measuresValues {
        date
        fatRatio
        muscleRatio
        lastWeightValue
      }
      name
      nickname
      pasScore
      points {
        total
        updatedAt
        categories {
          alixActivities {
            total
          }
          otherActivities {
            total
          }
          misc {
            total
          }
        }
      }
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      profileImage
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sensorsPossessed
      sessionsCompleted
      sleepValues {
        sleepProfileValue
        sleepAverageValue
        sleepSDValue
        sleepFeedbackValue
        sleepLastValue
      }
      shopifyId
      subscribed
      subscriptionSource
      subscriptionEndTime
      weight
      height
      withings {
        userId
        accessToken
        refreshToken
        validUntil
      }
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
      }
      avatar
      walkSessionsCompleted
      patientId
      program
      programInfo
      programData {
        id
        data {
          effusion
          extension
          flessum
          flexion
          walkingTestDuration
          knee
        }
        jsonData
      }
      memberships {
        id
        status
        startedAt
        endAt
        source
        refKey
        inviteToken
      }
      lastTestDate
      fitbit {
        userId
        accessToken
        refreshToken
        validUntil
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      dateOfBirth
      email
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      equipments
      painAreas
      firstName
      locale
      gender
      healthgoal
      healthgoalInfo
      hrMax
      hrMin
      measuresValues {
        date
        fatRatio
        muscleRatio
        lastWeightValue
      }
      name
      nickname
      pasScore
      points {
        total
        updatedAt
        categories {
          alixActivities {
            total
          }
          otherActivities {
            total
          }
          misc {
            total
          }
        }
      }
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      profileImage
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sensorsPossessed
      sessionsCompleted
      sleepValues {
        sleepProfileValue
        sleepAverageValue
        sleepSDValue
        sleepFeedbackValue
        sleepLastValue
      }
      shopifyId
      subscribed
      subscriptionSource
      subscriptionEndTime
      weight
      height
      withings {
        userId
        accessToken
        refreshToken
        validUntil
      }
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
      }
      avatar
      walkSessionsCompleted
      patientId
      program
      programInfo
      programData {
        id
        data {
          effusion
          extension
          flessum
          flexion
          walkingTestDuration
          knee
        }
        jsonData
      }
      memberships {
        id
        status
        startedAt
        endAt
        source
        refKey
        inviteToken
      }
      lastTestDate
      fitbit {
        userId
        accessToken
        refreshToken
        validUntil
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createLevelTest = /* GraphQL */ `
  mutation CreateLevelTest(
    $input: CreateLevelTestInput!
    $condition: ModelLevelTestConditionInput
  ) {
    createLevelTest(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      endurance
      enduranceLevel
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      timestamp
      userId
      resistance
      resistanceLevel
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      power
      powerLevel
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      context
      pasScore
      createdAt
      updatedAt
    }
  }
`;
export const updateLevelTest = /* GraphQL */ `
  mutation UpdateLevelTest(
    $input: UpdateLevelTestInput!
    $condition: ModelLevelTestConditionInput
  ) {
    updateLevelTest(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      endurance
      enduranceLevel
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      timestamp
      userId
      resistance
      resistanceLevel
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      power
      powerLevel
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      context
      pasScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteLevelTest = /* GraphQL */ `
  mutation DeleteLevelTest(
    $input: DeleteLevelTestInput!
    $condition: ModelLevelTestConditionInput
  ) {
    deleteLevelTest(input: $input, condition: $condition) {
      id
      balance
      balanceLevel
      endurance
      enduranceLevel
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      timestamp
      userId
      resistance
      resistanceLevel
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      power
      powerLevel
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      context
      pasScore
      createdAt
      updatedAt
    }
  }
`;
export const createExercise = /* GraphQL */ `
  mutation CreateExercise(
    $input: CreateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    createExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
      }
      bodyParts {
        id
      }
      capacity
      cardiacImpact
      categories {
        id
      }
      difficulty
      equipments {
        id
        name
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
          nina {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
        }
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
    }
  }
`;
export const updateExercise = /* GraphQL */ `
  mutation UpdateExercise(
    $input: UpdateExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    updateExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
      }
      bodyParts {
        id
      }
      capacity
      cardiacImpact
      categories {
        id
      }
      difficulty
      equipments {
        id
        name
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
          nina {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
        }
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
    }
  }
`;
export const deleteExercise = /* GraphQL */ `
  mutation DeleteExercise(
    $input: DeleteExerciseInput!
    $condition: ModelExerciseConditionInput
  ) {
    deleteExercise(input: $input, condition: $condition) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
      }
      bodyParts {
        id
      }
      capacity
      cardiacImpact
      categories {
        id
      }
      difficulty
      equipments {
        id
        name
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
          nina {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
        }
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
    }
  }
`;
export const createSession = /* GraphQL */ `
  mutation CreateSession(
    $input: CreateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    createSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
            }
            equipments
            cardiacImpact
            advice
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
        type
        description
        advice
        additionalComponents {
          exerciseVariantId
          video {
            filepath
            filesize
          }
        }
        previewInfoWebviewPath
        programParams
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      program
      type
      createdAt
      updatedAt
    }
  }
`;
export const updateSession = /* GraphQL */ `
  mutation UpdateSession(
    $input: UpdateSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    updateSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
            }
            equipments
            cardiacImpact
            advice
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
        type
        description
        advice
        additionalComponents {
          exerciseVariantId
          video {
            filepath
            filesize
          }
        }
        previewInfoWebviewPath
        programParams
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      program
      type
      createdAt
      updatedAt
    }
  }
`;
export const deleteSession = /* GraphQL */ `
  mutation DeleteSession(
    $input: DeleteSessionInput!
    $condition: ModelSessionConditionInput
  ) {
    deleteSession(input: $input, condition: $condition) {
      id
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
            }
            equipments
            cardiacImpact
            advice
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
        type
        description
        advice
        additionalComponents {
          exerciseVariantId
          video {
            filepath
            filesize
          }
        }
        previewInfoWebviewPath
        programParams
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      program
      type
      createdAt
      updatedAt
    }
  }
`;
export const createAlixInviteCode = /* GraphQL */ `
  mutation CreateAlixInviteCode(
    $input: CreateAlixInviteCodeInput!
    $condition: ModelAlixInviteCodeConditionInput
  ) {
    createAlixInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAlixInviteCode = /* GraphQL */ `
  mutation UpdateAlixInviteCode(
    $input: UpdateAlixInviteCodeInput!
    $condition: ModelAlixInviteCodeConditionInput
  ) {
    updateAlixInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlixInviteCode = /* GraphQL */ `
  mutation DeleteAlixInviteCode(
    $input: DeleteAlixInviteCodeInput!
    $condition: ModelAlixInviteCodeConditionInput
  ) {
    deleteAlixInviteCode(input: $input, condition: $condition) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlixUserProgramData = /* GraphQL */ `
  mutation CreateAlixUserProgramData(
    $input: CreateAlixUserProgramDataInput!
    $condition: ModelAlixUserProgramDataConditionInput
  ) {
    createAlixUserProgramData(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
    }
  }
`;
export const updateAlixUserProgramData = /* GraphQL */ `
  mutation UpdateAlixUserProgramData(
    $input: UpdateAlixUserProgramDataInput!
    $condition: ModelAlixUserProgramDataConditionInput
  ) {
    updateAlixUserProgramData(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlixUserProgramData = /* GraphQL */ `
  mutation DeleteAlixUserProgramData(
    $input: DeleteAlixUserProgramDataInput!
    $condition: ModelAlixUserProgramDataConditionInput
  ) {
    deleteAlixUserProgramData(input: $input, condition: $condition) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
    }
  }
`;
export const createAlixProgram = /* GraphQL */ `
  mutation CreateAlixProgram(
    $input: CreateAlixProgramInput!
    $condition: ModelAlixProgramConditionInput
  ) {
    createAlixProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          required
          cast
          min
          max
        }
      }
      options {
        indoorSessions
        walkSessions
        homeWebview
        detailsWebview
        questionnaires
        paymentInfoWebview
        pasTest
        kneeRaiseTest
        webviewTest
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
        }
        session {
          model
          programRules
          promptTemplate
        }
      }
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      visibility
      decor
      pages {
        items {
          id
          programID
          program {
            id
            displayName
            description
            icon
            lastInsertPatientId
            status
            questionnaires {
              id
            }
            options {
              indoorSessions
              walkSessions
              homeWebview
              detailsWebview
              questionnaires
              paymentInfoWebview
              pasTest
              kneeRaiseTest
              webviewTest
            }
            generateSessionMode
            product {
              productRef
              paymentMode
              purchaseType
              paymentProvider
              membershipDuration
              freeTrialDuration
            }
            visibility
            decor
            pages {
              nextToken
            }
            createdAt
            updatedAt
          }
          path
          title
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAlixProgram = /* GraphQL */ `
  mutation UpdateAlixProgram(
    $input: UpdateAlixProgramInput!
    $condition: ModelAlixProgramConditionInput
  ) {
    updateAlixProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          required
          cast
          min
          max
        }
      }
      options {
        indoorSessions
        walkSessions
        homeWebview
        detailsWebview
        questionnaires
        paymentInfoWebview
        pasTest
        kneeRaiseTest
        webviewTest
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
        }
        session {
          model
          programRules
          promptTemplate
        }
      }
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      visibility
      decor
      pages {
        items {
          id
          programID
          program {
            id
            displayName
            description
            icon
            lastInsertPatientId
            status
            questionnaires {
              id
            }
            options {
              indoorSessions
              walkSessions
              homeWebview
              detailsWebview
              questionnaires
              paymentInfoWebview
              pasTest
              kneeRaiseTest
              webviewTest
            }
            generateSessionMode
            product {
              productRef
              paymentMode
              purchaseType
              paymentProvider
              membershipDuration
              freeTrialDuration
            }
            visibility
            decor
            pages {
              nextToken
            }
            createdAt
            updatedAt
          }
          path
          title
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlixProgram = /* GraphQL */ `
  mutation DeleteAlixProgram(
    $input: DeleteAlixProgramInput!
    $condition: ModelAlixProgramConditionInput
  ) {
    deleteAlixProgram(input: $input, condition: $condition) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          required
          cast
          min
          max
        }
      }
      options {
        indoorSessions
        walkSessions
        homeWebview
        detailsWebview
        questionnaires
        paymentInfoWebview
        pasTest
        kneeRaiseTest
        webviewTest
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
        }
        session {
          model
          programRules
          promptTemplate
        }
      }
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      visibility
      decor
      pages {
        items {
          id
          programID
          program {
            id
            displayName
            description
            icon
            lastInsertPatientId
            status
            questionnaires {
              id
            }
            options {
              indoorSessions
              walkSessions
              homeWebview
              detailsWebview
              questionnaires
              paymentInfoWebview
              pasTest
              kneeRaiseTest
              webviewTest
            }
            generateSessionMode
            product {
              productRef
              paymentMode
              purchaseType
              paymentProvider
              membershipDuration
              freeTrialDuration
            }
            visibility
            decor
            pages {
              nextToken
            }
            createdAt
            updatedAt
          }
          path
          title
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProgramPage = /* GraphQL */ `
  mutation CreateProgramPage(
    $input: CreateProgramPageInput!
    $condition: ModelProgramPageConditionInput
  ) {
    createProgramPage(input: $input, condition: $condition) {
      id
      programID
      program {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            required
            cast
            min
            max
          }
        }
        options {
          indoorSessions
          walkSessions
          homeWebview
          detailsWebview
          questionnaires
          paymentInfoWebview
          pasTest
          kneeRaiseTest
          webviewTest
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
          }
          session {
            model
            programRules
            promptTemplate
          }
        }
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        visibility
        decor
        pages {
          items {
            id
            programID
            program {
              id
              displayName
              description
              icon
              lastInsertPatientId
              status
              generateSessionMode
              visibility
              decor
              createdAt
              updatedAt
            }
            path
            title
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      path
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const updateProgramPage = /* GraphQL */ `
  mutation UpdateProgramPage(
    $input: UpdateProgramPageInput!
    $condition: ModelProgramPageConditionInput
  ) {
    updateProgramPage(input: $input, condition: $condition) {
      id
      programID
      program {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            required
            cast
            min
            max
          }
        }
        options {
          indoorSessions
          walkSessions
          homeWebview
          detailsWebview
          questionnaires
          paymentInfoWebview
          pasTest
          kneeRaiseTest
          webviewTest
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
          }
          session {
            model
            programRules
            promptTemplate
          }
        }
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        visibility
        decor
        pages {
          items {
            id
            programID
            program {
              id
              displayName
              description
              icon
              lastInsertPatientId
              status
              generateSessionMode
              visibility
              decor
              createdAt
              updatedAt
            }
            path
            title
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      path
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const deleteProgramPage = /* GraphQL */ `
  mutation DeleteProgramPage(
    $input: DeleteProgramPageInput!
    $condition: ModelProgramPageConditionInput
  ) {
    deleteProgramPage(input: $input, condition: $condition) {
      id
      programID
      program {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            required
            cast
            min
            max
          }
        }
        options {
          indoorSessions
          walkSessions
          homeWebview
          detailsWebview
          questionnaires
          paymentInfoWebview
          pasTest
          kneeRaiseTest
          webviewTest
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
          }
          session {
            model
            programRules
            promptTemplate
          }
        }
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        visibility
        decor
        pages {
          items {
            id
            programID
            program {
              id
              displayName
              description
              icon
              lastInsertPatientId
              status
              generateSessionMode
              visibility
              decor
              createdAt
              updatedAt
            }
            path
            title
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      path
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const createWithingsSleepData = /* GraphQL */ `
  mutation CreateWithingsSleepData(
    $input: CreateWithingsSleepDataInput!
    $condition: ModelWithingsSleepDataConditionInput
  ) {
    createWithingsSleepData(input: $input, condition: $condition) {
      id
      userId
      date
      startTimestamp
      endTimestamp
      wakeUpDuration
      wakeUpCount
      remSleepDuration
      totalSleepTime
      lightSleepDuration
      deepSleepDuration
      heartRateAverage
      respirationRateAverage
      snoring
      sleepScore
      createdAt
      updatedAt
    }
  }
`;
export const updateWithingsSleepData = /* GraphQL */ `
  mutation UpdateWithingsSleepData(
    $input: UpdateWithingsSleepDataInput!
    $condition: ModelWithingsSleepDataConditionInput
  ) {
    updateWithingsSleepData(input: $input, condition: $condition) {
      id
      userId
      date
      startTimestamp
      endTimestamp
      wakeUpDuration
      wakeUpCount
      remSleepDuration
      totalSleepTime
      lightSleepDuration
      deepSleepDuration
      heartRateAverage
      respirationRateAverage
      snoring
      sleepScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteWithingsSleepData = /* GraphQL */ `
  mutation DeleteWithingsSleepData(
    $input: DeleteWithingsSleepDataInput!
    $condition: ModelWithingsSleepDataConditionInput
  ) {
    deleteWithingsSleepData(input: $input, condition: $condition) {
      id
      userId
      date
      startTimestamp
      endTimestamp
      wakeUpDuration
      wakeUpCount
      remSleepDuration
      totalSleepTime
      lightSleepDuration
      deepSleepDuration
      heartRateAverage
      respirationRateAverage
      snoring
      sleepScore
      createdAt
      updatedAt
    }
  }
`;
export const createWithingsMeasureData = /* GraphQL */ `
  mutation CreateWithingsMeasureData(
    $input: CreateWithingsMeasureDataInput!
    $condition: ModelWithingsMeasureDataConditionInput
  ) {
    createWithingsMeasureData(input: $input, condition: $condition) {
      id
      userId
      date
      weight
      fatRatio
      muscleMass
      height
      createdAt
      updatedAt
    }
  }
`;
export const updateWithingsMeasureData = /* GraphQL */ `
  mutation UpdateWithingsMeasureData(
    $input: UpdateWithingsMeasureDataInput!
    $condition: ModelWithingsMeasureDataConditionInput
  ) {
    updateWithingsMeasureData(input: $input, condition: $condition) {
      id
      userId
      date
      weight
      fatRatio
      muscleMass
      height
      createdAt
      updatedAt
    }
  }
`;
export const deleteWithingsMeasureData = /* GraphQL */ `
  mutation DeleteWithingsMeasureData(
    $input: DeleteWithingsMeasureDataInput!
    $condition: ModelWithingsMeasureDataConditionInput
  ) {
    deleteWithingsMeasureData(input: $input, condition: $condition) {
      id
      userId
      date
      weight
      fatRatio
      muscleMass
      height
      createdAt
      updatedAt
    }
  }
`;
export const createHistory = /* GraphQL */ `
  mutation CreateHistory(
    $input: CreateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    createHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHistory = /* GraphQL */ `
  mutation UpdateHistory(
    $input: UpdateHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    updateHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHistory = /* GraphQL */ `
  mutation DeleteHistory(
    $input: DeleteHistoryInput!
    $condition: ModelHistoryConditionInput
  ) {
    deleteHistory(input: $input, condition: $condition) {
      id
      userId
      timestamp
      event
      data {
        source
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlixActivityData = /* GraphQL */ `
  mutation CreateAlixActivityData(
    $input: CreateAlixActivityDataInput!
    $condition: ModelAlixActivityDataConditionInput
  ) {
    createAlixActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
      }
      granularity
    }
  }
`;
export const updateAlixActivityData = /* GraphQL */ `
  mutation UpdateAlixActivityData(
    $input: UpdateAlixActivityDataInput!
    $condition: ModelAlixActivityDataConditionInput
  ) {
    updateAlixActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
      }
      granularity
    }
  }
`;
export const deleteAlixActivityData = /* GraphQL */ `
  mutation DeleteAlixActivityData(
    $input: DeleteAlixActivityDataInput!
    $condition: ModelAlixActivityDataConditionInput
  ) {
    deleteAlixActivityData(input: $input, condition: $condition) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
      }
      granularity
    }
  }
`;
export const createHealthGoalQuestionnairesData = /* GraphQL */ `
  mutation CreateHealthGoalQuestionnairesData(
    $input: CreateHealthGoalQuestionnairesDataInput!
    $condition: ModelHealthGoalQuestionnairesDataConditionInput
  ) {
    createHealthGoalQuestionnairesData(input: $input, condition: $condition) {
      id
      healthgoal
      timestamp
      userId
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateHealthGoalQuestionnairesData = /* GraphQL */ `
  mutation UpdateHealthGoalQuestionnairesData(
    $input: UpdateHealthGoalQuestionnairesDataInput!
    $condition: ModelHealthGoalQuestionnairesDataConditionInput
  ) {
    updateHealthGoalQuestionnairesData(input: $input, condition: $condition) {
      id
      healthgoal
      timestamp
      userId
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteHealthGoalQuestionnairesData = /* GraphQL */ `
  mutation DeleteHealthGoalQuestionnairesData(
    $input: DeleteHealthGoalQuestionnairesDataInput!
    $condition: ModelHealthGoalQuestionnairesDataConditionInput
  ) {
    deleteHealthGoalQuestionnairesData(input: $input, condition: $condition) {
      id
      healthgoal
      timestamp
      userId
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNotifications = /* GraphQL */ `
  mutation CreateNotifications(
    $input: CreateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    createNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationType
      push {
        isPush
        sent
        sentAt
        title
        body
      }
      inApp {
        isInApp
        read
        readAt
        title
        body
      }
      timestamp
      action
      actionValue
      priority
      createdAt
      updatedAt
    }
  }
`;
export const updateNotifications = /* GraphQL */ `
  mutation UpdateNotifications(
    $input: UpdateNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    updateNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationType
      push {
        isPush
        sent
        sentAt
        title
        body
      }
      inApp {
        isInApp
        read
        readAt
        title
        body
      }
      timestamp
      action
      actionValue
      priority
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotifications = /* GraphQL */ `
  mutation DeleteNotifications(
    $input: DeleteNotificationsInput!
    $condition: ModelNotificationsConditionInput
  ) {
    deleteNotifications(input: $input, condition: $condition) {
      id
      userId
      notificationType
      push {
        isPush
        sent
        sentAt
        title
        body
      }
      inApp {
        isInApp
        read
        readAt
        title
        body
      }
      timestamp
      action
      actionValue
      priority
      createdAt
      updatedAt
    }
  }
`;
export const createUserPointsTransaction = /* GraphQL */ `
  mutation CreateUserPointsTransaction(
    $input: CreateUserPointsTransactionInput!
    $condition: ModelUserPointsTransactionConditionInput
  ) {
    createUserPointsTransaction(input: $input, condition: $condition) {
      id
      userId
      timestamp
      type
      points
      totalAfter
      expiresAt
      data {
        type
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPointsTransaction = /* GraphQL */ `
  mutation UpdateUserPointsTransaction(
    $input: UpdateUserPointsTransactionInput!
    $condition: ModelUserPointsTransactionConditionInput
  ) {
    updateUserPointsTransaction(input: $input, condition: $condition) {
      id
      userId
      timestamp
      type
      points
      totalAfter
      expiresAt
      data {
        type
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPointsTransaction = /* GraphQL */ `
  mutation DeleteUserPointsTransaction(
    $input: DeleteUserPointsTransactionInput!
    $condition: ModelUserPointsTransactionConditionInput
  ) {
    deleteUserPointsTransaction(input: $input, condition: $condition) {
      id
      userId
      timestamp
      type
      points
      totalAfter
      expiresAt
      data {
        type
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlixEventLog = /* GraphQL */ `
  mutation CreateAlixEventLog(
    $input: CreateAlixEventLogInput!
    $condition: ModelAlixEventLogConditionInput
  ) {
    createAlixEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
    }
  }
`;
export const updateAlixEventLog = /* GraphQL */ `
  mutation UpdateAlixEventLog(
    $input: UpdateAlixEventLogInput!
    $condition: ModelAlixEventLogConditionInput
  ) {
    updateAlixEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlixEventLog = /* GraphQL */ `
  mutation DeleteAlixEventLog(
    $input: DeleteAlixEventLogInput!
    $condition: ModelAlixEventLogConditionInput
  ) {
    deleteAlixEventLog(input: $input, condition: $condition) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
    }
  }
`;
export const createMembershipsAccess = /* GraphQL */ `
  mutation CreateMembershipsAccess(
    $input: CreateMembershipsAccessInput!
    $condition: ModelMembershipsAccessConditionInput
  ) {
    createMembershipsAccess(input: $input, condition: $condition) {
      id
      userId
      programId
      status
      startedAt
      endAt
      timestamp
      source
      createdAt
      updatedAt
    }
  }
`;
export const updateMembershipsAccess = /* GraphQL */ `
  mutation UpdateMembershipsAccess(
    $input: UpdateMembershipsAccessInput!
    $condition: ModelMembershipsAccessConditionInput
  ) {
    updateMembershipsAccess(input: $input, condition: $condition) {
      id
      userId
      programId
      status
      startedAt
      endAt
      timestamp
      source
      createdAt
      updatedAt
    }
  }
`;
export const deleteMembershipsAccess = /* GraphQL */ `
  mutation DeleteMembershipsAccess(
    $input: DeleteMembershipsAccessInput!
    $condition: ModelMembershipsAccessConditionInput
  ) {
    deleteMembershipsAccess(input: $input, condition: $condition) {
      id
      userId
      programId
      status
      startedAt
      endAt
      timestamp
      source
      createdAt
      updatedAt
    }
  }
`;
