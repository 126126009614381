import React, { useEffect, useState } from "react";
import { useRedirect, useTranslate } from "react-admin";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { ProgramModel } from "../program/model";
import { ProgramPageModel } from "./model";
import WysiwygEditor from "../components/WysiwygEditor";
import { Alert, Box, Button } from "@mui/material";
import { SelectElement, TextFieldElement } from "react-hook-form-mui";
import { postAsyncData } from "../utils/useAdminApi";

interface ProgramPageFormProps {
  program?: ProgramModel;
  programPage?: ProgramPageModel;
}

const slugify = (text: string) =>
  text
    .toLowerCase()
    .trim()
    .replace(/[\s_]+/g, "-") // Espaces et underscores transformés en tirets.
    .replace(/[^\w-]+/g, ""); // Suppression des caractères spéciaux.

const ProgramPageForm: React.FC<ProgramPageFormProps> = ({
  program,
  programPage,
}) => {
  const isProgramPageEditing = programPage ? true : false;
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isPathEdited, setIsPathEdited] = useState(isProgramPageEditing);
  const redirect = useRedirect();
  const translate = useTranslate();

  const pathTemplateOptions = [
    { id: "info", label: translate(`resources.pages.info`) },
    { id: "paymentinfo", label: translate(`resources.pages.paymentinfo`) },
    { id: "custom", label: translate(`resources.pages.custom`) },
  ];
  const specialPaths = ["info", "paymentinfo"];
  let pathTemplateDefaultValue = "info";
  if (programPage?.path) {
    if (specialPaths.includes(programPage.path)) {
      pathTemplateDefaultValue = programPage.path;
    } else {
      pathTemplateDefaultValue = "custom";
    }
  }
  const methods = useForm<ProgramPageModel>({
    defaultValues: {
      title: programPage?.title || "",
      body: programPage?.body || "",
      path: programPage?.path || "info",
      pathTemplate: pathTemplateDefaultValue,
    },
  });

  const { control, handleSubmit, watch, setValue, reset } = methods;

  const path = watch("path");
  const title = watch("title");
  const pathTemplate = watch("pathTemplate");

  // Slugification of path
  useEffect(() => {
    if (!isPathEdited && pathTemplate === "custom" && title) {
      const generatedSlug = slugify(title);
      if (!path || path === slugify(path)) {
        setValue("path", generatedSlug);
      }
    }
  }, [title, pathTemplate, isPathEdited, setValue]);

  useEffect(() => {
    if (program) {
      reset({
        title: programPage?.title || "",
        body: programPage?.body || "",
        path: programPage?.path || "",
        pathTemplate: pathTemplateDefaultValue,
      });
    }
  }, [program, programPage, reset]);

  const onSubmit = async (data: ProgramPageModel) => {
    setLoading(true);
    setErrorMessages([]);

    let dataToSend = {
      ...data,
    };

    try {
      // Populate path and title with non-custom path template
      if (
        dataToSend?.pathTemplate &&
        specialPaths.includes(dataToSend.pathTemplate)
      ) {
        dataToSend = {
          ...dataToSend,
          path: dataToSend.pathTemplate,
          title: dataToSend.pathTemplate,
        };
      }

      const apiUrl = isProgramPageEditing
        ? `/adminApi/program/${program?.id}/pages/${programPage?.id}`
        : `/adminApi/program/${program?.id}/pages`;
      const { data: result, error: postError } = (await postAsyncData(
        apiUrl,
        dataToSend
      )) as { data: any; error: any };
      if (postError) {
        setErrorMessages(postError?.response?.data?.errors);
      } else {
        // Success
        redirect(`/${program?.id}/pages/${result?.data.path}/show`);
      }
    } catch (error: any) {
      setErrorMessages([error]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {errorMessages?.length > 0 && (
          <Alert severity="error" sx={{ mt: 2 }}>
            <ul>
              {errorMessages.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </Alert>
        )}

        <Box sx={{ mt: 2, mb: 2 }}>
          <SelectElement
            name="pathTemplate"
            label={translate(`resources.pages.pageType`)}
            fullWidth
            options={pathTemplateOptions}
          />

          {pathTemplate === "custom" && (
            <TextFieldElement
              fullWidth
              margin="normal"
              label={translate(`resources.pages.pageTitle`)}
              {...methods.register("title")}
            />
          )}

          {pathTemplate === "custom" && (
            <TextFieldElement
              fullWidth
              margin="normal"
              label={translate(`resources.pages.path`)}
              {...methods.register("path")}
              onChange={() => setIsPathEdited(true)}
            />
          )}

        </Box>
        <Box>
          <Controller
            name="body"
            control={control}
            render={({ field }) => (
              <WysiwygEditor value={field.value} program={program} onChange={field.onChange} />
            )}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          disabled={loading}
        >
          {loading ? translate(`resources.misc.saving`) : translate(`resources.misc.save`)}
        </Button>
      </form>
    </FormProvider>
  );
};

export default ProgramPageForm;
