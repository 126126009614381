import frenchMessages from "ra-language-french";
import { TranslationMessages } from "react-admin";

import { Category } from "@mui/icons-material";

const customFrenchMessages: TranslationMessages = {
  ...frenchMessages,
  pos: {
    programShowTitle: "%{programName}",
    codeListTitle: "%{programName} – codes d'invitation",
    programPageListTitle: "%{programName} – pages rédactionnelles",
    usersListTitle:
      process.env.REACT_APP_ID == "alixmed"
        ? "%{programName} – patients"
        : "%{programName} – utilisateurs",
    search: "Rechercher",
    configuration: "Configuration",
    management: "Gestion",
    language: "Langue",
    dashboard: {
      title: "",
    },
    menu: {
      catalog: "Catalogue",
      codes: "Codes d'invitation",
      users:
        process.env.REACT_APP_ID == "alixmed" ? "Patients" : "Utilisateurs",
      home: "Accueil",
      program: "Programme",
      generateSessions: "Test de séances",
      programPages: "Pages rédactionnelles",
    },
  },
  resources: {
    locale: {
      locale: "fr-FR",
      prefix: "fr",
    },
    home: {
      addProgram: "Ajouter un programme",
    },
    exercises: {
      name: "Exercices",
    },
    sessions: {
      name: "Séances",
      generate: "Générer",
      execute: "Exécuter",
      generateSessions: "Générer des séances",
      iterations: "Itérations",
      randomization: "Randomisation",
      randomField: "Aléatoire",
      user: process.env.REACT_APP_ID == "alixmed" ? "Patient" : "Utilisateur",
      userId:
        process.env.REACT_APP_ID == "alixmed"
          ? "Patient réel"
          : "Utilisateur réel",
      userInfo:
        process.env.REACT_APP_ID == "alixmed"
          ? "Simulation de patient"
          : "Simulation d'utilisateur",
      userGeneralParams: "Séance et niveau",
      userPathologyParams: "Paramètres de programme",
      userProfile: "Profil",
      equipments: "Équipements",
      painAreas: "Zones de douleur",
      avatar: "Avatar",
      language: "Langue",
      module: "Module",
      level: "Niveau",
      moduleScore: "Score de %s",
      healthgoal: "Objectif de santé",
      duration: "Durée",
      author: "Auteur",
      mode: "Mode",
      full: "Complet",
      full_batch: "Complet (batch)",
      exampleAccount: "Compte exemple :",
      warmup: "Échauffements",
      exercises: "Exercices",
      stretching: "Étirements",
      switchSide: "changement de côté",
      generatedSession: "Séance générée",
      inputData: "Données fournies",
      sessionModule: "Séance %{module}",
      bodyPartToWorkOn: "Partie du corps à travailler",
      fatigueLevel: "Niveau de fatigue",
      sessionNumber: "Numéro de séance",
      enduranceScore: "Score d'endurance",
      pasScore: "Score PAS",
      healthgoalScore: "Score objectif santé",
      levelType: "Type de niveau",
      obesityCalculationMode: "Mode de calcul",
      bodyFatPercentage: "Indice de masse grasse (%)",
      obesityFatRatioEvolution: "Masse grasse",
      obesityMuscleRatioEvolution: "Masse musculaire",
      indoorSession: "Renforcement musculaire",
      walkSession: "Marche",
      type: "Type de séance",
      session: "Séance",
      recommendations: "Recommandation de séance",
      generateRecommendations: "Générer",
      generatingSession: "Génération de sessions...",
      generatingRecommendations: "Génération de recommandations...",
      export: "Exporter la séance",
      jsonSource: "Voir la source JSON",
      clearAll: "Vider les séances",
      settings: {
        title: "Paramétrage",
        session: "Séance",
        pathology: "Pathologie",
        profile: "Profil",
      },
      generation: {
        recommendationsSettings: "Paramètres de recommandations",
        sessionSettings: "Paramètres de séances",
        missingUserId: "Aucun utilisateur n'est sélectionné",
        supportedRecommendationGeneration:
          "Générer une recommandations de séance",
        unsupportedRecommendationGeneration:
          "La génération de recommandations n'est pas supportée par ce programme",
        walkSessionError:
          "Erreur lors de la génération de cette séance de marche",
      },
      api: {
        name: "Nom de l'API",
        id: "ID de l'API",
        prompt: "Prompt",
        noApiSettings: "Ulitiser les paramètres par défaut",
        aiModel: "Modèle d'IA",
        programRules: "Règles du programme",
      },
    },
    equipments: {
      // Keep snake case
      name: "Équipements",
      pull_up_bar: "Barres de traction",
      dumbbell: "Haltères",
      stepups_box: "Box Step-Up",
      bike: "Vélo d'intérieur",
      kettlebell: "Kettlebell",
      resistance_band: "Bandes de résistance",
      ankle_wrist_weights: "Poids légers",
      medicine_ball: "Medicine ball",
      dips_bar: "Barres parallèles",
      swiss_ball: "Swiss ball",
      tennis_ball: "Balle de tennis",
      waff: "Coussin d'équilibre",
      water_bottle: "Gourdes/bouteilles",
      stool: "Tabouret",
      chair: "Chaise",
      chair_with_armrests: "Chaise avec accoudoirs",
      table: "Table",
      weighted_backpack: "Gilet lesté",
      pillar: "Pilier",
      wall: "Mur",
      towels: "Serviettes",
      musculation_bench: "Banc de musculation",
    },
    codes: {
      listName: "Liste",
      title: "Code d'invitation %{id}",
      id: "Identifiant",
      name: "Nom",
      program: "Programme",
      refKey:
        process.env.REACT_APP_ID == "alixmed"
          ? "Clé de référence patient"
          : "Clé de référence utilisateur",
      status: "Statut",
      available: "Actif",
      used: "Utilisé",
      expired: "Expiré",
      revoked: "Révoqué",
      uses: "Nombre d'utilisations",
      createdAt: "Créé le",
      updatedAt: "Mis à jour le",
      statusLabel: "Status : ",
      expiresAtLabel: "Date d'expiration : ",
      expiresAt: "Date d'expiration",
      idLabel: "Id : ",
      validation: "Validation",
      validationLabel: "Validation : ",
      validationMethod: "Méthode de validation",
      seeQrCode: "Voir le QR code",
      urlToCopy: "URL à copier",
      download: "Télécharger le QR Code",
      inClipboard: "Copié !",
      copyToClipboardError: "Erreur dans la copie dans le presse-papier.",
      auto: "Automatique",
      manual: "Manuelle",
      use: {
        label: "Usage : ",
        count: "Décompte d'utilisation",
        rest: " restants sur ",
        unlimited: "Illimité",
      },
      edit: {
        setMaxUses: "Nombre d'utilisation maximale",
        setExpiresAtDate: "Définir une date d'expiration",
        rest: " restants sur ",
        unlimited: "Illimité",
        title: "Edition du code d'invitation",
        editSuccess: "Modifications sauvegardées",
        editError: "Erreur lors de la sauvegarde des modifications",
      },
      create: {
        title: "Ajouter un nouveau code d'invitation",
        prefixId: "Préfixe identifiant",
        withCounts: "Limiter le nombre d'utilisations",
        withExpiration: "Limiter la période d'utilisation",
        withUserValidation: "Validation utilisateur",
        maxUses: "Nombre d'utilisations maximal",
        uses: "Nombre d'utilisations",
        createSuccess: "Code d'invitation créé avec succès",
        createError: "Erreur lors de la création du code d'invitation",
        generateLots: "Générer des lots de codes",
        optionName: "Nom de la catégorie",
        optionNumber: "Nombre à générer",
        addCategory: "Ajouter une catégorie",
        removeCategory: "Retirer la catégorie",
        categoryName: "Nom de la catégorie",
        categoryNumber: "Nombre à générer",
        missingOptions: "Aucune catégorie n'est définie pour générer des lots",
        generationNumber: "%s codes seront générés",
        warningManyCategories: "Attention: beaucoup codes seront générés !",
        tooManyCategories: "Attention: trop de codes seront générés !",
        lotsErrors: "%s codes n'ont pas pu être générés",
      },
    },
    programs: {
      name: "%{name}",
      demo: "%{name}",
      incredible: "%{name}",
      all: "Tous",
      description: "Description",
      id: "Identifiant",
      displayName: "Nom",
      generateSessionMode: "Mode de génération de sessions",
      icon: "Icône",
      properties: "Propriétés",
      options: "Options",
      public: "public",
      private: "privé",
      apiSettings: {
        title: "Paramètres d'API",
        recommendations: {
          api: "Modèle de l'API de génération de recommandations",
          apiId: "ID de l'assistant",
          promptTemplate: "Prompt pour la génération de recommandations",
        },
        session: {
          model: "Modèle de l'API de génération de séances",
          programRules: "Prompt des règles du programme",
          promptTemplate: "Prompt pour la génération de séances",
        },
      },
      questions: "Questions",
      questionnaires: "Questionnaires",
      type: "Type",
      selectProgram: "Sélectionnez un programme",
      createSuccess: "Programme créé avec succès",
      createError: "Erreur lors de la création du programme",
      optionName: "Nom de l'option",
      addOption: "Ajouter une option",
      removeOption: "Retirer l'option",
      questionnaireId: "Identifiant du questionnaire",
      addQuestionnaire: "Ajouter un questionnaire",
      removeQuestionnaire: "Retirer le questionnaire",
      questionId: "Identifiant de la question",
      addQuestion: "Ajouter une question",
      removeQuestion: "Retirer la question",
      enum: "Énum",
      enumMultiple: "Multiple",
      addEnum: "Ajouter une constante",
      removeEnum: "Retirer la constante",
      editProgram: "Éditer le programme",
      create: {
        creationRedirectText:
          "Un code d'invitation est nécessaire pour inviter des utilisateurs au nouveau programme. Créer un code d'invitation ?",
        toInviteCode: "Créer un code d'invitation",
        toProgram: "Accéder au programme créé",
      },
      delete: {
        button: "Supprimer le programme",
        confirm: "Supprimer définitivement",
        confirmTitle:
          "Entrez l'identifiant du programme pour confirmer la suppression du programme",
        copyProgram: "Copier le programme dans le presse-papier ?",
        copy: "Copier",
        goBack: "Retourner au menu",
      },
    },
    painAreas: {
      right_shoulder: "Épaule droite",
      left_shoulder: "Épaule gauche",
      right_elbow: "Coude droit",
      left_elbow: "Coude gauche",
      right_wrist: "Poignet droit",
      left_wrist: "Poignet gauche",
      right_hip: "Hanche droite",
      left_hip: "Hanche gauche",
      right_knee: "Genou droit",
      left_knee: "Genou gauche",
      right_ankle: "Cheville droite",
      left_ankle: "Cheville gauche",
      upper_back: "Haut du dos",
      lower_back: "Bas du dos",
    },
    pages: {
      title: "Pages rédactionnelles",
      info: "Page d'information",
      paymentinfo: "Page d'information paiement",
      custom: "Page personnalisée",
      previewinfoindoorsession: "Avant une séance indoor",
      previewinfowalksession: "Avant une séance de marche",
      path: "Chemin d'accès",
      pageType: "Type de page",
      pageTitle: "Titre administratif",
      previewResolution: "Résolution",
      previewLightOrDarkMode: "Mode clair ou sombre",
      previewDarkMode: "🌙 Sombre",
      previewLightMode: "🌞 Clair",
      create: {
        title: "Créer une nouvelle page",
      },
      edit: {
        title: "Éditer `%{title}`",
      },
    },
    generalBodyParts: {
      lower_body: "Bas du corps",
      upper_body: "Haut du corps",
      lower_back: "Bas du dos",
    },
    fatigueLevels: {
      inShape: "En forme",
      littleTired: "Un peu fatigué",
      veryTired: "Très fatigué",
    },
    obesityCalculationModes: {
      bmi: "IMC",
      connectedScale: "Balance connectée",
    },
    obesityRatioEvolutions: {
      increasing: "En augmentation",
      decreasing: "En baisse",
    },
    modules: {
      name: "Modules",
      balance: "Balance",
      endurance: "Endurance",
      power: "Puissance",
      resistance: "Résistance",
      low: "Basse (0)",
      medium: "Moyenne (1)",
      high: "Bonne (2)",
      veryHigh: "Très bonne (3)",
    },
    healthgoals: {
      fit: "Fit",
      ven: "Respiration",
      sle: "Sommeil",
      msd: "TMS",
      obe: "Poids",
    },
    users: {
      name: process.env.REACT_APP_ID == "alixmed" ? "Patients" : "Utilisateurs",
      title:
        process.env.REACT_APP_ID == "alixmed"
          ? "Patient %{id}"
          : "Utilisateur %{id}",
      email: "Courriel",
      phoneNumber: "Numéro de téléphone",
      age: "Âge",
      gender: "Genre",
      female: "Femme",
      male: "Homme",
      nonbinary: "Non-binaire",
      id: "Identifiant",
      firstname: "Prénom",
      lastname: "Nom",
      patientId: "Référence",
      createdAt: "Créé le",
      updatedAt: "Dernière activité",
      membershipStartedAt: "Programme rejoint le",
      nickname: "Pseudo",
      dateOfBirth: "Date de naissance",
      monthOfBirth: "Mois de naissance",
      height: "Taille (cm)",
      weight: "Poids (kg)",
      programParams: {
        title: "Questions de paramétrage",
        noAnswerFound: "Aucune réponse trouvée",
        newAnswer: "Nouvelle réponse",
        formSuccess: "Réponses enregistrées",
        formError: "Les réponses n'ont pas pu être enregistrées",
        noQuestionnaire: "Aucun questionnaire associé",
      },
      sessions: {
        title: "Séances",
        sessionOf: "Séance du ",
        walkSessionOf: "Séance de marche du %{date}",
        indoorSessionOf: "Séance indoor du %{date}",
        sessionNumberOf: "Séance n°%{count} du %{date}",
        walkSessionNumberOf: "Séance de marche n°%{count} du %{date}",
        indoorSessionNumberOf: "Séance indoor n°%{count} du %{date}",
        sessionWithSensor: "Séance réalisée avec capteur : ",
        data: "Données",
        heartBeat: "Rythme cardiaque",
        bpm: "BPM",
        min: "Min.",
        avd: "Moyen",
        max: "Max.",
        exerciseDuration: "Durée",
        averagePace: "Allure (min/km)",
        averageSpeed: "Vitesse (km/h)",
        distance: "Distance (m)",
        difficulty: "Difficulté",
        walkEasy: "Tranquille",
        walkMedium: "Normale",
        walkHard: "Soutenue",
        walkAcceleration: "Accélération",
        walkVeryHard: "À fond",
        rating: "Évaluation",
        seeMore: "Voir plus de séances",
        indoorSessionsCompleted: "Séances d'exercices effectuées",
        walkSessionsCompleted: "Séances de marche effectuées",
        noCompletedSession: "Aucune séance effectuée pour le moment.",
      },
      charts: {
        stepsTitle: "Nombre de pas",
        activityTitle: "Temps d'activité",
        stepsLegend: "Nombre de pas par jour",
        activityLegend: "Temps d'activité journalier",
        unitSteps: " pas",
        unitActivity: " min.",
      },
      recommendations: {
        title: "Recommandations",
        noRecommendation: "Aucune recommandation",
        clickToView: "Cliquer pour voir les recommandations",
        generateNew: "Générer une nouvelle recommandation",
      },
      form: {
        editSuccess: "Utilisateur édité avec succès",
        editError: "Erreur lors de l'édition de l'utilisateur",
      },
      null: "-",
      eventLogList: "Événements du compte",
    },
    sessionGenerator: {
      showValidSessions: "Afficher les séances valides.",
      showExercisesSectionList:
        "Afficher le contenu de la section des Exercices",
      incorrectPauseBefore:
        "Durée de pause incorrecte avant `%{exerciseVariantId}` dans la section %{section}. Devrait être %{validPause} s mais %{foundPause} trouvé.",
      unilateralVariantExerciseNotPaired:
        "L'exercice `%{exerciseVariantId}` dans la section %{section} est unilatéral mais n'est pas associé à sa variante jumelle.",
      unknownVariantId:
        "Identifiant de variante d'exercice inconnu : `%{exerciseVariantId}`",
      unsatisfiedEquipmentWithNoUserEquipments:
        "L'exercice `%{exerciseVariantId}` requiert l'équipement `%{requiredEquipment}`. Mais l'utilisateur ne dispose d'aucun équipement.",
      unsatisfiedEquipment:
        "L'exercice `%{exerciseVariantId}` requiert l'équipement `%{requiredEquipment}`. Mais l'utilisateur ne dispose que des équipements suivants : %{userEquipments}",
      incorrectNumberOfComponentInExercisesSection:
        "Le nombre de composants dans la section d'exercices est incorrect. Il doit être égal à 10 fois le nombre d'itérations. Nombre attendu : %{expectedNumberOfComponents}. Nombre trouvé : %{foundNumberOfComponents}",
      incorrectExerciseDuration:
        "Le composant `%{exerciseVariantId}` dans la section %{section} devrait durer %{expectedDuration} s. Trouvé : %{foundDuration}.",
      unsatisfiedPainAreaCondition:
        "L'exercice `%{exerciseVariantId}` dans la section %{section} sollicite des zones pour lesquels l'utilisateur a indiqué avoir mal : %{painAreas}.",
      minDurationNotReached:
        "La séance n'atteint pas la durée minimale requise de %{expectedMinDuration} s. Trouvé : %{foundDuration} s.",
    },
    videoGenerator: {
      title: "Exporter la séance pour la génération de vidéos",
      submit: "Exporter",
      programId: "Nom du programme",
      creator: "Créateur",
      avatar: "Avatar",
      portraitMode: "Mode portrait",
      UHDExerciseVideos: "Ultra Haute Définition (4K)",
      introCreditDuration: 'Durée de l\'intro "Crédits"',
      introAvatarDuration: 'Durée de l\'outro "Crédits"',
      outroCreditDuration: 'Durée de l\'intro "Avatar"',
      outroAvatarDuration: 'Durée de l\'outro "Avatar"',
      withPreview:
        "Affichage de la prévisualisation d'exercice pendant les pauses",
      withBHLogo: "Affichage logo Beo Healthcare",
      withWhiteAlixLogo: "Affichage logo Alix Blanc (pour miroir) ",
      withSecondLogo: "Affichage logo secondaire",
      withAdvice: 'Affichage  "Information de nos médecins" en intro',
      withScrollingText: "Affichage texte défilant en bas d'écran",
      withPauseAlixVoiceOver: "Avec voix off Pause Alix",
      musicIntensity: "Intensité de la musique",
      musicVolume: "Volume de la musique (entre 0 et 1)",
      languages: "Langues",
      sessionNumber: "Numéro de séance",
      components: {
        title: "Composants",
        component: "Composant",
        session: "Séance",
        pause: "Pause",
        duration: "Durée",
        id: "Identifiant",
        section: "Section",
        speed: "Vitesse",
        zoom: "Zoom",
      },
    },
    misc: {
      none: "Aucun",
      errors: "Erreurs",
      error: "Erreur |||| Erreurs",
      success: "Succès",
      noData: "Aucune donnée",
      close: "Fermer",
      yes: "Oui",
      no: "Non",
      total: "Total",
      validate: "Valider",
      create: "Créer",
      save: "Enregistrer",
      saving: "Envoi en cours...",
      cancel: "Annuler",
      id: "ID",
      name: "Nom",
      value: "Valeur",
      goBack: "Retour",
      edit: "Éditer",
      details: "Détails",
    },
  },
  warning: {
    phone_prefix:
      "Veuillez ajouter l'indicatif pays dans le numéro recherché (ex: +336XXXXXXXX)",
  },
  errors: {
    user:
      process.env.REACT_APP_ID == "alixmed"
        ? "Erreur : Patient non trouvé"
        : "Erreur : Utilisateur non trouvé",
  },
  wysiwyg: {
    bold: "Gras",
    italic: "Italique",
    underline: "Souligné",
    h1: "Titre 1",
    h2: "Titre 2",
    h3: "Titre 3",
    sourcecode: "Code source",
    addLink: "Ajout d'un lien",
    editLink: "Mise à jour du lien",
    unsetLink: "Suppression du lien",
    alignLeft: "Aligner à gauche",
    alignCenter: "Centrer",
    alignRight: "Aligner à droite",
    alignJustify: "Justifier le texte",
    bulletList: "Liste à puces",
    orderedList: "Liste ordonnée",
    addCard: "Ajouter un encart",
    cardTitle: "Titre",
    addSection: "Nouvelle section",
    addVideo: "Ajout d'une vidéo",
    pasteLinkUrl: "Entrez l'URL du lien",
    pasteVideoUrl: "Collez une URL de vidéo YouTube, Vimeo ou Dailymotion :",
    unknownVideoUrl: "URL de vidéo non reconnue ou non supportée.",
    selectFile: "Sélectionnez un fichier",
    dragDropImage: "Glissez-déposez une image ici",
    uploadImage: "Insérer une image",
  },
};

export default customFrenchMessages;
