import { useTranslate } from "ra-core";
import { Title } from "react-admin";
import { useParams } from "react-router-dom";
import { Box, CircularProgress, Stack } from "@mui/material";
import { useFetchData } from "../utils/useAdminApi";
import ProgramPageForm from "./ProgramPageForm";
import { ProgramModel } from "../program/model";
import { ProgramPageModel } from "./model";

export const ProgramPageEdit = (props) => {
  const translate = useTranslate();
  const { programId, path } = useParams();

  const {
    data: program,
    loading: programLoading,
    error: programError,
  } = useFetchData(`/adminApi/program/${programId}`) as {
    data: ProgramModel;
    loading: boolean;
    error: any;
  };
  const {
    data: programPage,
    loading: programPageLoading,
    error: programPageError,
  } = useFetchData(`/adminApi/program/${programId}/pages/${path}`) as {
    data: ProgramPageModel;
    loading: boolean;
    error: any;
  };
  if (programLoading || programPageLoading || !program) {
    return <CircularProgress size={25} thickness={2} />;
  }

  if (programError || programPageError) {
    return <>ERROR</>;
  }

  return (
    <div>
      {/* Program data */}
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        {program && (
          <>
            <Title
              title={translate(`pos.programShowTitle`, {
                programName: program.displayName,
              })}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={2}
            >
              <h1>
                {translate("resources.pages.edit.title", {
                  title: programPage.title,
                })}
              </h1>
            </Box>

            <ProgramPageForm program={program} programPage={programPage} />
          </>
        )}
      </Stack>
    </div>
  );
};

export default ProgramPageEdit;
