import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer,
} from "recharts";

export default function DimensionsRadarChart(props) {
  const chartData = props.data;

  return (
    <ResponsiveContainer height={300} id="dimensions-radar">
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={chartData}>
        <PolarGrid />
        <PolarAngleAxis dataKey="name" tick={{ textAnchor: "middle" }} />
        <PolarRadiusAxis domain={[0, 10]} />
        <Radar
          name="Dimensions"
          dataKey="value"
          stroke="#E6738E"
          fill="#E6738E"
          fillOpacity={0.6}
        />
      </RadarChart>
    </ResponsiveContainer>
  );
}
