import { useTranslate } from "ra-core";
import { Title, useRedirect } from "react-admin";
import { useParams } from "react-router-dom";

import { Description, Info, ShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { theme } from "../layout/themes";
import { useFetchData } from "../utils/useAdminApi";
import { ProgramModel } from "../program/model";
import useRouteExists from "../utils/useRouteExists";
import { ProgramPageModel } from "./model";
import { ReactNode } from "react";

/**
 * Function to process the data by adding an "icon" attribute and sorting items based on specific rules.
 * @param data - Array of objects to be processed.
 * @returns A new sorted array with an additional "icon" attribute.
 */
const processProgramPageList = (
  translate,
  data: ProgramPageModel[]
): ProgramPageModel[] => {
  // Define the icon based on the "path" value
  const getIcon = (path: string): ReactNode => {
    switch (path) {
      case "info":
        return <Info />;
      case "paymentinfo":
        return <ShoppingCart />;
      default:
        return <Description />;
    }
  };

  // Set title
  const getTitle = (item: ProgramPageModel): string => {
    switch (item.path) {
      case "info":
        return translate(`resources.pages.info`);
      case "paymentinfo":
        return translate(`resources.pages.paymentinfo`);
      default:
        return item.title;
    }
  };

  // Add the "icon" attribute to each object
  const enrichedData = data.map((item) => ({
    ...item,
    icon: getIcon(item.path),
    title: getTitle(item),
  }));

  // Check if there is an item with path "info"
  const hasInfo = enrichedData.some((item) => item.path === "info");

  // Sort the items based on the required priority
  return enrichedData.sort((a, b) => {
    // "info" should always come first
    if (a.path === "info") return -1;
    if (b.path === "info") return 1;

    // "infopayment" should be second unless "info" is absent
    if (a.path === "infopayment") return hasInfo ? 1 : -1;
    if (b.path === "infopayment") return hasInfo ? -1 : 1;

    // Sort remaining items alphabetically by title
    return a.title.localeCompare(b.title);
  });
};

export const ProgramPageList = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const routeExists = useRouteExists();

  const { programId } = useParams();
  // const { control, handleSubmit, register, watch } = useForm({ defaultValues: { confirmDelete: "" } });

  const {
    data: program,
    loading: programLoading,
    error: programError,
  } = useFetchData(`/adminApi/program/${programId}`) as {
    data: ProgramModel;
    loading: boolean;
    error: any;
  };
  const {
    data: rawProgramPages,
    loading: programPagesLoading,
    error: programPagesError,
  } = useFetchData(`/adminApi/program/${programId}/pages`) as {
    data: ProgramPageModel[];
    loading: boolean;
    error: any;
  };
  if (programLoading || programPagesLoading || !program) {
    return <CircularProgress size={25} thickness={2} />;
  }

  const items = processProgramPageList(translate, rawProgramPages);

  // let items = [
  //   { title: translate(`resources.pages.info`), Icon: Info, path: `/${program.id}/pages/info` },
  // ];

  // let programOptions = program.options

  // if(programOptions?.paymentInfoWebview) {
  //   items.push({ title: translate(`resources.pages.paymentinfo`), Icon: ShoppingCart, path: `/${program.id}/pages/paymentinfo` })
  // }

  return (
    <div>
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        <Title
          title={translate(`pos.programPageListTitle`, {
            programName: program?.displayName,
          })}
        />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <h1>{translate(`resources.pages.title`)}</h1>
          {routeExists(`/${programId}/pages/create`) ? (
            <Button onClick={() => redirect(`/${programId}/pages/create`)}>
              {translate("resources.pages.create.title")}
            </Button>
          ) : null}
        </Box>

        <Box display="flex" flexWrap="wrap" alignContent="flex-start">
          {items
            .filter((item) =>
              routeExists(`/${program.id}/pages/${item.path}/show`)
            )
            .map((item, index) => (
              <Box
                key={index}
                m={1}
                onClick={() =>
                  redirect(`/${program.id}/pages/${item.path}/show`)
                }
                style={{ cursor: "pointer" }}
              >
                <Paper
                  style={{
                    width: "180px",
                    height: "180px",
                    display: "flex",
                    padding: "10px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.secondary.contrastText,
                    textAlign: "center",
                  }}
                >
                  <Box>{item.icon}</Box>
                  <Typography variant="h5">{item.title}</Typography>
                </Paper>
              </Box>
            ))}
        </Box>
      </Stack>
    </div>
  );
};
