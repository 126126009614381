import { Image } from "@tiptap/extension-image";
import { mergeAttributes } from "@tiptap/react";
import { Node, mergeAttributes as mergeNodeAttributes } from '@tiptap/core';


export const ImageNode = Image.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      sizes: ["inline", "block", "left", "right"],
      HTMLAttributes: {},
    };
  },
  
  renderHTML({ HTMLAttributes }) {
    const { style } = HTMLAttributes;
    return [
      "figure",
      { style },
      ["img", mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    ];
  }
});
