import { useTranslate } from "ra-core";
import { Title, useRedirect } from "react-admin";
import { useParams } from "react-router-dom";

import { Description, Subscriptions } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import inviteCodes from "../inviteCodes";
import { theme } from "../layout/themes";
import users from "../users";
import { useFetchData } from "../utils/useAdminApi";
import { ProgramModel } from "./model";
import useRouteExists from "../utils/useRouteExists";

const MenuBlocks = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const routeExists = useRouteExists();
  const program = props.program;

  let groupedItems = [
    {
      title: translate(`pos.management`),
      items: [
        {
          title: translate(`pos.menu.codes`),
          Icon: inviteCodes.icon,
          path: `/${program.id}/invite-codes`,
        },
        {
          title: translate(`pos.menu.users`),
          Icon: users.icon,
          path: `/${program.id}/users`,
        },
        {
          title: translate(`pos.menu.programPages`),
          Icon: Description,
          path: `/${program.id}/pages`,
        },
        {
          title: translate(`pos.menu.generateSessions`),
          Icon: Subscriptions,
          path: `/${program.id}/sessions/generate`,
        },
      ],
    },
  ];

  return (
    <>
      {groupedItems.map((section, sectionIndex) => (
        <div key={sectionIndex}>
          <h2>{section.title}</h2>
          <Box display="flex" flexWrap="wrap" alignContent="flex-start">
            {section.items
              .filter((item) => routeExists(item.path))
              .map(({ title, Icon, path }, itemIndex) => (
                <Box
                  key={itemIndex}
                  m={1}
                  onClick={() => redirect(path)}
                  style={{ cursor: "pointer" }}
                >
                  <Paper
                    style={{
                      width: "180px",
                      height: "180px",
                      display: "flex",
                      padding: "10px",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.secondary.contrastText,
                      textAlign: "center",
                    }}
                  >
                    <Icon style={{ marginBottom: "10px" }} />
                    <Typography variant="h5">{title}</Typography>
                  </Paper>
                </Box>
              ))}
          </Box>
        </div>
      ))}
    </>
  );
};

export const ProgramShow = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const routeExists = useRouteExists();

  // const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  // const [deletedProgramData, setDeletedProgramData] = useState<any>(null);
  // const [deleteError, setDeleteError] = useState<any>(null);
  // const [copySuccess, setCopySuccess] = useState<any>(false);

  const { programId } = useParams();
  // const { control, handleSubmit, register, watch } = useForm({ defaultValues: { confirmDelete: "" } });

  const { data, loading, error } = useFetchData(
    `/adminApi/program/${programId}`
  ) as { data: ProgramModel; loading: boolean; error: any };

  if (loading || !data) {
    return <CircularProgress size={25} thickness={2} />;
  }

  // async function handleDelete() {
  //   // Reset states
  //   setDeleteError(null);
  //   setDeletedProgramData(null);

  //   // Try to delete the program
  //   const { response, error } = await callAPI({ apiPath: `/adminApi/program/${programId}`, type: 'delete' });

  //   // Set results and toggle dialogs
  //   setDeleteDialogOpen(false);

  //   if (response) {
  //     setDeletedProgramData(response.deletedItem);
  //     setDeleteSuccessOpen(true);
  //   } else {
  //     setDeleteError(error);
  //   }
  // }

  // function copyToClipboard() {
  //   navigator.clipboard.writeText(JSON.stringify(deletedProgramData)).then(() => {
  //     setCopySuccess(true)
  //     setTimeout(() => redirect('/'), 3000);
  //   });
  // };

  return (
    <div style={{ padding: "16px 0" }}>
      {/* Program data */}
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        {data && (
          <>
            <Title
              title={translate(`pos.programShowTitle`, {
                programName: data.displayName,
              })}
            />

            <Box display="flex" alignItems="center">
              <h1>{data.displayName}</h1>
              <Box display="flex" gap={1} marginLeft="auto">
                {routeExists(`/${programId}/program/edit`) ? (
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => redirect(`/${programId}/program/edit`)}
                  >
                    {translate("resources.programs.editProgram")}
                  </Button>
                ) : null}
              </Box>
            </Box>
          </>
        )}
        <MenuBlocks program={data} />
      </Stack>

      {/* Delete dialog button */}
      {/* {routeExists(`/${programId}/edit`) ? // If the user has the right to edit the program, he has the right to delete it.
        <>
        <div style={{ position: 'absolute', bottom: 24, right: 24 }}>
          <Button variant='contained' color='error' onClick={() => setDeleteDialogOpen(true)}>{translate('resources.programs.delete.button')}</Button>
        </div>
        <Dialog open={deleteDialogOpen} maxWidth='md'>
          <DialogContent>
            <form onSubmit={handleSubmit((data) => handleDelete())} style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
              <Alert severity='warning' sx={{ display: 'flex' }}>
                <Typography variant='subtitle1'>{translate('resources.programs.delete.confirmTitle')}:</Typography>
                <Typography variant='h3' textAlign='center'>{programId}</Typography>
              </Alert>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <TextField {...register('confirmDelete')} sx={{ maxWidth: 'fit-content' }} size='small' fullWidth />
              </div>
              <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant='contained' onClick={() => setDeleteDialogOpen(false)} autoFocus>{translate('resources.misc.cancel')}</Button>
                <Button variant='outlined' color='error' type='submit'
                  disabled={watch('confirmDelete') !== programId}
                >{translate('resources.programs.delete.confirm')}</Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog> */}

      {/* Delete feedback */}
      {/* {deleteError &&
          <Alert severity='error'>{JSON.stringify(deleteError)}</Alert>
        } */}

      {/* Post-deletion dialog */}
      {/* <Dialog open={deleteSuccessOpen} maxWidth='md'>
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', rowGap: 3, padding: 2 }}>
            <Typography variant='subtitle1'>{translate('resources.programs.delete.copyProgram')}</Typography>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button variant='contained' onClick={() => copyToClipboard()} autoFocus color={copySuccess ? 'success' : undefined}>
                {copySuccess ?
                  <Check />
                  : translate('resources.programs.delete.copy')
                }
              </Button>
              <Button variant='contained' onClick={() => redirect('/')} >{translate('resources.programs.delete.goBack')}</Button>
            </DialogActions>
          </DialogContent>
  
        </Dialog>
        </>
        : null */}
      {/* } */}
    </div>
  );
};

export default ProgramShow;
