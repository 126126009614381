import {
  camelCase,
  concat,
  Dictionary,
  flattenDeep,
  get,
  isEmpty,
  omit,
  pick,
  range,
  size,
} from "lodash";
import moment from "moment";
import { useSafeSetState, useTranslate } from "ra-core";
import React, { useEffect, useState } from "react";
import { Title, Translate } from "react-admin";
import { Control, Controller, useForm, UseFormWatch } from "react-hook-form";
import {
  AutocompleteElement,
  MultiSelectElement,
  RadioButtonGroup,
  SelectElement,
  SwitchElement,
  TextFieldElement,
} from "react-hook-form-mui";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import config from "../config";

import { API, graphqlOperation } from "@aws-amplify/api";
import {
  CheckCircle,
  Circle,
  CompareArrows,
  DeleteForever,
  DirectionsWalk,
  ExpandMore,
  FitnessCenter,
  Pending,
  PlayArrow,
  SettingsSuggest,
  Timer,
  Tune,
} from "@mui/icons-material";
import ErrorIcon from "@mui/icons-material/Error";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Fab,
  Grid,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

import awsconfig from "../aws-exports";
import { generateSession } from "../graphql/mutations";
import { callAPI, getAsyncFetchData } from "../utils/useAdminApi";
import ExportVideoJSON from "./ExportVideoJSON";
import RawSessionOutputDialog from "./RawSessionOutputDialog";

const environment = String(process.env.REACT_APP_ID);
const currentConfig = config[environment];

enum CheckResultStatus {
  NoInfo,
  Pending,
  Success,
  Error,
}

type ReactHookFormMUIList = Array<{ id: string | number; label: string }>;

type FormTypes = {
  // Data sent to API:
  debug: boolean;
  mode: "userId" | "userInfo"; // Generation mode
  type: "walk" | "indoor"; // Session type
  lang: string; // Device language
  id?: string; // User ID
  userInfo: UserInfo; // User information used if user ID not provided
  recommendation: RecommendationsAIParams; // Params to generate recommendations
  session: SessionAIParams; // Params to generate sessions

  // Util fields:
  randomizedGeneralParams: RandomizedField;
  randomizedProgramParams: RandomizedQuestionnaire;
  age: number; // Age field to compute the date of birth
};

type UserInfo = {
  sessionsCompleted?: number; // Session number
  iterations?: number; // Fit iterations
  program?: string; // program ID
  programParams?: ProgramQuestionnaireParams; // Program params
  recommendations?: string; // Recommendations previously generated
  module?: string; // Selected module name
  moduleLevel?: number; // Selected module difficulty level
  modules?: {
    // Dictionary of modules with their difficulty level
    [key: string]: number;
  };
  equipments?: Array<string>; // List of equipments
  painAreas?: Array<string>; // List of pain areas
  avatar?: string; // Avatar selected
  dateOfBirth?: string; // Date of birth in ISO format
  gender?: string; // Patient's gender
};

type ProgramQuestionnaireParams = {
  [key: string]: ProgramParams;
};

type ProgramParams = {
  [key: string]: string | number | boolean;
};

type RandomizedQuestionnaire = {
  [key: string]: RandomizedField;
};

type RandomizedField = {
  [key: string]: boolean;
};

type RecommendationsAIParams = {
  api: string; // API name
  apiId: string; // API ID
  promptTemplate: string; // Recommendation prompt
};

type SessionAIParams = {
  model: string; // AI model
  programRules: string; // Session rules prompt
  promptTemplate: string; // Session generation prompt
};

dayjs.extend(localizedFormat);

function BatchSessionsSummary({
  sessions,
  translate,
}: {
  sessions: Array<any>;
  translate: Translate;
}) {
  let numErrors = sessions.filter(
    (session) => session.status == CheckResultStatus.Error
  ).length;
  let numSuccess = sessions.filter(
    (session) => session.status == CheckResultStatus.Success
  ).length;
  return (
    <Paper
      sx={{
        display: "flex",
        columnGap: 2,
        padding: "2px 16px",
        width: "fit-content",
        backgroundColor: "#f1eee6",
        border: "none",
      }}
      elevation={1}
    >
      <div style={{ display: "flex", columnGap: 4 }}>
        <Typography>{translate("resources.misc.success")}</Typography>
        <Typography color="green" fontWeight="bold">
          {numSuccess}
        </Typography>
      </div>
      <Divider
        orientation="vertical"
        sx={{ backgroundColor: "black", width: 2 }}
        flexItem
      />
      <div style={{ display: "flex", columnGap: 4 }}>
        <Typography>{translate("resources.misc.errors")}</Typography>
        <Typography color="red" fontWeight="bold">
          {numErrors}
        </Typography>
      </div>
    </Paper>
  );
}

function StatusSymbol({ status }: { status: CheckResultStatus }) {
  return status == CheckResultStatus.NoInfo ? (
    <Circle fontSize="inherit" />
  ) : status == CheckResultStatus.Pending ? (
    <Pending fontSize="inherit" />
  ) : status == CheckResultStatus.Error ? (
    <ErrorIcon fontSize="inherit" color="error" />
  ) : status == CheckResultStatus.Success ? (
    <CheckCircle fontSize="inherit" color="success" />
  ) : null;
}

// Session type switch component
function TypeButtonGroup({
  control,
  watch,
  register,
  typeList,
  translate,
}: {
  control: Control<FormTypes>;
  watch: UseFormWatch<FormTypes>;
  register;
  typeList;
  translate: Translate;
}) {
  const typeValue = watch("type");
  const typeField = register("type");

  return (
    <div>
      <Controller
        control={control}
        {...typeField}
        render={({ field }) => {
          return (
            <div>
              <InputLabel shrink required>
                {translate("resources.sessions.type")}
              </InputLabel>
              <ButtonGroup fullWidth sx={{ maxWidth: "100px" }}>
                {typeList.map((type, index) => (
                  <Button
                    size="small"
                    key={index}
                    onClick={() => field.onChange(type.id)}
                    variant={typeValue === type.id ? "contained" : "outlined"}
                    sx={{
                      boxShadow:
                        typeValue === type.id
                          ? "inset black 0px 0px 32px -16px;"
                          : "none",
                    }}
                  >
                    {type.icon}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          );
        }}
      />
    </div>
  );
}

function TooltipButton({
  disabled,
  onClickAction,
  disabledTitle,
  enabledTitle = "",
  icon,
  text,
}: {
  disabled: boolean;
  onClickAction: Function;
  disabledTitle?: string;
  enabledTitle?: string;
  icon?: any;
  text?: string;
}) {
  return (
    <Tooltip
      title={disabled ? disabledTitle : enabledTitle}
      arrow
      placement="top"
    >
      <Button
        variant="contained"
        sx={{ minWidth: "fit-content", padding: "8px 16px", flex: "0 1 100px" }}
        onClick={() => onClickAction()}
        disabled={disabled}
        startIcon={icon}
      >
        {text ? text : icon}
      </Button>
    </Tooltip>
  );
}

// Function to generate recommendations base on user informations
async function generateRecommendations({
  mode,
  userId,
  userInfo,
  apiSettings,
  programId,
}: {
  mode: string;
  userId?: string;
  userInfo?: any;
  apiSettings?: any;
  programId: string | undefined;
}) {
  // Initialize variables
  const apiName = currentConfig.apiName;
  const apiPath =
    mode === "user_id"
      ? `/adminApi/user/${userId}/recommendations`
      : `/adminApi/program/${programId}/recommendations`;

  // Skip if API function name is undefined
  if (!apiName || !apiPath) {
    for (const property of [apiName, apiPath]) {
      if (!property) console.log(`Warning: ${property} is undefined`);
    }
    throw new Error("API name or path are undefined");
  }

  // Prepare extra params
  const extraParams = {
    aiParams: size(apiSettings) > 0 ? apiSettings : undefined,
    updateTable: false,
  } as Dictionary<any>;

  if (mode === "user_id") extraParams.id = userId;
  else extraParams.userInfo = { ...userInfo, program: programId };

  // Call API and set result to state
  const { response, error } = await callAPI({
    apiName,
    apiPath,
    type: "post",
    extraParams,
  });

  // Set the result
  return {
    codeStatus: response ? response.statusCode : undefined,
    value:
      response && response.statusCode === 200
        ? JSON.parse(response.body).value || undefined
        : undefined,
    error:
      response && response.statusCode !== 200
        ? JSON.parse(response.body)
        : error,
  };
}

function RecommendationsGenerationButton({
  watch,
  translate,
  status,
  statusSetter,
  resultSetter,
  dialogStateSetter,
  programId,
}: {
  watch: UseFormWatch<FormTypes>;
  translate: Translate;
  status: string;
  statusSetter: React.Dispatch<React.SetStateAction<string>>;
  resultSetter: React.Dispatch<React.SetStateAction<any>>;
  dialogStateSetter: React.Dispatch<React.SetStateAction<boolean>>;
  programId: string | undefined;
}) {
  // Get selected mode and api settings
  const mode = watch("mode");
  const apiSettings = watch("recommendation");

  // Get userInfo
  const programParams = watch("userInfo.programParams");
  const userInfo = {
    programParams: programParams,
  };

  // Get userId
  const userId = watch("id");

  // Validation
  const isUserIdMissing = mode === "userId" ? !userId : false;
  const areApiSettingsMissing =
    !apiSettings ||
    !apiSettings.api ||
    !apiSettings.apiId ||
    !apiSettings.promptTemplate;

  async function onClickGenerateRecommendations() {
    // set feedback status
    statusSetter("loading");
    resultSetter({});

    // Close recommendation dialog
    dialogStateSetter(false);

    try {
      const recommendations = await generateRecommendations({
        userId,
        userInfo,
        apiSettings,
        programId,
        mode,
      });
      statusSetter("success");
      resultSetter(recommendations);
    } catch (error) {
      statusSetter("error");
    }
  }

  return (
    <Tooltip
      title={
        isUserIdMissing
          ? translate("resources.sessions.generation.missingUserId")
          : ""
      }
    >
      <Button
        variant="contained"
        onClick={async () => await onClickGenerateRecommendations()}
        disabled={
          isUserIdMissing || areApiSettingsMissing || status === "loading"
        }
      >
        {translate("resources.sessions.generateRecommendations")}
      </Button>
    </Tooltip>
  );
}

const Rectangle = styled("div", {
  shouldForwardProp: (prop) => prop !== "percentage" && prop !== "color",
  name: "Rectangle",
  slot: "Root",
})<{ percentage: number; color: string }>(({ percentage, color }) => {
  return {
    height: "30px",
    width: `${percentage}%`,
    backgroundColor: color,
  };
});

function SessionHeader({
  session,
  translate,
}: {
  session: any;
  translate: Translate;
}) {
  return (
    <>
      <div style={{ padding: "0 8px" }}>
        <Typography variant="h5" fontWeight="bold" color="primary">
          {session.result.name}
        </Typography>
        <div style={{ display: "flex", columnGap: 16 }}>
          {session.result.duration && (
            <div style={{ display: "flex", columnGap: 8 }}>
              <Typography sx={{ textDecoration: "underline" }}>
                {translate("resources.sessions.duration")}:{" "}
              </Typography>
              <Typography fontWeight="bold" color="primary">
                {moment
                  .utc(
                    moment
                      .duration({ seconds: session.result.duration })
                      .as("milliseconds")
                  )
                  .format("mm[:]ss")}
              </Typography>
            </div>
          )}
          {session.result.module && (
            <div style={{ display: "flex", columnGap: 8 }}>
              <Typography sx={{ textDecoration: "underline" }}>
                {translate("resources.sessions.module")}:{" "}
              </Typography>
              <Typography fontWeight="bold" color="primary">
                {session.result.module}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function SessionsDisplay() {}

function WalkSessionDisplay({ sessions }: { sessions: Array<any> }) {
  const translate = useTranslate();
  const colors = {
    easy: "#83B87A",
    medium: "#EFBB73",
    hard: "#F66868",
    very_hard: "#3D3D3D",
    acceleration: "#C1292E",
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: 4,
        marginTop: 4,
      }}
    >
      {sessions.map((session, sessionIndex) => {
        // Return warning if session isn't correctly defined
        if (
          !session.result ||
          !session.result.components ||
          session.result.components.length < 1
        )
          return (
            <>
              <div style={{ display: "flex", marginTop: 8 }}>
                <Typography>
                  {translate("resources.sessions.session")}:
                </Typography>
                <Typography color="primary">{sessionIndex}</Typography>
              </div>
              <Alert severity="warning">
                {translate("resources.sessions.generation.walkSessionError")}
              </Alert>
            </>
          );

        return (
          <>
            {sessions.length > 0 && (
              <div style={{ display: "flex", marginTop: 8 }}>
                <Typography>
                  {translate("resources.sessions.session")}:
                </Typography>
                <Typography color="primary">{sessionIndex}</Typography>
              </div>
            )}
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
                columnGap: 2,
                padding: 2,
                backgroundColor: "#f1eee6",
                border: "none",
              }}
              elevation={1}
            >
              <SessionHeader {...{ session, translate }} />

              {/* Durations */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: "10px",
                }}
              >
                {session.result.components.map((section, index) => {
                  const duration =
                    (section.duration / session.result.duration) * 100;
                  return (
                    <div
                      key={index}
                      style={{
                        width: `${duration || 0}%`,
                        textAlign: "center",
                        fontSize: "12px",
                        whiteSpace: "nowrap", // Prevent text from wrapping
                        display: "flex", // Flexbox layout
                        justifyContent: "center", // Center content horizontally
                        alignItems: "center", // Center content vertically
                      }}
                    >
                      <Typography fontSize="10px">
                        {moment
                          .utc(
                            moment
                              .duration({ seconds: section.duration })
                              .as("milliseconds")
                          )
                          .format(section.duration < 60 ? "s" : "m[:]ss")}
                      </Typography>
                    </div>
                  );
                })}
              </div>

              {/* Stacked bar */}
              <div style={{ display: "flex", flexDirection: "row" }}>
                {session.result.components.map((section, index) => {
                  const difficulty = section.exerciseId
                    .split("_")
                    .slice(1)
                    .join("_");
                  const color = colors[difficulty];
                  const duration =
                    (section.duration / session.result.duration) * 100;
                  return (
                    <Tooltip
                      placement="top"
                      arrow
                      title={
                        <div>
                          <Typography>
                            {moment
                              .utc(
                                moment
                                  .duration({ seconds: section.duration })
                                  .as("milliseconds")
                              )
                              .format("mm[:]ss")}
                          </Typography>
                          <Typography>
                            {translate(
                              `resources.users.sessions.${camelCase(
                                section.exerciseId
                              )}`
                            )}
                          </Typography>
                        </div>
                      }
                    >
                      <Rectangle
                        key={index}
                        percentage={duration || 0}
                        color={color}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            </Paper>
          </>
        );
      })}
    </div>
  );
}

function IndoorSessionDisplayFull() {}

function SessionDisplayTeaser() {}

// Default component: SessionGenerator
export default function SessionGenerator() {
  // Load translation
  const translate = useTranslate();

  const apiList = [{ id: "openai_assistant", label: "openai_assistant" }];

  const modelList = [
    {
      id: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo",
      label: "meta-llama/Meta-Llama-3.1-8B-Instruct-Turbo",
    },
    {
      id: "mistralai/Mixtral-8x7B-Instruct-v0.1",
      label: "mistralai/Mixtral-8x7B-Instruct-v0.1",
    },
    {
      id: "mistralai/Mistral-7B-Instruct-v0.1",
      label: "mistralai/Mistral-7B-Instruct-v0.1",
    },
    {
      id: "togethercomputer/CodeLlama-34b-Instruct",
      label: "togethercomputer/CodeLlama-34b-Instruct",
    },
  ];

  const sessionModes = [
    { id: "userInfo", label: translate("resources.sessions.userInfo") },
    { id: "userId", label: translate("resources.sessions.userId") },
  ];

  const moduleList = [
    { id: "balance", label: translate(`resources.modules.balance`) },
    { id: "power", label: translate(`resources.modules.power`) },
    { id: "resistance", label: translate(`resources.modules.resistance`) },
    { id: "endurance", label: translate(`resources.modules.endurance`) },
  ];

  const moduleScoreList = range(1, 11).map((value) => ({
    id: value,
    label: `${value}/10`,
  }));

  const levelList = [
    { id: 0, label: translate(`resources.modules.low`) },
    { id: 1, label: translate(`resources.modules.medium`) },
    { id: 2, label: translate(`resources.modules.high`) },
    { id: 3, label: translate(`resources.modules.veryHigh`) },
  ];

  const genderList = [
    { id: "female", label: translate(`resources.users.female`) },
    { id: "male", label: translate(`resources.users.male`) },
  ];

  const equipementList = [
    { id: "pull_up_bar", label: translate(`resources.equipments.pull_up_bar`) },
    { id: "dumbbell", label: translate(`resources.equipments.dumbbell`) },
    { id: "stepups_box", label: translate(`resources.equipments.stepups_box`) },
    { id: "bike", label: translate(`resources.equipments.bike`) },
    { id: "kettlebell", label: translate(`resources.equipments.kettlebell`) },
    {
      id: "resistance_band",
      label: translate(`resources.equipments.resistance_band`),
    },
    {
      id: "ankle_wrist_weights",
      label: translate(`resources.equipments.ankle_wrist_weights`),
    },
    {
      id: "medicine_ball",
      label: translate(`resources.equipments.medicine_ball`),
    },
    { id: "dips_bar", label: translate(`resources.equipments.dips_bar`) },
    { id: "swiss_ball", label: translate(`resources.equipments.swiss_ball`) },
    { id: "tennis_ball", label: translate(`resources.equipments.tennis_ball`) },
    { id: "waff", label: translate(`resources.equipments.waff`) },
    {
      id: "water_bottle",
      label: translate(`resources.equipments.water_bottle`),
    },
    { id: "stool", label: translate(`resources.equipments.stool`) },
    { id: "chair", label: translate(`resources.equipments.chair`) },
    {
      id: "chair_with_armrests",
      label: translate(`resources.equipments.chair_with_armrests`),
    },
    { id: "table", label: translate(`resources.equipments.table`) },
    {
      id: "weighted_backpack",
      label: translate(`resources.equipments.weighted_backpack`),
    },
    { id: "pillar", label: translate(`resources.equipments.pillar`) },
    { id: "wall", label: translate(`resources.equipments.wall`) },
    { id: "towels", label: translate(`resources.equipments.towels`) },
    {
      id: "musculation_bench",
      label: translate(`resources.equipments.musculation_bench`),
    },
  ];

  const painAreaList = [
    {
      id: "right_shoulder",
      label: translate(`resources.painAreas.right_shoulder`),
    },
    {
      id: "left_shoulder",
      label: translate(`resources.painAreas.left_shoulder`),
    },
    { id: "right_elbow", label: translate(`resources.painAreas.right_elbow`) },
    { id: "left_elbow", label: translate(`resources.painAreas.left_elbow`) },
    { id: "right_wrist", label: translate(`resources.painAreas.right_wrist`) },
    { id: "left_wrist", label: translate(`resources.painAreas.left_wrist`) },
    { id: "upper_back", label: translate(`resources.painAreas.upper_back`) },
    { id: "lower_back", label: translate(`resources.painAreas.lower_back`) },
    { id: "right_hip", label: translate(`resources.painAreas.right_hip`) },
    { id: "left_hip", label: translate(`resources.painAreas.left_hip`) },
    { id: "right_knee", label: translate(`resources.painAreas.right_knee`) },
    { id: "left_knee", label: translate(`resources.painAreas.left_knee`) },
    { id: "right_ankle", label: translate(`resources.painAreas.right_ankle`) },
    { id: "left_ankle", label: translate(`resources.painAreas.left_ankle`) },
  ];

  const sectionList = [
    { id: "Warmup", label: translate(`resources.sessions.warmup`) },
    { id: "Exercises", label: translate(`resources.sessions.exercises`) },
    { id: "Stretching", label: translate(`resources.sessions.stretching`) },
  ];

  const typeList = [
    {
      id: "indoor",
      label: translate(`resources.sessions.indoorSession`),
      icon: <FitnessCenter />,
    },
    {
      id: "walk",
      label: translate(`resources.sessions.walkSession`),
      icon: <DirectionsWalk />,
    },
  ];

  const avatarList = [
    { id: "nina", label: "Nina" },
    { id: "alex", label: "Alex" },
  ];

  const languageList = [
    { id: "fr", label: "Français" },
    { id: "en", label: "Anglais" },
  ];

  const ExerciseImage = ({ path }) => {
    const baseUrl = `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/`;
    const imageUrl = `${baseUrl}${path}`;

    return (
      <img
        src={imageUrl}
        className="exercise-image"
        style={{ width: "100%", aspectRatio: "1/1" }}
      />
    );
  };

  // Component's states
  const [loading, setLoading] = useSafeSetState(false);
  const [numberOfLoadedSessions, setNumberOfLoadedSessions] = useSafeSetState(
    0
  );
  const [
    numberOfSessionsToGenerate,
    setNumberOfSessionsToGenerate,
  ] = useState<number>(1);
  const [sessions, setSessions] = useState<Array<any>>([]);
  const [programList, setProgramList] = useState<Array<any>>([]);
  const [programListError, setProgramListError] = useState<String>("");
  const [program, setProgram] = useState<any>({});
  const [programUsers, setProgramUsers] = useState<Array<any>>([]);
  const [exercisesVariantsList, setExercisesVariantsList] = useState<any>([]);
  const { programId: paramsProgramId } = useParams();
  const [aiGeneratedRecommendations, setAiGeneratedRecommendations] = useState<{
    codeStatus?: number;
    value?: string;
    error?: any;
  }>({});
  const [recommendationStatus, setRecommendationStatus] = useState("idle");
  const [isRecommendationsOpen, setRecommendationsOpen] = useState(false);
  const [isAISessionSettingsOpen, setAISessionSettingsOpen] = useState(false);

  // Prepare the form's default values
  const defaultValues = {
    debug: false,
    mode: "userInfo",
    type: "indoor",
    id: undefined,
    lang: "fr",
    userInfo: {
      sessionsCompleted: undefined,
      iterations: 1,
      program: undefined,
      programParams: {},
      module: "balance",
      moduleLevel: 2,
      modules: moduleList.reduce((modules, module) => {
        return (modules = { ...modules, [module.id]: undefined });
      }, {}),
      dateOfBirth: undefined,
      gender: "female",
      avatar: "nina",
      equipments: [],
      painAreas: [],
    },
    recommendation: {
      api: "",
      apiId: "",
      promptTemplate: "",
    },
    session: {
      model: "",
      programRules: "",
      promptTemplate: "",
    },
    randomizedGeneralParams: {
      module: false,
      moduleLevel: false,
      equipments: false,
      painAreas: false,
      gender: true,
      dateOfBirth: true,
      avatar: false,
      modules: false,
      programParams: false,
    },
    randomizedProgramParams: {},
    age: 50,
  } as FormTypes;

  const { control, handleSubmit, watch, register, reset, getValues } = useForm({
    defaultValues,
  });
  const formProgramId = watch("userInfo.program");
  const sessionMode = watch("mode");
  const sessionType = watch("type");
  const randomGeneralFields = watch("randomizedGeneralParams");
  const randomProgramFields = watch("randomizedProgramParams");

  // Fetch program list once
  useEffect(() => {
    async function fetchProgramList() {
      const { response, error } = await callAPI({
        apiName: currentConfig.apiName,
        apiPath: `/adminApi/program`,
        type: "get",
      });
      if (response) setProgramList(response.data || []);
      else if (error) setProgramListError(JSON.stringify(error));
      else setProgramListError("Unexpected error");
    }

    // Fetch programs if none is selected
    if (!paramsProgramId) fetchProgramList();
  }, []);

  // Fetch program data
  useEffect(() => {
    // The ID of the program to fetch
    const programToFetch = paramsProgramId || formProgramId;

    // Backup the form's current values for the reset
    const formValues = watch();

    const fetchProgram = async () => {
      const response = await getAsyncFetchData(
        "/adminApi/program/" + programToFetch
      );
      if (response.error) {
        console.error("Failed to fetch program");
        return;
      }
      const data = response.data;
      setProgram(data);

      // Reset the form's values using the program's data
      reset({
        ...omit(formValues, "id"),
        recommendation: data.aiParams?.recommendations,
        session: data.aiParams?.session,
        userInfo: {
          ...formValues.userInfo,
          program: programToFetch, // Overwrite program ID
        },
      });
    };

    // Fetch the users associated with the program
    async function fetchProgramUsers() {
      const fetchedData = await getAsyncFetchData(
        `/adminApi/user?program=${programToFetch}`
      );
      setProgramUsers(fetchedData.data || []);
    }

    if (programToFetch === undefined) {
      setProgram({});
      setProgramUsers([]);
      return;
    }

    fetchProgram();
    fetchProgramUsers();
  }, [paramsProgramId, formProgramId]);

  const convertParamValue = (
    value: any,
    type: string
  ): number | boolean | string => {
    switch (type) {
      case "number":
      case "timestamp":
        return Number(value);
      case "boolean":
        return Boolean(value);
      default:
        return String(value);
    }
  };

  const submit = async (values: FormTypes) => {
    setLoading(true);
    setNumberOfLoadedSessions(0);
    let generatedSessions: any[] = [];
    let params: Partial<FormTypes> & { userInfo: UserInfo } = omit(values, [
      "randomizedGeneralParams",
      "randomizedProgramParams",
      "mode",
      "age",
    ]);
    if (!params.userInfo) params.userInfo = {};
    params.userInfo.dateOfBirth = new Date(
      new Date().valueOf() -
        (typeof values.age === "string" ? parseInt(values.age) : values.age) *
          366 *
          86400000
    ).toISOString();

    // If userId mode
    if (values.mode == "userId") {
      params.userInfo = pick(params.userInfo, ["sessionsCompleted"]);
    }

    // If userInfo
    else {
      params = omit(params, ["id"]);

      // Convert the values from programParams to the corresponding type
      for (const questionnaire of program.questionnaires) {
        for (const question of questionnaire.questions) {
          const userParams =
            params.userInfo.programParams?.[questionnaire.id]?.[question.id];
          if (params.userInfo.programParams && userParams !== undefined) {
            params.userInfo.programParams[questionnaire.id][
              question.id
            ] = convertParamValue(userParams, question.type);
          }
        }
      }
    }

    // Load exercises database if not already available
    let allExerciseVariants;
    if (exercisesVariantsList.length == 0) {
      const module = await import("../graphql/queries");
      let allExercises: any = [];
      let nextToken = null;
      do {
        const response: any = await API.graphql(
          graphqlOperation(module[currentConfig.queries.listExercises], {
            limit: 1000,
            nextToken,
          })
        );

        if (
          typeof response.data !== "undefined" &&
          typeof response.data[currentConfig.queries.listExercises] !==
            "undefined" &&
          typeof response.data[currentConfig.queries.listExercises].items !==
            "undefined"
        ) {
          const data = response?.data[currentConfig.queries.listExercises];
          allExercises = [...allExercises, ...data.items];
          nextToken = data.nextToken;
        } else {
          // Error, while break
          nextToken = null;
        }
      } while (nextToken);

      allExerciseVariants = allExercises.reduce((object, item) => {
        item.variants.map((variant) => {
          object[variant.id] = item;
        });
        return object;
      }, {});

      setExercisesVariantsList(allExerciseVariants);
    } else {
      allExerciseVariants = exercisesVariantsList;
    }

    let sessionResult: any = null;

    // Omit indoor properties if session mode is "walk"
    if (values.type === "walk")
      params = omit(params, ["moduleLevel", "module"]) as FormTypes & {
        userInfo: UserInfo;
      };
    // Omit walk properties if session mode is "indoor" or undefined
    else params = omit(params, "walkModules");

    // Generate batch values
    for (let i = 0; i < numberOfSessionsToGenerate; i++) {
      if (values.mode === "userInfo") {
        // Randomize indoor parameters if fields are toggled
        if (values.type === "indoor") {
          params.userInfo.iterations = 1; //Math.floor(Math.random() * 6 + 1);

          // Randomize module
          if (values.randomizedGeneralParams.module)
            params.userInfo.module =
              moduleList[Math.floor(Math.random() * moduleList.length)].id;

          // Randomize module level
          if (values.randomizedGeneralParams.moduleLevel)
            params.userInfo.moduleLevel =
              levelList[Math.floor(Math.random() * levelList.length)].id;

          // Randomize gender
          if (values.randomizedGeneralParams.gender)
            params.userInfo.gender =
              genderList[Math.floor(Math.random() * genderList.length)].id;

          // Randomize date of birth
          if (values.randomizedGeneralParams.dateOfBirth) {
            let dateOfBirth = new Date(
              new Date().valueOf() -
                Math.floor(Math.random() * 62 + 18) * 366 * 86400000
            );
            params.userInfo.dateOfBirth = dateOfBirth.toISOString();
          }

          // Randomize equipment
          if (values.randomizedGeneralParams.equipments) {
            // Select up to 6 equipments (with 2 more chances to have 0 equipments)
            let numberOfEquipments = Math.floor(Math.random() * 8 - 1);
            numberOfEquipments =
              numberOfEquipments < 0 ? 0 : numberOfEquipments;
            params.userInfo.equipments = [];
            for (let j = 0; j < numberOfEquipments; j++) {
              params.userInfo.equipments.push(
                equipementList[
                  Math.floor(Math.random() * equipementList.length)
                ].id
              );
            }
            params.userInfo.equipments = params.userInfo.equipments.filter(
              (val, index, arr) => arr.indexOf(val) === index
            ); // Deduplicates equipments
          }

          // Randomize pain areas
          if (values.randomizedGeneralParams.painAreas) {
            // Select up to 4 pain areas (with 50% chances to have 0 pain area, 12.5% for 1 or 2 or 3 or 4 pain areas)
            let numberOfPainAreas = Math.floor(Math.random() * 8 - 3);
            numberOfPainAreas = numberOfPainAreas < 0 ? 0 : numberOfPainAreas;
            params.userInfo.painAreas = [];
            for (let j = 0; j < numberOfPainAreas; j++) {
              params.userInfo.painAreas.push(
                painAreaList[Math.floor(Math.random() * painAreaList.length)].id
              );
            }
            params.userInfo.painAreas = params.userInfo.painAreas.filter(
              (val, index, arr) => arr.indexOf(val) === index
            ); // Deduplicates pain areas
          }
        }

        // Random values for programParams enum, enumMultiple and for number fields with min and max
        for (let questionnaireId in values.randomizedProgramParams) {
          for (let randomStatusKey in values.randomizedProgramParams[
            questionnaireId
          ]) {
            if (
              values.randomizedProgramParams[questionnaireId][
                randomStatusKey
              ] === true
            ) {
              // Random value asked

              // Find the right question
              let question: any = null;
              for (let i in program.questionnaires) {
                if (program.questionnaires[i].id === questionnaireId) {
                  const q = program.questionnaires[i].questions.find(
                    (q) => q.id === randomStatusKey
                  );
                  if (q) {
                    question = q;
                  }
                }
              }

              // Find options
              if (
                question &&
                typeof params.userInfo.programParams == "object"
              ) {
                if (question.enum) {
                  // Select one option among the possible options
                  params.userInfo.programParams = {
                    ...params.userInfo.programParams,
                    [questionnaireId]: {
                      ...params.userInfo.programParams[questionnaireId],
                      [randomStatusKey]:
                        question.enum[
                          Math.floor(Math.random() * question.enum.length)
                        ],
                    },
                  };
                } else if (question.enumMultiple) {
                  // Select one or more options among the possible options
                  params.userInfo.programParams = {
                    ...params.userInfo.programParams,
                    [questionnaireId]: {
                      ...params.userInfo.programParams[questionnaireId],
                      [randomStatusKey]: question.enumMultiple
                        .sort(() => Math.random() - 0.5) // randomize
                        .slice(
                          0,
                          Math.floor(
                            Math.random() * (question.enumMultiple.length + 1)
                          )
                        ),
                    },
                  };
                } else if (
                  question.type == "number" &&
                  question.min != null &&
                  question.max != null
                ) {
                  let randomValue;
                  if (question.cast == "float") {
                    // "float"
                    randomValue =
                      Math.floor(
                        (Math.random() * (question.max - question.min) +
                          question.min) *
                          100
                      ) / 100;
                  } else {
                    // "integer"
                    randomValue =
                      Math.floor(
                        Math.random() * (question.max - question.min + 1)
                      ) + question.min;
                  }
                  params.userInfo.programParams = {
                    ...params.userInfo.programParams,
                    [questionnaireId]: {
                      ...params.userInfo.programParams[questionnaireId],
                      [randomStatusKey]: randomValue,
                    },
                  };
                }
              }
            }
          }
        }
      }

      const sessionGeneratorInput = {
        ...params,
        userInfo: {
          ...params.userInfo,
          programParams: JSON.stringify(params.userInfo.programParams),
        },
      };

      // Send graphql request with serialization of userInfo.programParams
      try {
        sessionResult = await API.graphql(
          graphqlOperation(generateSession, {
            input: sessionGeneratorInput,
          })
        );
      } catch (err) {
        let jsonErr: any = err;

        generatedSessions.push({
          params: Object.assign({}, params),
          result: {},
          status: CheckResultStatus.Error,
          errorMessages: [JSON.stringify(jsonErr)],
        });
        continue;
      }

      if (
        sessionResult &&
        typeof sessionResult.data !== "undefined" &&
        sessionResult.data != null &&
        typeof sessionResult.data.generateSession == "object"
      ) {
        // Unserialize programParams
        let session = sessionResult.data.generateSession;
        if (typeof session.programParams == "string") {
          session.programParams = JSON.parse(session.programParams);
        }
        generatedSessions.push({
          params: { ...params.userInfo },
          result: session,
          status: CheckResultStatus.Success,
          errorMessages: {},
        });
      } else {
        generatedSessions.push({
          params: { ...params.userInfo },
          result: {},
          status: CheckResultStatus.Error,
          errorMessages: ["No data or inconsistant data from server."],
        });
      }

      setNumberOfLoadedSessions(i + 1);
    }

    setSessions(generatedSessions);
    setLoading(false);
  };

  // SessionGenerator return statement
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Title title={translate(`resources.sessions.generateSessions`)} />
      <Grid container sx={{ pt: 2, pr: 2, pl: 2 }}>
        <Grid
          item
          xs={sessions.length ? 4 : 12}
          sx={{
            maxHeight: "calc(100vh - 64px)",
            overflowY: "scroll",
            transition: "0.4s",
          }}
        >
          <Paper sx={{ padding: 2, backgroundColor: "#F6F6F6" }}>
            <Typography variant="h5">
              {translate("resources.sessions.settings.title")}
            </Typography>
            <form onSubmit={handleSubmit((data) => submit(data))} noValidate>
              {/* Session mode selection */}
              <RadioButtonGroup
                name="mode"
                control={control}
                options={sessionModes}
                required
                row
              />

              {/* Session settings */}
              <Accordion defaultExpanded key="session">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="sessionSettings"
                  id="sessionSettings"
                >
                  <Typography>
                    {translate("resources.sessions.settings.session")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}
                >
                  <Grid container spacing={0}>
                    <Grid item xs={12} lg={5}>
                      {/* Session type selection */}
                      <TypeButtonGroup
                        {...{ control, register, translate, typeList, watch }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={7}>
                      {/* Number of sessions slider */}
                      <div>
                        <Typography>
                          {`Batch : ${numberOfSessionsToGenerate} ${translate(
                            "resources.sessions.name"
                          ).slice(
                            0,
                            numberOfSessionsToGenerate === 1 ? -1 : undefined
                          )}`}
                        </Typography>
                        <Slider
                          defaultValue={numberOfSessionsToGenerate}
                          onChangeCommitted={(event, value) =>
                            setNumberOfSessionsToGenerate(Number(value))
                          }
                          step={1}
                          min={1}
                          max={100}
                          valueLabelDisplay="auto"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>

              {/* Pathology settings */}
              {sessionMode === "userInfo" &&
                program.questionnaires &&
                program.questionnaires.length > 0 && (
                  <Accordion defaultExpanded key="pathology">
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="pathologySettings"
                      id="pathologySettings"
                    >
                      <Typography>
                        {translate("resources.sessions.userPathologyParams")}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 2,
                      }}
                    >
                      {/* List program's questionnaires */}
                      {program.questionnaires &&
                        program.questionnaires.map((questionnaire, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                rowGap: 8,
                              }}
                            >
                              <Typography sx={{ textDecoration: "underline" }}>
                                {translate(
                                  `program.questionnaires.${questionnaire.id}`,
                                  { _: questionnaire.id }
                                )}
                              </Typography>

                              {/* List the questionnaire's questions */}
                              {questionnaire.questions.map(
                                (question, qIndex) => {
                                  if (question.enumMultiple) {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          columnGap: 8,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <MultiSelectElement
                                          key={qIndex}
                                          control={control}
                                          name={`userInfo.programParams.${questionnaire.id}.${question.id}`}
                                          label={translate(
                                            `program.params.${question.id}`,
                                            { _: question.id }
                                          )}
                                          options={question.enumMultiple.map(
                                            (value, qId) => ({
                                              id: value,
                                              label: translate(
                                                `program.params.${question.id}__${value}`,
                                                { _: String(value) }
                                              ),
                                            })
                                          )}
                                          size="small"
                                          required={question.required || false}
                                          disabled={
                                            loading ||
                                            (randomProgramFields[
                                              questionnaire.id
                                            ] &&
                                              randomProgramFields[
                                                questionnaire.id
                                              ][question.id])
                                          }
                                          fullWidth
                                        />
                                        <div>
                                          <InputLabel
                                            shrink
                                            sx={{ position: "absolute" }}
                                          >
                                            {translate(
                                              "resources.sessions.randomField"
                                            )}
                                          </InputLabel>
                                          <SwitchElement
                                            control={control}
                                            name={`randomizedProgramParams.${questionnaire.id}.${question.id}`}
                                            label=""
                                            disabled={loading}
                                            sx={{
                                              margin: 0,
                                              position: "relative",
                                              top: "8px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else if (question.enum) {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          columnGap: 8,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <SelectElement
                                          key={qIndex}
                                          control={control}
                                          name={`userInfo.programParams.${questionnaire.id}.${question.id}`}
                                          label={translate(
                                            `program.params.${question.id}`,
                                            { _: question.id }
                                          )}
                                          options={question.enum.map(
                                            (value) => ({
                                              id: value,
                                              label: translate(
                                                `program.params.${question.id}__${value}`,
                                                { _: String(value) }
                                              ),
                                            })
                                          )}
                                          size="small"
                                          disabled={
                                            loading ||
                                            (randomProgramFields[
                                              questionnaire.id
                                            ] &&
                                              randomProgramFields[
                                                questionnaire.id
                                              ][question.id])
                                          }
                                          required={question.required || false}
                                          fullWidth
                                        />
                                        <div>
                                          <InputLabel
                                            shrink
                                            sx={{ position: "absolute" }}
                                          >
                                            {translate(
                                              "resources.sessions.randomField"
                                            )}
                                          </InputLabel>
                                          <SwitchElement
                                            control={control}
                                            name={`randomizedProgramParams.${questionnaire.id}.${question.id}`}
                                            label=""
                                            disabled={loading}
                                            sx={{
                                              margin: 0,
                                              position: "relative",
                                              top: "8px",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  } else if (question.type == "timestamp") {
                                    return (
                                      <Controller
                                        name={`userInfo.programParams.${questionnaire.id}.${question.id}`}
                                        control={control}
                                        render={({ field }) => (
                                          <DatePicker
                                            value={
                                              field.value &&
                                              typeof field.value == "number"
                                                ? new DateObject(field.value)
                                                : ""
                                            }
                                            format="DD/MM/YYYY HH:mm"
                                            weekStartDayIndex={1}
                                            plugins={[
                                              <TimePicker position="bottom" />,
                                            ]}
                                            onChange={(date) =>
                                              field.onChange(
                                                date
                                                  ? date.toDate().getTime()
                                                  : null
                                              )
                                            }
                                            required={
                                              question.required || false
                                            }
                                            render={(value, openCalendar) => {
                                              return (
                                                <TextField
                                                  value={value}
                                                  onClick={openCalendar}
                                                  label={translate(
                                                    `program.params.${question.id}`,
                                                    { _: question.id }
                                                  )}
                                                  fullWidth
                                                  size={"small"}
                                                  required={
                                                    question.required || false
                                                  }
                                                />
                                              );
                                            }}
                                          />
                                        )}
                                      />
                                    );
                                  }
                                  // else (no multiple values nor timestamp): text or number
                                  else {
                                    return (
                                      <div
                                        style={{
                                          display: "flex",
                                          columnGap: 8,
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <TextFieldElement
                                          key={qIndex}
                                          control={control}
                                          name={`userInfo.programParams.${questionnaire.id}.${question.id}`}
                                          type={
                                            question.type === "number"
                                              ? "number"
                                              : "string"
                                          }
                                          label={translate(
                                            `program.params.${question.id}`,
                                            { _: question.id }
                                          )}
                                          size="small"
                                          disabled={
                                            loading ||
                                            (randomProgramFields[
                                              questionnaire.id
                                            ] &&
                                              randomProgramFields[
                                                questionnaire.id
                                              ][question.id])
                                          }
                                          required={question.required || false}
                                          InputProps={
                                            question.type === "number"
                                              ? {
                                                  inputProps: {
                                                    min: question.min,
                                                    max: question.max,
                                                  },
                                                }
                                              : {}
                                          }
                                          fullWidth
                                        />
                                        {question.type === "number" &&
                                          question.min != null &&
                                          question.max != null && ( // Able to randomise
                                            <div>
                                              <InputLabel
                                                shrink
                                                sx={{ position: "absolute" }}
                                              >
                                                {translate(
                                                  "resources.sessions.randomField"
                                                )}
                                              </InputLabel>
                                              <SwitchElement
                                                control={control}
                                                name={`randomizedProgramParams.${questionnaire.id}.${question.id}`}
                                                label=""
                                                disabled={loading}
                                                sx={{
                                                  margin: 0,
                                                  position: "relative",
                                                  top: "8px",
                                                }}
                                              />
                                            </div>
                                          )}
                                      </div>
                                    );
                                  }
                                }
                              )}
                            </div>
                          );
                        })}
                    </AccordionDetails>
                  </Accordion>
                )}

              {/* Profile settings */}
              <Accordion defaultExpanded key="profile">
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="profileSettings"
                  id="profileSettings"
                >
                  <Typography>
                    {translate("resources.sessions.userGeneralParams")}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}
                >
                  {/* User ID */}
                  {sessionMode === "userId" && (
                    <Controller
                      control={control}
                      name="id"
                      render={({ field }) => (
                        <AutocompleteElement
                          control={control}
                          autocompleteProps={{
                            onChange: (event, value) => {
                              field.onChange(value?.id || "");
                            },
                            componentsProps: {
                              popper: { style: { width: "fit-content" } },
                            },
                            size: "small",
                          }}
                          name="id"
                          label={translate("resources.users.id")}
                          options={programUsers.map((userData) => ({
                            id: userData.id,
                            label:
                              String(
                                userData.email ||
                                  userData.firstName ||
                                  userData.phoneNumber
                              ) +
                              " : " +
                              userData.id,
                          }))}
                          required
                        />
                      )}
                    />
                  )}

                  {/* Session number */}
                  <TextFieldElement
                    control={control}
                    name="userInfo.sessionsCompleted"
                    type="number"
                    label={translate("resources.sessions.sessionNumber")}
                    inputProps={{ min: 1 }}
                    disabled={loading}
                    size="small"
                    fullWidth
                  />

                  {/* Iterations */}
                  {sessionMode === "userInfo" && (
                    <>
                      <TextField
                        type="number"
                        {...register("userInfo.iterations")}
                        label={translate("resources.sessions.iterations")}
                        inputProps={{ min: 1 }}
                        disabled={loading}
                        size="small"
                        required
                        fullWidth
                      />
                      {sessionType === "indoor" ? (
                        <>
                          {/* Module name */}
                          <div
                            style={{
                              display: "flex",
                              columnGap: 8,
                              justifyContent: "space-between",
                            }}
                          >
                            <SelectElement
                              control={control}
                              name={`userInfo.module`}
                              label={translate("resources.sessions.module")}
                              options={moduleList}
                              disabled={loading || randomGeneralFields.module}
                              required
                              fullWidth
                              size="small"
                            />
                            <div>
                              <InputLabel shrink sx={{ position: "absolute" }}>
                                {translate("resources.sessions.randomField")}
                              </InputLabel>
                              <SwitchElement
                                control={control}
                                name="randomizedGeneralParams.module"
                                label=""
                                disabled={loading}
                                sx={{
                                  margin: 0,
                                  position: "relative",
                                  top: "8px",
                                }}
                              />
                            </div>
                          </div>

                          {/* Module difficulty level */}
                          <div
                            style={{
                              display: "flex",
                              columnGap: 8,
                              justifyContent: "space-between",
                            }}
                          >
                            <SelectElement
                              control={control}
                              name={`userInfo.moduleLevel`}
                              label={translate("resources.sessions.level")}
                              options={levelList}
                              required
                              fullWidth
                              size="small"
                              disabled={
                                loading || randomGeneralFields.moduleLevel
                              }
                            />
                            <div>
                              <InputLabel shrink sx={{ position: "absolute" }}>
                                {translate("resources.sessions.randomField")}
                              </InputLabel>
                              <SwitchElement
                                control={control}
                                name="randomizedGeneralParams.moduleLevel"
                                label=""
                                disabled={loading}
                                sx={{
                                  margin: 0,
                                  position: "relative",
                                  top: "8px",
                                }}
                              />
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {/* Walk modules */}
                          {moduleList.map((module, index) => (
                            <SelectElement
                              key={index}
                              control={control}
                              name={`userInfo.modules.${module.id}`}
                              label={translate(
                                `resources.sessions.moduleScore`
                              ).replace(
                                "%s",
                                translate(`resources.modules.${module.id}`)
                              )}
                              options={moduleScoreList}
                              size="small"
                              required
                              fullWidth
                              disabled={loading}
                            />
                          ))}
                        </>
                      )}

                      {/* Equipments */}
                      <div
                        style={{
                          display: "flex",
                          columnGap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <MultiSelectElement
                          control={control}
                          name={`userInfo.equipments`}
                          label={translate("resources.sessions.equipments")}
                          options={equipementList}
                          fullWidth
                          size="small"
                          disabled={loading || randomGeneralFields.equipments}
                        />
                        <div>
                          <InputLabel shrink sx={{ position: "absolute" }}>
                            {translate("resources.sessions.randomField")}
                          </InputLabel>
                          <SwitchElement
                            control={control}
                            name="randomizedGeneralParams.equipments"
                            label=""
                            disabled={loading}
                            sx={{ margin: 0, position: "relative", top: "8px" }}
                          />
                        </div>
                      </div>

                      {/* Pain areas */}
                      <div
                        style={{
                          display: "flex",
                          columnGap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <MultiSelectElement
                          control={control}
                          name={`userInfo.painAreas`}
                          label={translate("resources.sessions.painAreas")}
                          options={painAreaList}
                          fullWidth
                          size="small"
                          disabled={loading || randomGeneralFields.painAreas}
                        />
                        <div>
                          <InputLabel shrink sx={{ position: "absolute" }}>
                            {translate("resources.sessions.randomField")}
                          </InputLabel>
                          <SwitchElement
                            control={control}
                            name="randomizedGeneralParams.painAreas"
                            label=""
                            disabled={loading}
                            sx={{ margin: 0, position: "relative", top: "8px" }}
                          />
                        </div>
                      </div>

                      {/* Gender */}
                      <div
                        style={{
                          display: "flex",
                          columnGap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <RadioButtonGroup
                          name="userInfo.gender"
                          control={control}
                          options={genderList}
                          disabled={loading || randomGeneralFields.gender}
                          required
                          row
                        />
                        <div>
                          <InputLabel shrink sx={{ position: "absolute" }}>
                            {translate("resources.sessions.randomField")}
                          </InputLabel>
                          <SwitchElement
                            control={control}
                            name="randomizedGeneralParams.gender"
                            label=""
                            disabled={loading}
                            sx={{ margin: 0, position: "relative", top: "8px" }}
                          />
                        </div>
                      </div>

                      {/* Date of birth / age */}
                      <div
                        style={{
                          display: "flex",
                          columnGap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          {...register("age")}
                          label={translate("resources.users.age")}
                          type="number"
                          inputProps={{ min: 0, max: 150 }}
                          disabled={loading || randomGeneralFields.dateOfBirth}
                          required
                          fullWidth
                          size="small"
                        />
                        <div>
                          <InputLabel shrink sx={{ position: "absolute" }}>
                            {translate("resources.sessions.randomField")}
                          </InputLabel>
                          <SwitchElement
                            control={control}
                            name="randomizedGeneralParams.dateOfBirth"
                            label=""
                            disabled={loading}
                            sx={{ margin: 0, position: "relative", top: "8px" }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </AccordionDetails>
              </Accordion>

              {/* API settings */}
              <Dialog open={isRecommendationsOpen} maxWidth="lg" fullWidth>
                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 16,
                      padding: 8,
                    }}
                  >
                    <Typography variant="h5" color="primary">
                      {translate(
                        "resources.sessions.generation.recommendationsSettings"
                      )}
                    </Typography>
                    <SelectElement
                      control={control}
                      name={`recommendation.api`}
                      label={translate("resources.sessions.api.name")}
                      options={apiList}
                      required
                      fullWidth
                    />
                    <TextField
                      {...register("recommendation.apiId")}
                      label={translate("resources.sessions.api.id")}
                      disabled={recommendationStatus === "loading"}
                      required
                      fullWidth
                    />
                    <TextField
                      {...register("recommendation.promptTemplate")}
                      label={translate("resources.sessions.api.prompt")}
                      disabled={recommendationStatus === "loading"}
                      fullWidth
                      required
                      multiline
                    />
                  </div>
                  <DialogActions>
                    <RecommendationsGenerationButton
                      {...{
                        dialogStateSetter: setRecommendationsOpen,
                        programId: paramsProgramId
                          ? paramsProgramId
                          : formProgramId,
                        translate,
                        watch,
                        resultSetter: setAiGeneratedRecommendations,
                        status: recommendationStatus,
                        statusSetter: setRecommendationStatus,
                      }}
                    />
                    <Button
                      variant="outlined"
                      onClick={() => setRecommendationsOpen(false)}
                    >
                      {translate("resources.misc.close")}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              {/* AI session settings */}
              <Dialog open={isAISessionSettingsOpen} maxWidth="lg" fullWidth>
                <DialogContent>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 16,
                      padding: "0 16px",
                    }}
                  >
                    <Typography variant="h5" color="primary">
                      {translate(
                        "resources.sessions.generation.sessionSettings"
                      )}
                    </Typography>
                    <SelectElement
                      control={control}
                      name={`session.model`}
                      label={translate("resources.sessions.api.aiModel")}
                      options={modelList}
                      required
                      fullWidth
                    />
                    <TextField
                      {...register("session.programRules")}
                      label={translate("resources.sessions.api.programRules")}
                      disabled={recommendationStatus === "loading"}
                      fullWidth
                      required
                      multiline
                    />
                    <TextField
                      {...register("session.promptTemplate")}
                      label={translate("resources.sessions.api.prompt")}
                      disabled={recommendationStatus === "loading"}
                      fullWidth
                      required
                      multiline
                    />
                  </div>
                  <DialogActions>
                    <Button
                      variant="contained"
                      onClick={() => setAISessionSettingsOpen(false)}
                    >
                      {translate("resources.misc.close")}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Dialog>

              {/* Generation buttons */}
              <div>
                {program.generateSessionMode === "aiWithRecommendations" && (
                  <>
                    {/* Recommendation settings button */}
                    <TooltipButton
                      disabled={
                        program.generateSessionMode !== "aiWithRecommendations"
                      }
                      onClickAction={() => setRecommendationsOpen(true)}
                      disabledTitle={translate(
                        "resources.sessions.generation.unsupportedRecommendationGeneration"
                      )}
                      icon={<SettingsSuggest />}
                      text={translate(
                        "resources.sessions.generation.recommendationsSettings"
                      )}
                    />

                    {/* AI generation settings button */}
                    <TooltipButton
                      disabled={
                        program.generateSessionMode !== "aiWithRecommendations"
                      }
                      onClickAction={() => setAISessionSettingsOpen(true)}
                      icon={<Tune />}
                      text={translate(
                        "resources.sessions.generation.sessionSettings"
                      )}
                    />
                  </>
                )}

                {/* Generate button */}
                <Tooltip title={translate("resources.sessions.generate")}>
                  <Fab
                    color="primary"
                    aria-label={translate("resources.sessions.generate")}
                    disabled={loading || !formProgramId}
                    type="submit"
                    sx={{
                      position: "fixed",
                      bottom: 16,
                      right: 16,
                      opacity: 1,
                    }}
                  >
                    {!!numberOfLoadedSessions &&
                    loading &&
                    numberOfLoadedSessions > 1 ? (
                      <>
                        {Math.floor(
                          (numberOfLoadedSessions /
                            numberOfSessionsToGenerate) *
                            100
                        )}
                        %
                      </>
                    ) : (
                      <PlayArrow />
                    )}
                  </Fab>
                </Tooltip>
              </div>
            </form>
          </Paper>
        </Grid>

        <Grid
          item
          xs={sessions.length ? 8 : 0}
          sx={{
            maxHeight: "calc(100vh - 64px)",
            overflowY: "auto",
            padding: "0 16px",
            transition: "0.4",
          }}
        >
          {sessions.length > 0 && (
            <>
              {!paramsProgramId && (
                <Box sx={{ pt: 2, pb: 2 }}>
                  <SelectElement
                    control={control}
                    name="userInfo.program"
                    label={translate("pos.menu.program")}
                    options={programList.map((program) => ({
                      id: program.id || "undefined",
                      label: program.displayName || "undefined",
                    }))}
                    size="small"
                    fullWidth
                    sx={{ maxWidth: "fit-content", minWidth: "144px" }}
                  />
                </Box>
              )}
              {programListError && (
                <Alert severity="error">{programListError}</Alert>
              )}

              {/* Generated recommendations */}
              {recommendationStatus !== "idle" && (
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="recommendationsAccordion"
                    sx={{ display: "flex" }}
                  >
                    <Typography variant="h4" textAlign="center" width="100%">
                      {translate("resources.sessions.recommendations")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* Progress bar */}
                    {recommendationStatus === "loading" && (
                      <>
                        <Typography variant="h5">
                          {translate(
                            "resources.sessions.generatingRecommendations"
                          )}
                        </Typography>
                        <LinearProgress />
                      </>
                    )}
                    {aiGeneratedRecommendations.value && (
                      <ReactMarkdown>
                        {aiGeneratedRecommendations.value}
                      </ReactMarkdown>
                    )}
                    {aiGeneratedRecommendations.error && (
                      <Alert severity="warning">
                        {JSON.stringify(aiGeneratedRecommendations.error)}
                      </Alert>
                    )}
                  </AccordionDetails>
                </Accordion>
              )}

              {/* Progress bar when generating sessions */}
              {loading && (
                <>
                  <Typography variant="h5">
                    {translate("resources.sessions.generatingSession")}
                  </Typography>
                  <LinearProgress />
                </>
              )}

              {!loading && (
                <>
                  {/* Session generation summary */}
                  <BatchSessionsSummary {...{ sessions, translate }} />

                  {/* Walk session content */}
                  {sessions.length > 0 &&
                    sessions[0].result.type === "walk" && (
                      <WalkSessionDisplay sessions={sessions} />
                    )}

                  {/* Single indoor session content */}
                  {sessions.length === 1 && sessions[0].result.type !== "walk" && (
                    <Grid
                      container
                      sx={{ marginTop: 4, marginBottom: 4 }}
                      maxWidth="80vw"
                    >
                      <Grid item xs={11}>
                        <Paper
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            columnGap: 2,
                            padding: 2,
                            backgroundColor: "#f1eee6",
                            border: "none",
                            overflowX: "auto",
                          }}
                          elevation={1}
                        >
                          <SessionHeader
                            {...{ session: sessions[0], translate }}
                          />

                          {/* List sections */}
                          {sessions[0].result.components ? (
                            sectionList.map((section, i) => {
                              const sectionComponents = sessions[0].result.components.filter(
                                (component) => component.section === section.id
                              );
                              if (sectionComponents.length == 0) return; // Do not display section if it's empty
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography fontWeight="bold" marginTop={4}>
                                    {section.label} ({sectionComponents.length})
                                  </Typography>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    {/* Group components by sections */}
                                    {sectionComponents.map(
                                      (
                                        component,
                                        componentIndex,
                                        componentList
                                      ) => (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                              alignItems: "center",
                                              padding: 16,
                                              rowGap: 8,
                                            }}
                                          >
                                            {/* Pause card */}
                                            {exercisesVariantsList[
                                              component.exerciseVariantId
                                            ] &&
                                            exercisesVariantsList[
                                              component.exerciseVariantId
                                            ].variants.length == 2 &&
                                            componentIndex > 0 &&
                                            componentList[componentIndex - 1]
                                              .exerciseVariantId ==
                                              exercisesVariantsList[
                                                component.exerciseVariantId
                                              ].variants[0].id ? (
                                              <>
                                                <Paper
                                                  sx={{
                                                    backgroundColor: "#FF9914",
                                                    border: "none",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 1,
                                                  }}
                                                  elevation={3}
                                                >
                                                  <CompareArrows />
                                                  <Typography
                                                    fontWeight="bold"
                                                    textAlign="center"
                                                  >{`${component.pause}s`}</Typography>
                                                </Paper>
                                                <Typography
                                                  fontWeight="bold"
                                                  variant="body2"
                                                  color="black"
                                                  textAlign="center"
                                                >
                                                  {translate(
                                                    "resources.sessions.switchSide"
                                                  )}
                                                </Typography>
                                              </>
                                            ) : (
                                              <Paper
                                                sx={{
                                                  backgroundColor: "#3E6990",
                                                  border: "none",
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  padding: 1,
                                                }}
                                                elevation={3}
                                              >
                                                <Timer htmlColor="white" />
                                                <Typography
                                                  fontWeight="bold"
                                                  color="white"
                                                  textAlign="center"
                                                >{`${component.pause}s`}</Typography>
                                              </Paper>
                                            )}
                                          </div>

                                          {/* Exercise card */}
                                          <Paper
                                            elevation={2}
                                            square
                                            sx={{
                                              display: "flex",
                                              flexDirection: "column",
                                              padding: 1,
                                              minWidth: "180px",
                                              maxWidth: "180px",
                                              rowGap: 1,
                                            }}
                                          >
                                            <ExerciseImage
                                              path={`exercisesGif/nina/${component.exerciseVariantId}.gif`}
                                            />
                                            <Typography variant="body2">
                                              {component.name}
                                            </Typography>
                                            <div
                                              style={{
                                                display: "flex",
                                                columnGap: 8,
                                              }}
                                            >
                                              <Typography
                                                fontWeight="bold"
                                                variant="body2"
                                              >
                                                {translate(
                                                  "resources.sessions.duration"
                                                )}
                                                :{" "}
                                              </Typography>
                                              <Typography
                                                fontWeight="bold"
                                                color="primary"
                                                variant="body2"
                                              >
                                                {moment
                                                  .utc(
                                                    moment
                                                      .duration({
                                                        seconds:
                                                          component.duration,
                                                      })
                                                      .as("milliseconds")
                                                  )
                                                  .format(
                                                    component.duration > 59
                                                      ? "mm[:]ss"
                                                      : "s[s]"
                                                  )}
                                              </Typography>
                                            </div>
                                          </Paper>
                                        </>
                                      )
                                    )}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <Typography fontWeight="bold" color="primary">
                              {translate("resources.misc.noData")}
                            </Typography>
                          )}
                        </Paper>
                      </Grid>

                      {/* Session buttons */}
                      <Grid item xs={1}>
                        {sessions[0].result && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              rowGap: 8,
                            }}
                          >
                            <RawSessionOutputDialog
                              session={sessions[0].result}
                            />
                            <ExportVideoJSON session={sessions[0].result} />
                            <Tooltip
                              title={translate("resources.sessions.clearAll")}
                              arrow
                              placement="left"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ minWidth: 0, padding: 1 }}
                                onClick={() => {
                                  setSessions([]);
                                }}
                              >
                                <DeleteForever />
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                      </Grid>

                      {sessions[0].status == CheckResultStatus.Error &&
                      sessions[0].errorMessages.length ? (
                        <Alert severity="error">
                          <AlertTitle>
                            {translate("resources.misc.error", {
                              smart_count: sessions[0].errorMessages.length,
                            })}
                          </AlertTitle>
                          <ul>
                            {sessions[0].errorMessages.map((item, index) => {
                              return <li key={index}>{item}</li>;
                            })}
                          </ul>
                        </Alert>
                      ) : null}
                    </Grid>
                  )}

                  {/* Multiple indoor sessions content */}
                  {sessions.length > 1 && sessions[0].result.type !== "walk" && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item xs={11}></Grid>
                        <Grid item xs={1}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              rowGap: 8,
                            }}
                          >
                            <Tooltip
                              title={translate("resources.sessions.clearAll")}
                              arrow
                              placement="left"
                            >
                              <Button
                                variant="contained"
                                color="primary"
                                sx={{ minWidth: 0, padding: 1 }}
                                onClick={() => {
                                  setSessions([]);
                                }}
                              >
                                <DeleteForever />
                              </Button>
                            </Tooltip>
                          </div>
                        </Grid>
                      </Grid>
                      {sessions.map((session, sessionIndex) => {
                        return (
                          <div style={{ borderBottom: 1 }} key={sessionIndex}>
                            <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                              <Grid item xs={11}>
                                <Box
                                  sx={{
                                    position: "relative",
                                    display: "inline-block",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      left: "50%",
                                      transform: "translate(-50%, -50%)", // Centering
                                      backgroundColor: "white",
                                      px: 2,
                                    }}
                                  >
                                    {translate("resources.sessions.session")}{" "}
                                    {sessionIndex + 1}
                                  </Typography>
                                  <Paper
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      columnGap: 2,
                                      padding: 2,
                                      backgroundColor: "#f1eee6",
                                      border: "none",
                                      overflowX: "scroll",
                                    }}
                                    elevation={1}
                                  >
                                    <Typography
                                      variant="h5"
                                      fontWeight="bold"
                                      color="primary"
                                    >
                                      {session.result.name}
                                    </Typography>
                                    <Grid container>
                                      {/* Session informations */}
                                      <Grid item xs={5} md={4}>
                                        <Typography fontWeight="bold">
                                          {translate(
                                            "resources.sessions.inputData"
                                          )}
                                        </Typography>
                                        {session.params.gender && (
                                          <div
                                            style={{
                                              display: "flex",
                                              columnGap: 8,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {translate(
                                                "resources.users.gender"
                                              )}
                                              :{" "}
                                            </Typography>
                                            <Typography
                                              fontWeight="bold"
                                              color="primary"
                                            >
                                              {translate(
                                                `resources.users.${session.params.gender}`
                                              )}
                                            </Typography>
                                          </div>
                                        )}
                                        {session.params.dateOfBirth && (
                                          <div
                                            style={{
                                              display: "flex",
                                              columnGap: 8,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {translate("resources.users.age")}
                                              :{" "}
                                            </Typography>
                                            <Typography
                                              fontWeight="bold"
                                              color="primary"
                                            >
                                              {moment().diff(
                                                session.params.dateOfBirth,
                                                "years"
                                              )}
                                            </Typography>
                                          </div>
                                        )}
                                        {session.params.module && (
                                          <div
                                            style={{
                                              display: "flex",
                                              columnGap: 8,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {translate(
                                                "resources.sessions.module"
                                              )}
                                              :{" "}
                                            </Typography>
                                            <Typography
                                              fontWeight="bold"
                                              color="primary"
                                            >
                                              {translate(
                                                `resources.modules.${session.params.module}`
                                              )}
                                            </Typography>
                                          </div>
                                        )}
                                        {session.params.moduleLevel !=
                                          undefined && (
                                          <div
                                            style={{
                                              display: "flex",
                                              columnGap: 8,
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                textDecoration: "underline",
                                              }}
                                            >
                                              {translate(
                                                "resources.sessions.level"
                                              )}
                                              :{" "}
                                            </Typography>
                                            <Typography
                                              fontWeight="bold"
                                              color="primary"
                                            >
                                              {session.params.moduleLevel}
                                            </Typography>
                                          </div>
                                        )}
                                        {session.params.equipments &&
                                          session.params.equipments.length >
                                            0 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {translate(
                                                  "resources.sessions.equipments"
                                                )}
                                                :{" "}
                                              </Typography>
                                              <List>
                                                {session.params.equipments.map(
                                                  (equipment, equipId) => (
                                                    <ListItem
                                                      key={equipId}
                                                      sx={{
                                                        padding: 0,
                                                        paddingLeft: 4,
                                                      }}
                                                    >
                                                      <ListItemIcon
                                                        sx={{ minWidth: 16 }}
                                                      >
                                                        <Circle
                                                          fontSize="small"
                                                          color="primary"
                                                          sx={{
                                                            width: 8,
                                                            height: 8,
                                                          }}
                                                        />
                                                      </ListItemIcon>
                                                      <Typography
                                                        fontWeight="bold"
                                                        color="primary"
                                                      >
                                                        {translate(
                                                          `resources.equipments.${equipment}`
                                                        )}
                                                      </Typography>
                                                    </ListItem>
                                                  )
                                                )}
                                              </List>
                                            </div>
                                          )}
                                        {session.params.painAreas &&
                                          session.params.painAreas.length >
                                            0 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {translate(
                                                  "resources.sessions.painAreas"
                                                )}
                                                :{" "}
                                              </Typography>
                                              <List>
                                                {session.params.painAreas.map(
                                                  (painArea, painId) => (
                                                    <ListItem
                                                      key={painId}
                                                      sx={{
                                                        padding: 0,
                                                        paddingLeft: 4,
                                                      }}
                                                    >
                                                      <ListItemIcon
                                                        sx={{ minWidth: 16 }}
                                                      >
                                                        <Circle
                                                          fontSize="small"
                                                          color="primary"
                                                          sx={{
                                                            width: 8,
                                                            height: 8,
                                                          }}
                                                        />
                                                      </ListItemIcon>
                                                      <Typography
                                                        fontWeight="bold"
                                                        color="primary"
                                                      >
                                                        {translate(
                                                          `resources.painAreas.${painArea}`
                                                        )}
                                                      </Typography>
                                                    </ListItem>
                                                  )
                                                )}
                                              </List>
                                            </div>
                                          )}

                                        {session.params.programParams &&
                                          !isEmpty(
                                            session.params.programParams
                                          ) && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {translate(
                                                  "resources.sessions.userPathologyParams"
                                                )}
                                                :{" "}
                                              </Typography>
                                              <List sx={{ paddingLeft: 2 }}>
                                                {Object.keys(
                                                  session.params.programParams
                                                ).map((questionnaireId, id) => {
                                                  return Object.keys(
                                                    session.params
                                                      .programParams[
                                                      questionnaireId
                                                    ]
                                                  ).map((param, id) => {
                                                    if (
                                                      session.params
                                                        .programParams[
                                                        questionnaireId
                                                      ][param] == null
                                                    ) {
                                                      return; // Don't display null values
                                                    }
                                                    return (
                                                      <ListItem
                                                        key={id}
                                                        sx={{
                                                          display: "list-item",
                                                          listStyleType: "disc",
                                                          p: 0,
                                                        }}
                                                      >
                                                        <Typography>
                                                          {translate(
                                                            `program.params.${param}`,
                                                            { _: param }
                                                          )}
                                                        </Typography>
                                                        <Typography
                                                          component="span"
                                                          fontWeight="bold"
                                                          color="primary"
                                                        >
                                                          {typeof session.params
                                                            .programParams[
                                                            questionnaireId
                                                          ][param] === "number"
                                                            ? session.params
                                                                .programParams[
                                                                questionnaireId
                                                              ][param]
                                                            : translate(
                                                                `program.params.${param}__${session.params.programParams[questionnaireId][param]}`,
                                                                {
                                                                  _:
                                                                    session
                                                                      .params
                                                                      .programParams[
                                                                      questionnaireId
                                                                    ][param],
                                                                }
                                                              )}
                                                        </Typography>
                                                      </ListItem>
                                                    );
                                                  });
                                                })}
                                              </List>
                                            </div>
                                          )}
                                      </Grid>

                                      {/* Session content */}
                                      <Grid item xs={7} md={8}>
                                        <Typography fontWeight="bold">
                                          {translate(
                                            "resources.sessions.generatedSession"
                                          )}
                                        </Typography>
                                        <div
                                          style={{
                                            display: "flex",
                                            columnGap: 8,
                                          }}
                                        >
                                          <Typography
                                            sx={{ textDecoration: "underline" }}
                                          >
                                            {translate(
                                              "resources.sessions.duration"
                                            )}
                                            :{" "}
                                          </Typography>
                                          <Typography
                                            fontWeight="bold"
                                            color="primary"
                                          >
                                            {moment
                                              .utc(
                                                moment
                                                  .duration({
                                                    seconds:
                                                      session.result.duration,
                                                  })
                                                  .as("milliseconds")
                                              )
                                              .format(
                                                session.result.duration > 59
                                                  ? "mm[:]ss"
                                                  : "s[s]"
                                              )}
                                          </Typography>
                                        </div>
                                        {session.result.equipments &&
                                          session.result.equipments.length >
                                            0 && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                {translate(
                                                  "resources.sessions.equipments"
                                                )}
                                                :{" "}
                                              </Typography>
                                              <List>
                                                {session.result.equipments.map(
                                                  (equipment, equipId) => (
                                                    <ListItem
                                                      key={equipId}
                                                      sx={{ padding: 0 }}
                                                    >
                                                      <ListItemIcon
                                                        sx={{ minWidth: 16 }}
                                                      >
                                                        <Circle
                                                          fontSize="small"
                                                          color="primary"
                                                          sx={{
                                                            width: 8,
                                                            height: 8,
                                                          }}
                                                        />
                                                      </ListItemIcon>
                                                      <Typography
                                                        fontWeight="bold"
                                                        color="primary"
                                                      >
                                                        {translate(
                                                          `resources.equipments.${equipment}`
                                                        )}
                                                      </Typography>
                                                    </ListItem>
                                                  )
                                                )}
                                              </List>
                                            </div>
                                          )}

                                        {/* Exercises */}
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <Typography
                                            sx={{ textDecoration: "underline" }}
                                          >
                                            {translate(
                                              "resources.sessions.exercises"
                                            )}
                                            :{" "}
                                          </Typography>
                                          <List
                                            sx={{ listStyle: "decimal", pl: 4 }}
                                          >
                                            {session.result.components ? (
                                              session.result.components.map(
                                                (component, index) => (
                                                  <ListItem sx={{ padding: 0 }}>
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        columnGap: 4,
                                                      }}
                                                    >
                                                      <ListItemText>
                                                        <Typography fontWeight="bold">
                                                          {index}.
                                                        </Typography>
                                                      </ListItemText>
                                                      <ListItemAvatar>
                                                        <Chip
                                                          label={exercisesVariantsList[
                                                            component
                                                              .exerciseVariantId
                                                          ]?.categories
                                                            .map(
                                                              (category) =>
                                                                category.id
                                                            )
                                                            .join("-")}
                                                        />
                                                      </ListItemAvatar>
                                                      <Typography>
                                                        {
                                                          exercisesVariantsList[
                                                            component
                                                              .exerciseVariantId
                                                          ].name
                                                        }{" "}
                                                        ({component.duration}
                                                        &nbsp;s)
                                                      </Typography>
                                                    </div>
                                                  </ListItem>
                                                )
                                              )
                                            ) : (
                                              <Typography
                                                fontWeight="bold"
                                                color="primary"
                                              >
                                                {translate(
                                                  "resources.misc.noData"
                                                )}
                                              </Typography>
                                            )}
                                          </List>
                                        </div>

                                        {session.status ==
                                          CheckResultStatus.Error &&
                                          session.errorMessages.length && (
                                            <Alert severity="error">
                                              <AlertTitle>
                                                {translate(
                                                  "resources.misc.error",
                                                  {
                                                    smart_count:
                                                      session.errorMessages
                                                        .length,
                                                  }
                                                )}
                                              </AlertTitle>
                                              <ul>
                                                {session.errorMessages.map(
                                                  (item) => {
                                                    return <li>{item}</li>;
                                                  }
                                                )}
                                              </ul>
                                            </Alert>
                                          )}
                                      </Grid>
                                    </Grid>
                                  </Paper>
                                </Box>
                              </Grid>

                              {/* Session buttons */}
                              <Grid item xs={1}>
                                {session.result && (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                      rowGap: 8,
                                    }}
                                  >
                                    <RawSessionOutputDialog
                                      session={session.result}
                                    />
                                    <ExportVideoJSON session={session.result} />
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
