import UserGroupIcon from "@mui/icons-material/Group";

import UsersList from "./UsersList";
import UsersShow from "./UsersShow";
import UsersEdit from "./UsersEdit";

export default {
  list: UsersList,
  show: UsersShow,
  edit: UsersEdit,
  icon: UserGroupIcon,
};
