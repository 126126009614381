import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

const Redirect = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    let redirectUrl = searchParams.get("redirect_uri");

    Auth.currentSession().then((res) => {
      let accessToken = res.getAccessToken();
      let jwt = accessToken.getJwtToken();
      //You can print them to see the full objects
      console.log(`myAccessToken: ${JSON.stringify(accessToken)}`);
      console.log(`myJwt: ${jwt}`);
    });
  }, []);

  return null;
};

export default Redirect;
