import React, { useEffect, useMemo, useState } from "react";
import { useRedirect, useTranslate } from "ra-core";
import { Title } from "react-admin";
import { Auth } from "@aws-amplify/auth";
import { useParams } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useFetchData } from "../utils/useAdminApi";
import { ProgramModel } from "../program/model";
import awsExports from "../aws-exports";
import {
  Card,
  CardContent,
  Select,
  MenuItem,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import useRouteExists from "../utils/useRouteExists";
import { ProgramPageModel } from "./model";
import { ExpandMore } from "@mui/icons-material";
import HtmlMuiRenderer from "../components/HtmlMuiRenderer";

interface WebViewProps {
  baseUrl: string;
}

type SecureIframeProps = {
  baseUrl: string;
  themeMode: string;
};

const resolutions = [
  { label: "iPhone SE (375x667)", width: 375, height: 667 },
  { label: "iPhone 12/13 (390x844)", width: 390, height: 844 },
  { label: "iPhone 14 Pro Max (430x932)", width: 430, height: 932 },
  { label: "iPad Mini (768x1024)", width: 768, height: 1024 },
  { label: "iPad Air (820x1180)", width: 820, height: 1180 },
  { label: "Galaxy S21 (412x915)", width: 412, height: 915 },
];

const SecureIframe: React.FC<SecureIframeProps> = ({ baseUrl, themeMode }) => {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);

  useEffect(() => {
    const buildIframeUrl = async () => {
      try {
        const session = await Auth.currentSession();

        const idToken = session.getIdToken().getJwtToken();
        const accessToken = session.getAccessToken().getJwtToken();
        const refreshToken = session.getRefreshToken().getToken();
        const clientId = session.getIdToken().payload.aud;
        const userId = session.getIdToken().payload['cognito:username'];

        const url = new URL(baseUrl);

        url.searchParams.set('userId', userId);
        url.searchParams.set('idToken', idToken);
        url.searchParams.set('accessToken', accessToken);
        url.searchParams.set('refreshToken', refreshToken);
        url.searchParams.set('clientId', clientId);
        url.searchParams.set("mode", themeMode);

        setIframeUrl(url.toString());
      } catch (err) {
        console.error('Erreur lors de la récupération des tokens Cognito', err);
      }
    };

    buildIframeUrl();
  }, [themeMode]);

  if (!iframeUrl) return <p>Chargement de l’iframe sécurisée…</p>;

  return (
    <iframe
      src={iframeUrl}
      width="100%"
      height="100%"
      style={{ border: "none", display: "block" }}
    />
  );
};

const WebViewResponsive: React.FC<WebViewProps> = ({ baseUrl }) => {
  const [selectedResolution, setSelectedResolution] = useState(resolutions[1]); // iPhone 12/13 by default
  const [themeMode, setThemeMode] = useState<"light" | "dark">("light");
  const translate = useTranslate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 16,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
        {/* Resolution selector */}
        <FormControl sx={{ width: 250 }}>
          <InputLabel id="page-preview-resolution-select">
            {translate(`resources.pages.previewResolution`)}
          </InputLabel>
          <Select
            labelId="page-preview-resolution-select"
            label={translate(`resources.pages.previewResolution`)}
            value={selectedResolution.label}
            onChange={(e) => {
              const resolution = resolutions.find(
                (r) => r.label === e.target.value
              );
              if (resolution) setSelectedResolution(resolution);
            }}
          >
            {resolutions.map((res) => (
              <MenuItem key={res.label} value={res.label}>
                {res.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Light/dark mode */}
        <ToggleButtonGroup
          value={themeMode}
          exclusive
          onChange={(e, newMode) => {
            if (newMode) setThemeMode(newMode);
          }}
          aria-label={translate(`resources.pages.previewLightOrDarkMode`)}
        >
          <ToggleButton value="light">{translate(`resources.pages.previewLightMode`)}</ToggleButton>
          <ToggleButton value="dark">{translate(`resources.pages.previewDarkMode`)}</ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* WebView */}
      <Card
        sx={{
          width: selectedResolution.width,
          height: selectedResolution.height,
          boxShadow: 3,
          borderRadius: 1,
          p: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardContent sx={{ p: 0, flexGrow: 1 }}>
          <SecureIframe baseUrl={baseUrl} themeMode={themeMode} />
        </CardContent>
      </Card>

      {/* Display current resolution */}
      <Typography variant="caption" color="textSecondary">
        {selectedResolution.width} x {selectedResolution.height} px | Mode{" "}
        {themeMode}
      </Typography>
    </div>
  );
};

const ProgramPageShow = (props) => {
  const redirect = useRedirect();
  const translate = useTranslate();
  const routeExists = useRouteExists();

  const { programId, path } = useParams();

  const {
    data: program,
    loading: programLoading,
    error: programError,
  } = useFetchData(`/adminApi/program/${programId}`) as {
    data: ProgramModel;
    loading: boolean;
    error: any;
  };
  const {
    data: programPage,
    loading: programPageLoading,
    error: programPageError,
  } = useFetchData(`/adminApi/program/${programId}/pages/${path}`) as {
    data: ProgramPageModel;
    loading: boolean;
    error: any;
  };
  if (programLoading || programPageLoading || !program) {
    return <CircularProgress size={25} thickness={2} />;
  }

  if (programError || programPageError) {
    return <>ERROR</>;
  }

  return (
    <div>
      <Stack spacing={2} direction="column" sx={{ mx: 4 }}>
        <Title title={translate(`resources.pages.${path}`, { _: path })} />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <h1>{translate(`resources.pages.${path}`, { _: path })}</h1>
          {routeExists(`/${programId}/pages/${path}/edit`) ? (
            <Button
              onClick={() => redirect(`/${programId}/pages/${path}/edit`)}
            >
              {translate("resources.misc.edit")}
            </Button>
          ) : null}
        </Box>
        {/* <Box>
          <HtmlMuiRenderer html={programPage.body} />
        </Box> */}
        <WebViewResponsive
          baseUrl={`${awsExports["aws_content_delivery_url"]}/programpages/?programId=${program.id}&path=${path}&avatar=nina`}
        />
      </Stack>
    </div>
  );
};

export default ProgramPageShow;
