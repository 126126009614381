import React, { useEffect, useState } from "react";
import {
  SimpleForm,
  SelectInput,
  Toolbar,
  SaveButton,
  Title,
  DeleteWithConfirmButton,
  ShowButton,
  useRedirect,
} from "react-admin";
import { useTranslate } from "ra-core";
import {
  Card,
  CircularProgress,
  Button,
  List,
  ListItem,
  ListItemText,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  TextField,
  Box,
  Alert,
} from "@mui/material";
import { useParams } from "react-router-dom";
import {
  useFetchData,
  getAsyncFetchData,
  deleteAsyncData,
  postAsyncData,
} from "../utils/useAdminApi";
import UserProgramParamsForm from "./UserProgramParamsForm";
import { ExpandMore } from "@mui/icons-material";
import { SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { isNil, omit, omitBy } from "lodash";
import config from "../config";

const environment = String(process.env.REACT_APP_ID);
const currentConfig = config[environment];

const validateName = [];
const validateFirstName = [];
const validateSubscribed = [];

const CustomToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <DeleteWithConfirmButton undoable={false} />
  </Toolbar>
);

const keysToEdit = ["patientId", "name", "firstName", "programParams"];
export const UserEdit = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const { programId, id } = useParams();

  const { data, loading, error } = useFetchData("/adminApi/user/" + id);
  const userDataHashSignature = JSON.stringify(data);
  const [userData, setUserData] = useState({});

  const [programs, setPrograms] = useState([]);
  const [loadingPrograms, setLoadingPrograms] = useState(true);

  const [status, setStatus] = useState("idle");

  useEffect(() => {
    // Parse user data
    if (data) {
      const newUserData = {};
      keysToEdit.forEach((key) => {
        newUserData[key] = data[key];
      });
      setUserData(newUserData);

      // Reset form default values
      reset({
        firstName: data.firstName,
        name: data.name,
        program: programId,
      });
    }

    // Fetch programs
    const fetchProgram = async () => {
      const response = await getAsyncFetchData("/adminApi/program/");
      if (response.error) {
        setLoadingPrograms(false);
        return;
      }
      const parsedPrograms = response.data.map((programData, index) => ({
        id: programData.id,
        label: programData.displayName,
      }));
      setPrograms(parsedPrograms);
      setLoadingPrograms(false);
    };
    fetchProgram();
  }, [userDataHashSignature]);

  const { control, handleSubmit, register, reset } = useForm();

  // Pending data handling
  if (loading || loadingPrograms) {
    return <CircularProgress size={25} thickness={2} />;
  }
  if (!data || !userData) {
    return <CircularProgress size={25} thickness={2} />;
  }

  const PageTitle = () => {
    const { id } = useParams();
    return id ? (
      <Title
        title={translate("resources.users.title", {
          id: id,
        })}
      />
    ) : null;
  };

  const UserEditActions = () => (
    <Toolbar {...props}>
      <ShowButton
        to={`/${programId}/users/${id}/show`}
        resource={currentConfig.resources.users}
      />
    </Toolbar>
  );

  const handleChange = (e, key) => {
    setUserData({ ...userData, [key]: e.target.value });
  };

  const submitForm = async (formValues) => {
    setStatus("loading");

    // Omit nullish fields
    let valuesToSubmit = omitBy(formValues, isNil);
    if (valuesToSubmit["program"] && valuesToSubmit["program"] === programId)
      valuesToSubmit = omit(valuesToSubmit, "program");

    console.log("SUBMITTED DATA", valuesToSubmit);

    const { error, data } = await postAsyncData(
      "/adminApi/user/" + id,
      valuesToSubmit
    );

    console.log("ERROR", error);
    console.log("DATA", data);
    if (data.data.$metadata.httpStatusCode === 200) {
      setStatus("success");
      if (valuesToSubmit.program !== undefined)
        redirect("/" + valuesToSubmit.program + "/users/" + id + "/edit");
    } else setStatus("error");
  };

  const deleteUser = async () => {
    const { error, data } = await deleteAsyncData("/adminApi/user/" + id);
  };

  return (
    <>
      <PageTitle />
      {/* User edit form */}
      <form
        onSubmit={handleSubmit((data) => submitForm(data))}
        noValidate
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          paddingTop: 16,
        }}
      >
        <TextField
          label={translate("resources.users.id")}
          variant="outlined"
          value={id}
          disabled={true}
        />
        <TextFieldElement
          control={control}
          name="firstName"
          label={translate("resources.users.firstname")}
          variant="outlined"
        />
        <TextFieldElement
          control={control}
          name="name"
          label={translate("resources.users.lastname")}
          variant="outlined"
        />
        <SelectElement
          control={control}
          label={translate("pos.menu.program")}
          name="program"
          options={programs}
          sx={{
            minWidth: "150px",
          }}
        />
        {status === "success" && (
          <Alert severity="success">
            {translate("resources.users.form.editSuccess")}
          </Alert>
        )}
        {status === "failure" && (
          <Alert severity="error">
            {translate("resources.users.form.editError")}
          </Alert>
        )}
        <Box sx={{ mt: 2, mb: 2, display: "flex", alignItems: "center" }}>
          <Button
            type={"submit"}
            color={"primary"}
            variant="contained"
            disabled={status === "loading"}
          >
            {translate("resources.misc.validate")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => deleteUser()}
            sx={{ mr: 1 }}
          >
            Supprimer
          </Button>
        </Box>
      </form>

      {/* User program answers section */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography>
            {translate(`resources.users.programParams.title`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UserProgramParamsForm user={data} />
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default UserEdit;
