/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const filterUsers = /* GraphQL */ `
  query FilterUsers($email: String, $limit: Int, $nextToken: String) {
    filterUsers(email: $email, limit: $limit, nextToken: $nextToken) {
      items {
        id
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        dateOfBirth
        email
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        equipments
        painAreas
        firstName
        locale
        gender
        healthgoal
        healthgoalInfo
        hrMax
        hrMin
        measuresValues {
          date
          fatRatio
          muscleRatio
          lastWeightValue
        }
        name
        nickname
        pasScore
        points {
          total
          updatedAt
          categories {
            alixActivities {
              total
            }
            otherActivities {
              total
            }
            misc {
              total
            }
          }
        }
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        profileImage
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sensorsPossessed
        sessionsCompleted
        sleepValues {
          sleepProfileValue
          sleepAverageValue
          sleepSDValue
          sleepFeedbackValue
          sleepLastValue
        }
        shopifyId
        subscribed
        subscriptionSource
        subscriptionEndTime
        weight
        height
        withings {
          userId
          accessToken
          refreshToken
          validUntil
        }
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
        }
        avatar
        walkSessionsCompleted
        patientId
        program
        programInfo
        programData {
          id
          data {
            effusion
            extension
            flessum
            flexion
            walkingTestDuration
            knee
          }
          jsonData
        }
        memberships {
          id
          status
          startedAt
          endAt
          source
          refKey
          inviteToken
        }
        lastTestDate
        fitbit {
          userId
          accessToken
          refreshToken
          validUntil
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      balance
      balanceLevel
      balanceCardiacFeedback
      balanceFeedback
      dateOfBirth
      email
      endurance
      enduranceLevel
      enduranceCardiacFeedback
      enduranceFeedback
      equipments
      painAreas
      firstName
      locale
      gender
      healthgoal
      healthgoalInfo
      hrMax
      hrMin
      measuresValues {
        date
        fatRatio
        muscleRatio
        lastWeightValue
      }
      name
      nickname
      pasScore
      points {
        total
        updatedAt
        categories {
          alixActivities {
            total
          }
          otherActivities {
            total
          }
          misc {
            total
          }
        }
      }
      power
      powerLevel
      powerCardiacFeedback
      powerFeedback
      profileImage
      resistance
      resistanceLevel
      resistanceCardiacFeedback
      resistanceFeedback
      sensorsConfigured {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sensorsPossessed
      sessionsCompleted
      sleepValues {
        sleepProfileValue
        sleepAverageValue
        sleepSDValue
        sleepFeedbackValue
        sleepLastValue
      }
      shopifyId
      subscribed
      subscriptionSource
      subscriptionEndTime
      weight
      height
      withings {
        userId
        accessToken
        refreshToken
        validUntil
      }
      versionInfo {
        appVersion
        os
        osVersion
        platform
        version
      }
      avatar
      walkSessionsCompleted
      patientId
      program
      programInfo
      programData {
        id
        data {
          effusion
          extension
          flessum
          flexion
          walkingTestDuration
          knee
        }
        jsonData
      }
      memberships {
        id
        status
        startedAt
        endAt
        source
        refKey
        inviteToken
      }
      lastTestDate
      fitbit {
        userId
        accessToken
        refreshToken
        validUntil
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        dateOfBirth
        email
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        equipments
        painAreas
        firstName
        locale
        gender
        healthgoal
        healthgoalInfo
        hrMax
        hrMin
        measuresValues {
          date
          fatRatio
          muscleRatio
          lastWeightValue
        }
        name
        nickname
        pasScore
        points {
          total
          updatedAt
          categories {
            alixActivities {
              total
            }
            otherActivities {
              total
            }
            misc {
              total
            }
          }
        }
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        profileImage
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sensorsPossessed
        sessionsCompleted
        sleepValues {
          sleepProfileValue
          sleepAverageValue
          sleepSDValue
          sleepFeedbackValue
          sleepLastValue
        }
        shopifyId
        subscribed
        subscriptionSource
        subscriptionEndTime
        weight
        height
        withings {
          userId
          accessToken
          refreshToken
          validUntil
        }
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
        }
        avatar
        walkSessionsCompleted
        patientId
        program
        programInfo
        programData {
          id
          data {
            effusion
            extension
            flessum
            flexion
            walkingTestDuration
            knee
          }
          jsonData
        }
        memberships {
          id
          status
          startedAt
          endAt
          source
          refKey
          inviteToken
        }
        lastTestDate
        fitbit {
          userId
          accessToken
          refreshToken
          validUntil
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listUsersByEmail = /* GraphQL */ `
  query ListUsersByEmail(
    $email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        dateOfBirth
        email
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        equipments
        painAreas
        firstName
        locale
        gender
        healthgoal
        healthgoalInfo
        hrMax
        hrMin
        measuresValues {
          date
          fatRatio
          muscleRatio
          lastWeightValue
        }
        name
        nickname
        pasScore
        points {
          total
          updatedAt
          categories {
            alixActivities {
              total
            }
            otherActivities {
              total
            }
            misc {
              total
            }
          }
        }
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        profileImage
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sensorsPossessed
        sessionsCompleted
        sleepValues {
          sleepProfileValue
          sleepAverageValue
          sleepSDValue
          sleepFeedbackValue
          sleepLastValue
        }
        shopifyId
        subscribed
        subscriptionSource
        subscriptionEndTime
        weight
        height
        withings {
          userId
          accessToken
          refreshToken
          validUntil
        }
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
        }
        avatar
        walkSessionsCompleted
        patientId
        program
        programInfo
        programData {
          id
          data {
            effusion
            extension
            flessum
            flexion
            walkingTestDuration
            knee
          }
          jsonData
        }
        memberships {
          id
          status
          startedAt
          endAt
          source
          refKey
          inviteToken
        }
        lastTestDate
        fitbit {
          userId
          accessToken
          refreshToken
          validUntil
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAlixUsersByPatientId = /* GraphQL */ `
  query ListAlixUsersByPatientId(
    $patientId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixUsersByPatientId(
      patientId: $patientId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        dateOfBirth
        email
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        equipments
        painAreas
        firstName
        locale
        gender
        healthgoal
        healthgoalInfo
        hrMax
        hrMin
        measuresValues {
          date
          fatRatio
          muscleRatio
          lastWeightValue
        }
        name
        nickname
        pasScore
        points {
          total
          updatedAt
          categories {
            alixActivities {
              total
            }
            otherActivities {
              total
            }
            misc {
              total
            }
          }
        }
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        profileImage
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sensorsPossessed
        sessionsCompleted
        sleepValues {
          sleepProfileValue
          sleepAverageValue
          sleepSDValue
          sleepFeedbackValue
          sleepLastValue
        }
        shopifyId
        subscribed
        subscriptionSource
        subscriptionEndTime
        weight
        height
        withings {
          userId
          accessToken
          refreshToken
          validUntil
        }
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
        }
        avatar
        walkSessionsCompleted
        patientId
        program
        programInfo
        programData {
          id
          data {
            effusion
            extension
            flessum
            flexion
            walkingTestDuration
            knee
          }
          jsonData
        }
        memberships {
          id
          status
          startedAt
          endAt
          source
          refKey
          inviteToken
        }
        lastTestDate
        fitbit {
          userId
          accessToken
          refreshToken
          validUntil
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const listAlixUsersByProgrId = /* GraphQL */ `
  query ListAlixUsersByProgrId(
    $program: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixUsersByProgrId(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balance
        balanceLevel
        balanceCardiacFeedback
        balanceFeedback
        dateOfBirth
        email
        endurance
        enduranceLevel
        enduranceCardiacFeedback
        enduranceFeedback
        equipments
        painAreas
        firstName
        locale
        gender
        healthgoal
        healthgoalInfo
        hrMax
        hrMin
        measuresValues {
          date
          fatRatio
          muscleRatio
          lastWeightValue
        }
        name
        nickname
        pasScore
        points {
          total
          updatedAt
          categories {
            alixActivities {
              total
            }
            otherActivities {
              total
            }
            misc {
              total
            }
          }
        }
        power
        powerLevel
        powerCardiacFeedback
        powerFeedback
        profileImage
        resistance
        resistanceLevel
        resistanceCardiacFeedback
        resistanceFeedback
        sensorsConfigured {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sensorsPossessed
        sessionsCompleted
        sleepValues {
          sleepProfileValue
          sleepAverageValue
          sleepSDValue
          sleepFeedbackValue
          sleepLastValue
        }
        shopifyId
        subscribed
        subscriptionSource
        subscriptionEndTime
        weight
        height
        withings {
          userId
          accessToken
          refreshToken
          validUntil
        }
        versionInfo {
          appVersion
          os
          osVersion
          platform
          version
        }
        avatar
        walkSessionsCompleted
        patientId
        program
        programInfo
        programData {
          id
          data {
            effusion
            extension
            flessum
            flexion
            walkingTestDuration
            knee
          }
          jsonData
        }
        memberships {
          id
          status
          startedAt
          endAt
          source
          refKey
          inviteToken
        }
        lastTestDate
        fitbit {
          userId
          accessToken
          refreshToken
          validUntil
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getLevelTest = /* GraphQL */ `
  query GetLevelTest($id: ID!) {
    getLevelTest(id: $id) {
      id
      balance
      balanceLevel
      endurance
      enduranceLevel
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      timestamp
      userId
      resistance
      resistanceLevel
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      power
      powerLevel
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      context
      pasScore
      createdAt
      updatedAt
    }
  }
`;
export const listLevelTests = /* GraphQL */ `
  query ListLevelTests(
    $filter: ModelLevelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLevelTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        balance
        balanceLevel
        endurance
        enduranceLevel
        events {
          type
          timestamp
          data {
            index
            type
          }
        }
        timestamp
        userId
        resistance
        resistanceLevel
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          module
          moduleLevel
          iterations
          sensorDataTimeseries {
            hr {
              min
              max
              avg
            }
          }
          sessionsCompleted
          healthPoints
        }
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sessionTimeSeries
        power
        powerLevel
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        context
        pasScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLevelTestByUserSortedByTimestamp = /* GraphQL */ `
  query GetLevelTestByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLevelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getLevelTestByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        balance
        balanceLevel
        endurance
        enduranceLevel
        events {
          type
          timestamp
          data {
            index
            type
          }
        }
        timestamp
        userId
        resistance
        resistanceLevel
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          module
          moduleLevel
          iterations
          sensorDataTimeseries {
            hr {
              min
              max
              avg
            }
          }
          sessionsCompleted
          healthPoints
        }
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sessionTimeSeries
        power
        powerLevel
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        context
        pasScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getExercise = /* GraphQL */ `
  query GetExercise($id: ID!) {
    getExercise(id: $id) {
      id
      advice {
        fr
        en
        nl
        es
        it
        de
      }
      bodyParts {
        id
      }
      capacity
      cardiacImpact
      categories {
        id
      }
      difficulty
      equipments {
        id
        name
      }
      painAreas
      family
      intensity
      muscularContraction
      musclesInvolved {
        id
      }
      name
      tags
      variants {
        id
        name
        videos {
          alex {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
          nina {
            default {
              filepath
              filesize
            }
            high {
              filepath
              filesize
            }
          }
        }
      }
      workoutPrograms
      easyIDs
      createdAt
      updatedAt
    }
  }
`;
export const listExercises = /* GraphQL */ `
  query ListExercises(
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercises(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
        }
        bodyParts {
          id
        }
        capacity
        cardiacImpact
        categories {
          id
        }
        difficulty
        equipments {
          id
          name
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
        }
        name
        tags
        variants {
          id
          name
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExercisesByIntensity = /* GraphQL */ `
  query ListExercisesByIntensity(
    $intensity: Int!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercisesByIntensity(
      intensity: $intensity
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
        }
        bodyParts {
          id
        }
        capacity
        cardiacImpact
        categories {
          id
        }
        difficulty
        equipments {
          id
          name
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
        }
        name
        tags
        variants {
          id
          name
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listExercisesByName = /* GraphQL */ `
  query ListExercisesByName(
    $name: String!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelExerciseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listExercisesByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        advice {
          fr
          en
          nl
          es
          it
          de
        }
        bodyParts {
          id
        }
        capacity
        cardiacImpact
        categories {
          id
        }
        difficulty
        equipments {
          id
          name
        }
        painAreas
        family
        intensity
        muscularContraction
        musclesInvolved {
          id
        }
        name
        tags
        variants {
          id
          name
        }
        workoutPrograms
        easyIDs
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSession = /* GraphQL */ `
  query GetSession($id: ID!) {
    getSession(id: $id) {
      id
      events {
        type
        timestamp
        data {
          index
          type
        }
      }
      exercisesData {
        name
        author
        duration
        components {
          section
          exerciseVariantId
          exerciseId
          name
          pause
          duration
          speedFactor
          intensity
          video {
            filepath
            filesize
          }
          equipments
          painAreas
          cardiacImpact
          easyMode {
            exerciseId
            exerciseVariantId
            name
            video {
              filepath
              filesize
            }
            equipments
            cardiacImpact
            advice
          }
          advice
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
        }
        equipments
        module
        moduleLevel
        program
        iterations
        walkSessionData {
          distance
          averageSpeed
          averagePace
        }
        type
        description
        advice
        additionalComponents {
          exerciseVariantId
          video {
            filepath
            filesize
          }
        }
        previewInfoWebviewPath
        programParams
      }
      sessionInfo {
        startTime
        endTime
        userFeedback
        cardiacFeedback
        module
        moduleLevel
        iterations
        sensorDataTimeseries {
          hr {
            min
            max
            avg
          }
        }
        sessionsCompleted
        healthPoints
      }
      timestamp
      userId
      sensor {
        modelName
        name
        tag
        type
        additionalData {
          macAddress
        }
        brand
      }
      sessionTimeSeries
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      program
      type
      createdAt
      updatedAt
    }
  }
`;
export const listSessions = /* GraphQL */ `
  query ListSessions(
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        events {
          type
          timestamp
          data {
            index
            type
          }
        }
        exercisesData {
          name
          author
          duration
          components {
            section
            exerciseVariantId
            exerciseId
            name
            pause
            duration
            speedFactor
            intensity
            video {
              filepath
              filesize
            }
            equipments
            painAreas
            cardiacImpact
            easyMode {
              exerciseId
              exerciseVariantId
              name
              equipments
              cardiacImpact
              advice
            }
            advice
            walkSessionData {
              distance
              averageSpeed
              averagePace
            }
          }
          equipments
          module
          moduleLevel
          program
          iterations
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
          type
          description
          advice
          additionalComponents {
            exerciseVariantId
            video {
              filepath
              filesize
            }
          }
          previewInfoWebviewPath
          programParams
        }
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          module
          moduleLevel
          iterations
          sensorDataTimeseries {
            hr {
              min
              max
              avg
            }
          }
          sessionsCompleted
          healthPoints
        }
        timestamp
        userId
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sessionTimeSeries
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        program
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSessionByUserSortedByTimestamp = /* GraphQL */ `
  query GetSessionByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSessionByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        events {
          type
          timestamp
          data {
            index
            type
          }
        }
        exercisesData {
          name
          author
          duration
          components {
            section
            exerciseVariantId
            exerciseId
            name
            pause
            duration
            speedFactor
            intensity
            video {
              filepath
              filesize
            }
            equipments
            painAreas
            cardiacImpact
            easyMode {
              exerciseId
              exerciseVariantId
              name
              equipments
              cardiacImpact
              advice
            }
            advice
            walkSessionData {
              distance
              averageSpeed
              averagePace
            }
          }
          equipments
          module
          moduleLevel
          program
          iterations
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
          type
          description
          advice
          additionalComponents {
            exerciseVariantId
            video {
              filepath
              filesize
            }
          }
          previewInfoWebviewPath
          programParams
        }
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          module
          moduleLevel
          iterations
          sensorDataTimeseries {
            hr {
              min
              max
              avg
            }
          }
          sessionsCompleted
          healthPoints
        }
        timestamp
        userId
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sessionTimeSeries
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        program
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listSessionByUserId = /* GraphQL */ `
  query ListSessionByUserId(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSessionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSessionByUserId(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        events {
          type
          timestamp
          data {
            index
            type
          }
        }
        exercisesData {
          name
          author
          duration
          components {
            section
            exerciseVariantId
            exerciseId
            name
            pause
            duration
            speedFactor
            intensity
            video {
              filepath
              filesize
            }
            equipments
            painAreas
            cardiacImpact
            easyMode {
              exerciseId
              exerciseVariantId
              name
              equipments
              cardiacImpact
              advice
            }
            advice
            walkSessionData {
              distance
              averageSpeed
              averagePace
            }
          }
          equipments
          module
          moduleLevel
          program
          iterations
          walkSessionData {
            distance
            averageSpeed
            averagePace
          }
          type
          description
          advice
          additionalComponents {
            exerciseVariantId
            video {
              filepath
              filesize
            }
          }
          previewInfoWebviewPath
          programParams
        }
        sessionInfo {
          startTime
          endTime
          userFeedback
          cardiacFeedback
          module
          moduleLevel
          iterations
          sensorDataTimeseries {
            hr {
              min
              max
              avg
            }
          }
          sessionsCompleted
          healthPoints
        }
        timestamp
        userId
        sensor {
          modelName
          name
          tag
          type
          additionalData {
            macAddress
          }
          brand
        }
        sessionTimeSeries
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        program
        type
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlixInviteCode = /* GraphQL */ `
  query GetAlixInviteCode($id: String!) {
    getAlixInviteCode(id: $id) {
      id
      name
      program
      codeStatus
      refKey
      withExpiration
      expiresAt
      withCounts
      uses
      maxUses
      withUserValidation
      userValidationMethod
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAlixInviteCodes = /* GraphQL */ `
  query ListAlixInviteCodes(
    $id: String
    $filter: ModelAlixInviteCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlixInviteCodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAlixInviteCodesByName = /* GraphQL */ `
  query ListAlixInviteCodesByName(
    $name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixInviteCodesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAlixInviteCodesByProgrId = /* GraphQL */ `
  query ListAlixInviteCodesByProgrId(
    $program: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixInviteCodesByProgrId(
      program: $program
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAlixInviteCodesByRefKey = /* GraphQL */ `
  query ListAlixInviteCodesByRefKey(
    $refKey: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAlixInviteCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixInviteCodesByRefKey(
      refKey: $refKey
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        program
        codeStatus
        refKey
        withExpiration
        expiresAt
        withCounts
        uses
        maxUses
        withUserValidation
        userValidationMethod
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlixUserProgramData = /* GraphQL */ `
  query GetAlixUserProgramData($id: ID!) {
    getAlixUserProgramData(id: $id) {
      id
      userId
      timestamp
      status
      questionnaireId
      createdAt
      updatedAt
    }
  }
`;
export const listAlixUserProgramData = /* GraphQL */ `
  query ListAlixUserProgramData(
    $filter: ModelAlixUserProgramDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixUserProgramData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        status
        questionnaireId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgramDataByUserSortedByTimestamp = /* GraphQL */ `
  query GetProgramDataByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixUserProgramDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getProgramDataByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        status
        questionnaireId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlixProgram = /* GraphQL */ `
  query GetAlixProgram($id: ID!) {
    getAlixProgram(id: $id) {
      id
      displayName
      description
      icon
      lastInsertPatientId
      status
      questionnaires {
        id
        questions {
          id
          type
          enum
          enumMultiple
          required
          cast
          min
          max
        }
      }
      options {
        indoorSessions
        walkSessions
        homeWebview
        detailsWebview
        questionnaires
        paymentInfoWebview
        pasTest
        kneeRaiseTest
        webviewTest
      }
      generateSessionMode
      aiParams {
        recommendations {
          api
          apiId
          promptTemplate
        }
        session {
          model
          programRules
          promptTemplate
        }
      }
      product {
        productRef
        paymentMode
        purchaseType
        paymentProvider
        membershipDuration
        freeTrialDuration
      }
      visibility
      decor
      pages {
        items {
          id
          programID
          program {
            id
            displayName
            description
            icon
            lastInsertPatientId
            status
            questionnaires {
              id
            }
            options {
              indoorSessions
              walkSessions
              homeWebview
              detailsWebview
              questionnaires
              paymentInfoWebview
              pasTest
              kneeRaiseTest
              webviewTest
            }
            generateSessionMode
            product {
              productRef
              paymentMode
              purchaseType
              paymentProvider
              membershipDuration
              freeTrialDuration
            }
            visibility
            decor
            pages {
              nextToken
            }
            createdAt
            updatedAt
          }
          path
          title
          body
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAlixPrograms = /* GraphQL */ `
  query ListAlixPrograms(
    $id: ID
    $filter: ModelAlixProgramFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAlixPrograms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            required
            cast
            min
            max
          }
        }
        options {
          indoorSessions
          walkSessions
          homeWebview
          detailsWebview
          questionnaires
          paymentInfoWebview
          pasTest
          kneeRaiseTest
          webviewTest
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
          }
          session {
            model
            programRules
            promptTemplate
          }
        }
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        visibility
        decor
        pages {
          items {
            id
            programID
            program {
              id
              displayName
              description
              icon
              lastInsertPatientId
              status
              generateSessionMode
              visibility
              decor
              createdAt
              updatedAt
            }
            path
            title
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProgramPage = /* GraphQL */ `
  query GetProgramPage($id: ID!) {
    getProgramPage(id: $id) {
      id
      programID
      program {
        id
        displayName
        description
        icon
        lastInsertPatientId
        status
        questionnaires {
          id
          questions {
            id
            type
            enum
            enumMultiple
            required
            cast
            min
            max
          }
        }
        options {
          indoorSessions
          walkSessions
          homeWebview
          detailsWebview
          questionnaires
          paymentInfoWebview
          pasTest
          kneeRaiseTest
          webviewTest
        }
        generateSessionMode
        aiParams {
          recommendations {
            api
            apiId
            promptTemplate
          }
          session {
            model
            programRules
            promptTemplate
          }
        }
        product {
          productRef
          paymentMode
          purchaseType
          paymentProvider
          membershipDuration
          freeTrialDuration
        }
        visibility
        decor
        pages {
          items {
            id
            programID
            program {
              id
              displayName
              description
              icon
              lastInsertPatientId
              status
              generateSessionMode
              visibility
              decor
              createdAt
              updatedAt
            }
            path
            title
            body
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      path
      title
      body
      createdAt
      updatedAt
    }
  }
`;
export const listProgramPages = /* GraphQL */ `
  query ListProgramPages(
    $filter: ModelProgramPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProgramPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        programID
        program {
          id
          displayName
          description
          icon
          lastInsertPatientId
          status
          questionnaires {
            id
            questions {
              id
              type
              enum
              enumMultiple
              required
              cast
              min
              max
            }
          }
          options {
            indoorSessions
            walkSessions
            homeWebview
            detailsWebview
            questionnaires
            paymentInfoWebview
            pasTest
            kneeRaiseTest
            webviewTest
          }
          generateSessionMode
          aiParams {
            recommendations {
              api
              apiId
              promptTemplate
            }
            session {
              model
              programRules
              promptTemplate
            }
          }
          product {
            productRef
            paymentMode
            purchaseType
            paymentProvider
            membershipDuration
            freeTrialDuration
          }
          visibility
          decor
          pages {
            items {
              id
              programID
              path
              title
              body
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        path
        title
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWithingsSleepData = /* GraphQL */ `
  query GetWithingsSleepData($id: ID!) {
    getWithingsSleepData(id: $id) {
      id
      userId
      date
      startTimestamp
      endTimestamp
      wakeUpDuration
      wakeUpCount
      remSleepDuration
      totalSleepTime
      lightSleepDuration
      deepSleepDuration
      heartRateAverage
      respirationRateAverage
      snoring
      sleepScore
      createdAt
      updatedAt
    }
  }
`;
export const listWithingsSleepData = /* GraphQL */ `
  query ListWithingsSleepData(
    $filter: ModelWithingsSleepDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithingsSleepData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        startTimestamp
        endTimestamp
        wakeUpDuration
        wakeUpCount
        remSleepDuration
        totalSleepTime
        lightSleepDuration
        deepSleepDuration
        heartRateAverage
        respirationRateAverage
        snoring
        sleepScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSleepDataByUserSortedByDate = /* GraphQL */ `
  query GetSleepDataByUserSortedByDate(
    $userId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWithingsSleepDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSleepDataByUserSortedByDate(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        startTimestamp
        endTimestamp
        wakeUpDuration
        wakeUpCount
        remSleepDuration
        totalSleepTime
        lightSleepDuration
        deepSleepDuration
        heartRateAverage
        respirationRateAverage
        snoring
        sleepScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWithingsMeasureData = /* GraphQL */ `
  query GetWithingsMeasureData($id: ID!) {
    getWithingsMeasureData(id: $id) {
      id
      userId
      date
      weight
      fatRatio
      muscleMass
      height
      createdAt
      updatedAt
    }
  }
`;
export const listWithingsMeasureData = /* GraphQL */ `
  query ListWithingsMeasureData(
    $filter: ModelWithingsMeasureDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithingsMeasureData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        weight
        fatRatio
        muscleMass
        height
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMeasureDataByUserSortedByDate = /* GraphQL */ `
  query GetMeasureDataByUserSortedByDate(
    $userId: String!
    $date: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWithingsMeasureDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMeasureDataByUserSortedByDate(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        weight
        fatRatio
        muscleMass
        height
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      userId
      timestamp
      event
      data {
        source
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        timestamp
        event
        data {
          source
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const subscriptionHistoryByUserSortedByTimestamp = /* GraphQL */ `
  query SubscriptionHistoryByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionHistoryByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        event
        data {
          source
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlixActivityData = /* GraphQL */ `
  query GetAlixActivityData($id: ID!) {
    getAlixActivityData(id: $id) {
      id
      userId
      date
      endTime
      startTime
      dataValues {
        activityType
        value
      }
      granularity
    }
  }
`;
export const listAlixActivityData = /* GraphQL */ `
  query ListAlixActivityData(
    $filter: ModelAlixActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixActivityData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
        }
        granularity
      }
      nextToken
    }
  }
`;
export const listAlixActivityDataByUserId = /* GraphQL */ `
  query ListAlixActivityDataByUserId(
    $userId: String!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixActivityDataByUserId(
      userId: $userId
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
        }
        granularity
      }
      nextToken
    }
  }
`;
export const listAlixActivityDataByGranularityAndDate = /* GraphQL */ `
  query ListAlixActivityDataByGranularityAndDate(
    $granularity: ActivityDataGranularity!
    $date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixActivityDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixActivityDataByGranularityAndDate(
      granularity: $granularity
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        date
        endTime
        startTime
        dataValues {
          activityType
          value
        }
        granularity
      }
      nextToken
    }
  }
`;
export const getHealthGoalQuestionnairesData = /* GraphQL */ `
  query GetHealthGoalQuestionnairesData($id: ID!) {
    getHealthGoalQuestionnairesData(id: $id) {
      id
      healthgoal
      timestamp
      userId
      userInfo {
        dateOfBirth
        weight
        height
        hrMin
        hrMax
        gender
      }
      createdAt
      updatedAt
    }
  }
`;
export const listHealthGoalQuestionnairesData = /* GraphQL */ `
  query ListHealthGoalQuestionnairesData(
    $filter: ModelHealthGoalQuestionnairesDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHealthGoalQuestionnairesData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        healthgoal
        timestamp
        userId
        userInfo {
          dateOfBirth
          weight
          height
          hrMin
          hrMax
          gender
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotifications = /* GraphQL */ `
  query GetNotifications($id: ID!) {
    getNotifications(id: $id) {
      id
      userId
      notificationType
      push {
        isPush
        sent
        sentAt
        title
        body
      }
      inApp {
        isInApp
        read
        readAt
        title
        body
      }
      timestamp
      action
      actionValue
      priority
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        notificationType
        push {
          isPush
          sent
          sentAt
          title
          body
        }
        inApp {
          isInApp
          read
          readAt
          title
          body
        }
        timestamp
        action
        actionValue
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotificationsByUserSortedByTimestamp = /* GraphQL */ `
  query GetNotificationsByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getNotificationsByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        notificationType
        push {
          isPush
          sent
          sentAt
          title
          body
        }
        inApp {
          isInApp
          read
          readAt
          title
          body
        }
        timestamp
        action
        actionValue
        priority
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPointsTransaction = /* GraphQL */ `
  query GetUserPointsTransaction($id: ID!) {
    getUserPointsTransaction(id: $id) {
      id
      userId
      timestamp
      type
      points
      totalAfter
      expiresAt
      data {
        type
        data
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserPointsTransactions = /* GraphQL */ `
  query ListUserPointsTransactions(
    $filter: ModelUserPointsTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPointsTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        type
        points
        totalAfter
        expiresAt
        data {
          type
          data
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPointsTransactionsByUserSortedByTimestamp = /* GraphQL */ `
  query GetUserPointsTransactionsByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPointsTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserPointsTransactionsByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        type
        points
        totalAfter
        expiresAt
        data {
          type
          data
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlixEventLog = /* GraphQL */ `
  query GetAlixEventLog($id: ID!) {
    getAlixEventLog(id: $id) {
      id
      userId
      timestamp
      eventType
      ref
      data
      title
      createdAt
      updatedAt
    }
  }
`;
export const listAlixEventLogs = /* GraphQL */ `
  query ListAlixEventLogs(
    $filter: ModelAlixEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlixEventLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventLogsByUserSortedByTimestamp = /* GraphQL */ `
  query EventLogsByUserSortedByTimestamp(
    $userId: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventLogsByUserSortedByTimestamp(
      userId: $userId
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventLogByUserEventTypeSortedByTimestamp = /* GraphQL */ `
  query EventLogByUserEventTypeSortedByTimestamp(
    $eventType: String!
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAlixEventLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventLogByUserEventTypeSortedByTimestamp(
      eventType: $eventType
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        timestamp
        eventType
        ref
        data
        title
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMembershipsAccess = /* GraphQL */ `
  query GetMembershipsAccess($id: ID!) {
    getMembershipsAccess(id: $id) {
      id
      userId
      programId
      status
      startedAt
      endAt
      timestamp
      source
      createdAt
      updatedAt
    }
  }
`;
export const listMembershipsAccesses = /* GraphQL */ `
  query ListMembershipsAccesses(
    $filter: ModelMembershipsAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipsAccesses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        programId
        status
        startedAt
        endAt
        timestamp
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMembershipsAccessByUser = /* GraphQL */ `
  query ListMembershipsAccessByUser(
    $userId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipsAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipsAccessByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        programId
        status
        startedAt
        endAt
        timestamp
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMembershipsAccessByUserAndProgramId = /* GraphQL */ `
  query ListMembershipsAccessByUserAndProgramId(
    $userId: String!
    $programId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipsAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipsAccessByUserAndProgramId(
      userId: $userId
      programId: $programId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        programId
        status
        startedAt
        endAt
        timestamp
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listMembershipsAccessByProgramId = /* GraphQL */ `
  query ListMembershipsAccessByProgramId(
    $programId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMembershipsAccessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembershipsAccessByProgramId(
      programId: $programId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        programId
        status
        startedAt
        endAt
        timestamp
        source
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
