const customMessages = {
  program: {
    params: {
      knee: "Treated knee",
      walkingTestDuration: "Walking duration (s)",
      left: "left",
      right: "right",
    },
  },
};

export default customMessages;
